import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { savePrice } from "../../../client/actions/apiActions";
import {Redirect} from "react-router-dom";

import ItemRadio from "../../form/itemRadio";
import ItemText from "../../form/itemText";

class Price extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            cancelEdit: false,
            name: '', price: '', addOn: '',  sort: 0, startTime: '', endTime: '', unitType: '',
            retailRate: '0.00',
            hotelRate: '0.00',
            errors : {}
        };
    };
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onChangeRadio = (name, value) => {
        this.setState({
            [name]: value
        });
    };
    savePrice = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        let noerror = true;
        let id = 0;
        let errors = {};
        const { name, price, retailRate, hotelRate, sort, addOn, startTime, endTime, unitType } = this.state;

        if (name === '') {
            noerror = false;
            errors = {...errors, name: 'You left the price "Name" blank'};
        }
        if (price === '') {
            noerror = false;
            errors = {...errors, price: 'You left the price blank'};
        }
        if (startTime === '') {
            noerror = false;
            errors = {...errors, startTime: 'You left the price "start Time" blank'};
        }
        if (endTime === '') {
            noerror = false;
            errors = {...errors, endTime: 'You left the price "end Time" blank'};
        }
        if (unitType === '') {
            noerror = false;
            errors = {...errors, unitType: 'You left the price "unit code" blank'};
        }

        if (noerror === true) {
            let priceData = { id, name, price, retailRate, hotelRate, sort, addOn, startTime, endTime, unitType };
            this.props.savePrice( priceData );
            this.props.history.push('/config/prices');
        } else {
            this.setState({errors});
        }
    };

    cancelEdit = () => {
        this.setState({
            errorMessage: '',
            cancelEdit: true,
            errors : {},
            id:'', name:'', price:'', sort: 0, addOn:'', startTime:'', endTime:'', unitType:'',
            retailRate: '0.00',
            hotelRate: '0.00'
        });
    };

    render() {
        const {
            errorMessage,
            errors,
            cancelEdit,
            name,
            price,
            addOn,
            startTime,
            endTime,
            unitType,
            sort,
            retailRate,
            hotelRate
        } = this.state;

        if (cancelEdit === true) {
            return (<Redirect to="/config/prices"/> )
        } else {
            return (
                <div className="container">
                    <form name="addPriceForm" id="addPriceForm" autoComplete="off" onSubmit={this.savePrice}>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="text-center">Add New Price</h1>
                            </div>
                            {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}

                            <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                            <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePrice()}>SAVE PRICE</button></div>
                            <div className="col-12"><hr noshade="true" /></div>
                        </div>


                        <ItemText
                            label="Name"
                            id="name"
                            placeholder="enter name ... "
                            value={name}
                            type="text"
                            errors={errors.name}
                            onChange={this.onChange}
                        />
                        <ItemText
                            label="Price"
                            id="price"
                            placeholder="enter price ... "
                            value={price}
                            type="text"
                            errors={errors.price}
                            onChange={this.onChange}
                        />
                        <ItemText
                            label="Retail Rate"
                            id="retailRate"
                            placeholder="enter retail rate ... "
                            type="text"
                            value={retailRate}
                            errors={errors.retailRate}
                            onChange={this.onChange}
                        />
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Is this an Add on</label>
                            <div className="col-sm-8">
                                <div className="row">
                                    <div className="col">
                                        <ItemRadio
                                            label="Yes"
                                            name="addOn"
                                            radioId={"addOnY"}
                                            value="1"
                                            onChangeRadio={this.onChangeRadio}
                                            selectedValue={addOn}
                                        />
                                    </div>
                                    <div className="col">
                                        <ItemRadio
                                            label="No"
                                            name="addOn"
                                            radioId={"addOnN"}
                                            value="0"
                                            onChangeRadio={this.onChangeRadio}
                                            selectedValue={addOn}
                                        />
                                    </div>
                                </div>
                            </div>
                            {errors.addOn && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors.addOn}</div></div>}
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Hotel Only</label>
                            <div className="col-sm-8">Add price first then edit to link to a hotel</div>
                        </div>

                        <ItemText
                            label="Start Time"
                            id="startTime"
                            placeholder="enter start time ... "
                            value={startTime}
                            type="text"
                            errors={errors.startTime}
                            onChange={this.onChange}
                        />
                        <ItemText
                            label="End Time"
                            id="endTime"
                            placeholder="enter end time ... "
                            value={endTime}
                            type="text"
                            errors={errors.endTime}
                            onChange={this.onChange}
                        />
                        <ItemText
                            label="Unit Code"
                            id="unitType"
                            placeholder="enter unit code ... "
                            value={unitType}
                            type="text"
                            errors={errors.unitType}
                            onChange={this.onChange}
                        />
                        <ItemText
                            label="sort"
                            id="sort"
                            placeholder="enter sort ... "
                            value={sort}
                            type="text"
                            errors={errors.sort}
                            onChange={this.onChange}
                        />
                        <div className="row">
                            <div className="col-12"><hr noshade="true" /></div>
                            <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                            <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePrice()}>SAVE PRICE</button></div>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

Price.propTypes = {
    savePrice: PropTypes.func.isRequired,
    saveData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    saveData: state.pages.saveData
});

export default connect(mapStateToProps, { savePrice })(Price);
