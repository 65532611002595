import React, {Component, Fragment} from 'react';
import Loading from '../../layout/Loading';

class ReportHotel extends Component {

    viewTransaction = (viewTransactionId) => {
        this.props.viewTransaction(viewTransactionId);
    };

    render() {
        if (typeof(this.props.reportData) !== 'undefined') {
            const { reportTitle, reportHeaders, reportRows, reportFooter } = this.props.reportData;
            const { switchTransaction, editTransaction, deleteTransaction, user, reportType } = this.props;

            return (
                <>
                    <h3>{reportTitle}</h3>
                    <table className="table table-striped table-sm table-bordered">
                        {reportHeaders && <thead className="thead-dark">
                            <tr>
                                {reportHeaders.map((field,index) => <Fragment key={index}>
                                    {reportHeaders.length-1 === index && <th className="noprint" key={index}>{field}</th>}
                                    {reportHeaders.length-1 !== index && <th key={index}>{field}</th>}
                                </Fragment>)}
                            </tr>
                        </thead>}
                        {reportRows && <tbody>
                            {reportRows.map((row,index)=> {
                                return (<tr key={index}>
                                    {row.map((data,index2) => {
                                        return (<Fragment key={index2}>
                                            {reportType.indexOf(reportType) !== -1 && <>
                                                {index2 !== row.length-1 && <td key={index2}>{data}</td>}
                                            </>}
                                        </Fragment>);
                                    })}
                                    <td className="noprint">
                                        <button className="btn btn-sm btn-primary m-1" onClick={ () => this.viewTransaction(row[row.length-1]) }><i className="fas fa-eye" /></button>
                                        {row[row.length-2] === 1 && user.user.userType === 'A' && <button className="btn btn-sm btn-success m-1" onClick={ () => editTransaction(row[2], row[row.length-1]) }><i className="fas fa-dollar-sign" /></button>}
                                        {row[row.length-2] === 1 && user.user.userType === 'A' && <button className="btn btn-sm btn-warning m-1" onClick={ () => switchTransaction(row[row.length-1]) }><i className="fas fa-hotel" /></button>}
                                        {user.user.userType === 'A' && <button className="btn btn-sm btn-danger m-1" onClick={ () => deleteTransaction(row[row.length-1], reportType) }><i className="fas fa-trash-alt" /></button>}
                                    </td>
                                </tr>);
                            })}
                        </tbody>}
                        {reportFooter && <tfoot>
                            <tr className="bg-warning">
                                {reportFooter.map((data, index) => {
                                    return (
                                        <Fragment key={index}>
                                            {index !== reportFooter.length && <td key={index}>{data}</td>}
                                            {index === reportFooter.length && <td className="noprint" key={index}>{data}</td>}
                                        </Fragment>
                                    );
                                })}
                            </tr>
                        </tfoot>}
                    </table>
                </>
            );
        } else {
            return (<Loading />);
        }
    }
}
export default ReportHotel
