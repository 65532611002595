import React, {Component} from 'react';

class UnitLog extends Component {
    render() {
        const { logs } = this.props.unitData;
        return (
            <div className="card panel-primary">
                <div className="card-header">Log</div>
                <div className="card-body">
                    {logs && (
                        <table className="mt-3 table table-striped">
                            <thead className="thead-dark text-light">
                            <tr>
                                <th width="10%">User</th>
                                <th width="10%">Date</th>
                                <th width="60%">Note</th>
                            </tr>
                            </thead>
                            <tbody>
                                {logs.map((note, index) => (
                                    <tr key={index}>
                                        <td>{note.admin}</td>
                                        <td>{note.epoc}</td>
                                        <td>{note.note}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        )
    }
}

export default UnitLog