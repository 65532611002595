import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, loginAuthCode } from "../../client/actions/auth";
import { Redirect } from "react-router-dom";
import logo from '../../assets/logo.png';
import queryString from "query-string";

class Login extends Component {
    state = {
        username: '',
        password: '',
        appVersion: '',
        AUTH2FA: false,
        AuthMessage: '',
        auth2faCode: ''
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        let appVersion = values.AV;
        this.setState({ appVersion:appVersion });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            console.log("componentDidUpdate", this.props.authUser);

            if (this.props.authUser && this.props.authUser.AUTH2FA === true) {
                this.setState({AUTH2FA: true, AuthMessage: this.props.authUser.details});
            }
        }
    }

    onChange = e => this.setState({[e.target.name]: e.target.value});
    SubmitLogIn = (e) => {
        e.preventDefault();
        const { username, password, appVersion } = this.state;
        let error = false;
        let errorMessage = '';
        if (username === '') {
            error = true;
            errorMessage = "You left your username blank";
        }
        if (password === '') {
            error = true;
            errorMessage = "You left your password blank";
        }

        if (error === false) this.props.login(username, password, appVersion);
        if (error === true) this.setState({ errorMessage: errorMessage });
    };
    SubmitAuthCode = (e) => {
        e.preventDefault();
        const { auth2faCode } = this.state;
        this.props.loginAuthCode(auth2faCode);
    }

    render() {
        if (this.props.isAuthenticated) {
            const { appVersion } = this.state;
            const { user } = this.props.auth;
            if (user.user.userType === 'S' || user.user.userType === 'M') {

                if (typeof(appVersion) !== 'undefined') {
                    return <Redirect to={`/config/daily-log?AV=` + appVersion} />;
                } else {
                    return <Redirect to="/config/daily-log"/>;
                }
            } else {

                if (typeof(appVersion) !== 'undefined') {
                    return <Redirect to={`/?AV=` + appVersion} />;
                } else {
                    return <Redirect to="/"/>;
                }
            }

        }
        const { username, password, AUTH2FA, AuthMessage, auth2faCode }= this.state;
        return (
            <div className="container mb-4">
                <div className="row">
                    <div className="col-md-6 m-auto">
                        <div className="card card-body border-warning mt-5">

                            <div className="row">
                                <div className="col-12 my-2 text-center">
                                    <img src={logo} className="img-fluid" alt="Company Logo" style={{width: '200px', height: 'auto'}} border="0" />
                                </div>
                            </div>
                            {AuthMessage && <div className="alert alert-warning">{AuthMessage}</div> }
                            {AUTH2FA === false && <form onSubmit={this.SubmitLogIn}>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-left">Username</label>
                                    <div className="col-sm-8"><input
                                        type="text"
                                        name="username"
                                        onChange={this.onChange}
                                        className="form-control"
                                        autoComplete="section-red username"
                                        value={username}
                                    /></div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-left">Password</label>
                                    <div className="col-sm-8"><input
                                        type="password"
                                        name="password"
                                        onChange={this.onChange}
                                        className="form-control"
                                        autoComplete="section-red current-password"
                                        value={password}
                                    /></div>
                                </div>
                                <button className="btn btn-block btn-secondary" onClick={(e) => this.SubmitLogIn(e)}>LOG IN</button>
                            </form>}
                            {AUTH2FA === true && <form>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label text-left">Auth Code</label>
                                    <div className="col-sm-8"><input
                                        type="text"
                                        name="auth2faCode"
                                        onChange={this.onChange}
                                        className="form-control"
                                        autoComplete="section-red username"
                                        value={auth2faCode}
                                    /></div>
                                </div>
                                <button className="btn btn-block btn-secondary" onClick={(e) => this.SubmitAuthCode(e)}>Continue</button>
                            </form>}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated : state.auth.isAuthenticated,
    authUser: state.auth.authCode,
    auth: state.auth
});

export default connect(mapStateToProps, {login, loginAuthCode} )(Login);
