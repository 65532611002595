import React, {Component} from 'react';

class UnitNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customNotes: ''
        };
        this.onChange = this.onChange.bind(this);
    }
    componentWillUnmount() {

        this.setState({
            customNotes: ''
        });
    }

    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onSaveNotes = () => {
        const { customNotes } = this.state;
        this.props.saveUnitNotes(customNotes);
        this.setState({customNotes:''});
    };
    render() {
        const { id, notes } = this.props.unitData;
        const { printNote } = this.props;
        const { customNotes } = this.state;
        let unitId = id;
        return (
            <div className="card panel-primary">
                <div className="card-header">Notes</div>
                <div className="card-body">
                    {notes && (
                        <table className="mt-3 table table-striped">
                            <thead className="thead-dark text-light">
                            <tr>
                                <th width="10%">User</th>
                                <th width="10%">Date</th>
                                <th width="60%">Note</th>
                                <th width="20%">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                                {notes.map((note, index) => (
                                    <tr key={index}>
                                        <td>{note.admin}</td>
                                        <td>{note.epoc}</td>
                                        <td>{note.note}</td>
                                        <td><button onClick={()=>printNote(unitId)} className="btn btn-secondary btn-sm"><i className="fas fa-print" /></button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    <div className="row">
                        <div className="col-12">
                            <h3>Enter Custom Notes</h3>
                        </div>
                        <div className="col-12">
                            <textarea className="form-control" name="customNotes" value={customNotes} onChange={this.onChange} />
                        </div>
                        <div className="col-12">
                            <button className="btn btn-success" onClick={this.onSaveNotes}>Save Note</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default UnitNotes