import React, {Component, Fragment} from 'react';

class ReviewOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            androidMessage: '',
            cartUnits: [],
            hasCartUnits: false,
            thisUnit: false,
            readerConnected: false,
            paymentMethod: '',
            SQtotalAmount: '',
            SQcustomerName: ''
        };
    }
    componentDidMount() {

        let paymentMethod = this.props.paymentMethod;
        if (this.props.hasCartUnits === true) {
            let thisUnit = false;
            this.props.cartUnits.map( (unit) => {
                if (unit.unitId === this.props.unitId) thisUnit = true;
                return (null);
            });

            if (thisUnit === true)
            {
                this.setState({
                    cartUnits: this.props.cartUnits,
                    hasCartUnits: this.props.hasCartUnits,
                    thisUnit: thisUnit
                });
            }
            else
            {
                const { cartUnits } = this.props;
                paymentMethod = cartUnits[0].paymentMethod;
                let unitInfo = {
                    unitId : this.props.unitId,
                    unitType : this.props.unitType,
                    unitNumber : this.props.unitNumber,
                    unitLocation : this.props.unitLocation,
                    thisUnit: thisUnit,

                    roomChargeHotel: cartUnits[0].roomChargeHotel,
                    roomNumber: cartUnits[0].roomNumber,
                    lastName: cartUnits[0].lastName,
                    manualEntry: cartUnits[0].manualEntry,

                    selectedUnitPrice: this.props.selectedUnitPrice,
                    selectedUnit: this.props.selectedUnit,
                    selectedAddons: this.props.selectedAddons,

                    orderTotal: this.props.orderTotal,
                    addonTotal: this.props.addonTotal,

                    wyndhamExtraCharge: this.props.wyndhamExtraCharge,
                    wyndhamAdditionalCharge: this.props.wyndhamAdditionalCharge
                };
                let newCartUnits = {};

                let cartUnitsFound = cartUnits.filter(filterUnit => filterUnit.unitId !== this.props.unitId);
                newCartUnits = [...cartUnitsFound, unitInfo];
                this.setState({
                    cartUnits: newCartUnits,
                    hasCartUnits: this.props.hasCartUnits
                });
            }
        }
        this.setState({paymentMethod: paymentMethod});
        this.props.logAction("review screen loaded - multi unit " + this.props.hasCartUnits + " paymentMethod " + paymentMethod);
    }
    componentWillUnmount() {
        this.setState({
            showModal: false,
            androidMessage: '',
            cartUnits: [],
            hasCartUnits: false,
            thisUnit: false,
            readerConnected: false,
            paymentMethod: '',
            SQtotalAmount: '',
            SQcustomerName: ''
        });
    }

    render() {
        const { saveMultiOrderReview, equipmentData, unSelectSingleUnit, unitId, unitType, unitNumber, unitLocation, nameSelected, equipmentSelected, selectedUnit, selectedUnitPrice, selectedAddons, cancelPaymentMethod, cancelSelectedEquipment, cancelGuestName, saveOrderReview, ccErrorMessage, selectMultipleUnits} = this.props;
        const { cartUnits, hasCartUnits, thisUnit } = this.state;

        let paymentMethod = this.state.paymentMethod;

        const {androidMessage} = this.state;
        let orderTotal = this.props.orderTotal;
        let addonTotal = this.props.addonTotal;

        let roomChargeHotel = this.props.roomChargeHotel;
        let lastName = this.props.lastName;
        let roomNumber = this.props.roomNumber;

        let currentDateTime = new Date();
        let currentTime = currentDateTime.getHours();

        let selectedAddonList = [];
        if (selectedAddons !== null) {
            selectedAddonList = selectedAddons.split(",");
        }
        orderTotal += addonTotal;

        let roomEnding = 0;
        if (hasCartUnits === true)
        {
            lastName = cartUnits[0].lastName;
            roomChargeHotel = cartUnits[0].roomChargeHotel;
            roomNumber = cartUnits[0].roomNumber;
            orderTotal = 0;
            if (paymentMethod === "" || paymentMethod === null) paymentMethod = cartUnits[0].paymentMethod;

            return (<div className="row">
                <div className="col-sm-12 text-center">
                    <div className="row my-2">
                        <div className="col-12">
                            <h5 className="text-center mt-2">Payment Method : {paymentMethod}</h5>
                        </div>
                    </div>
                    {roomChargeHotel && <div className="row my-2">
                        <div className="col-12">
                            <h5 className="text-center mt-2">Room Charge Hotel : {roomChargeHotel}</h5>
                        </div>
                    </div>}
                    {roomNumber !== null && roomNumber !== 0 && <div className="row my-2">
                        <div className="col-12">
                            <h5 className="text-center mt-2">Guest Name : {lastName} </h5>
                        </div>
                    </div>}
                    {roomNumber === null && <div className="row my-2">
                        <div className="col-12">
                            <h5 className="text-center mt-2">Customer Name : {lastName}</h5>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                            <table className="table table-sm table-striped table-bordered">
                                <tbody>
                                    {cartUnits.map( (unit, index) => {
                                        let selectedAddons = '';
                                        if (unit.selectedAddons !== null && typeof(unit.selectedAddons) !== 'undefined') selectedAddons = unit.selectedAddons.split(",");
                                        orderTotal = Number.parseFloat(unit.selectedUnitPrice) + orderTotal;
                                        return (<Fragment key={index}>
                                            <tr>
                                                <td>{unit.unitType + unit.unitNumber}</td>
                                                <td>{unit.selectedUnit}</td>
                                                <td>$ {Number.parseFloat(unit.selectedUnitPrice)}</td>
                                            </tr>
                                            {selectedAddons !== '' && selectedAddons.length > 1 && <Fragment>
                                                {selectedAddons.map( (addon, index2) => {
                                                    return (<Fragment key={index2}>
                                                        {addon !== '' && <Fragment>
                                                            {equipmentData.addons.map( (addonName, index3) => {
                                                                if (addonName.unitType === addon) {
                                                                    orderTotal = Number.parseFloat(addonName.price) + orderTotal;
                                                                    return (<tr key={index3}>
                                                                        <td>&nbsp;</td>
                                                                        <td>{addonName.name}</td>
                                                                        <td>$ {Number.parseFloat(addonName.price)}</td>
                                                                    </tr>)
                                                                } else {
                                                                    return (null);
                                                                }
                                                            })}
                                                        </Fragment>}
                                                    </Fragment>);
                                                })}
                                            </Fragment>}
                                        </Fragment>);
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row my-2">
                        <div className="col-12">
                            <h5 className="text-center mt-2">Charge Total : ${ Number.parseFloat(orderTotal).toFixed(2) }</h5>
                        </div>
                    </div>

                    {paymentMethod !== '' && <>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                                <button
                                    className="btn btn-block btn-secondary btn-lg my-4"
                                    onClick={() => saveMultiOrderReview(cartUnits)}
                                >CONTINUE</button>
                            </div>
                        </div>

                        { thisUnit === false && <div className="row">
                            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                                <button
                                    className="btn btn-block btn-warning btn-sm mt-4"
                                    onClick={() => selectMultipleUnits()}
                                >Add More Unit(s)</button>
                            </div>
                        </div>}
                        { thisUnit === true && <div className="row">
                            <div className="col-sm-6 text-center">
                                <button
                                    className="btn btn-block btn-warning btn-sm mt-4"
                                    onClick={() => selectMultipleUnits()}
                                >Add Additional Unit(s)</button>
                            </div>
                            <div className="col-sm-6 text-center">
                                <button
                                    className="btn btn-block btn-danger btn-sm mt-4"
                                    onClick={() => unSelectSingleUnit(unitId)}
                                >Remove from transaction</button>
                            </div>
                        </div>}
                    </>}

                </div>
            </div>);
        }
        else
        {
            return (
                <div className="row">
                    <div className="col-sm-12 text-center">
                        {paymentMethod !== '' && <div className="row my-2">
                            <div className="col-9">
                                <h5 className="text-right mt-2">Payment Method : {paymentMethod}</h5>
                            </div>
                            <div className="col-3 text-right">
                                <button onClick={()=>cancelPaymentMethod()} className="btn btn-outline-danger btn-lg mr-2"> <i className="fas fa-times" /></button>
                            </div>
                        </div>}

                        {roomChargeHotel && <div className="row my-2">
                            <div className="col-9">
                                <h5 className="text-right mt-2">Room Charge Hotel : {roomChargeHotel}</h5>
                            </div>
                            <div className="col-3 text-right">
                                <button onClick={() => cancelPaymentMethod()} className="btn btn-outline-danger btn-lg mr-2"><i className="fas fa-times"/></button>
                            </div>
                        </div>}

                        {nameSelected === true && roomNumber !== 0 && <div className="row my-2">
                            <div className="col-9">
                                <h5 className="text-right mt-2">Guest Name : {lastName}</h5>
                            </div>
                            <div className="col-3 text-right">
                                <button onClick={() => cancelPaymentMethod()} className="btn btn-outline-danger btn-lg mr-2"><i className="fas fa-times"/></button>
                            </div>
                        </div>}

                        {equipmentSelected === true && <div className="row my-2">
                            <div className="col-9">
                                <h5 className="text-right mt-2">Unit Selected : {selectedUnit} {' '}
                                    {selectedUnitPrice !== 0.00 && selectedUnitPrice !== '' && selectedUnitPrice !== null && <React.Fragment>$ {selectedUnitPrice}</React.Fragment>}
                                    {(selectedUnitPrice === 0.00 || selectedUnitPrice === '') && <React.Fragment>(included)</React.Fragment>}
                                </h5>
                            </div>
                            <div className="col-3 text-right">
                                <button onClick={() => cancelPaymentMethod()} className="btn btn-outline-danger btn-lg mr-2"><i className="fas fa-times"/></button>
                            </div>
                        </div>}

                        {selectedAddons !== null && <div className="row my-2">
                            <div className="col-9 text-right">
                                <h5 className="text-right mt-2">Unit Addons Selected </h5>
                                {selectedAddonList.map((item, index)=>
                                    <Fragment key={index}>
                                        {((roomChargeHotel !== "Wyndham") || (roomChargeHotel === "Wyndham" && roomEnding >= 70)) && <Fragment>
                                            {equipmentData.addons.map((unit, index)=> <Fragment key={index}>
                                                {
                                                    parseInt(unit.startTime) <= currentTime &&
                                                    parseInt(unit.endTime) >= currentTime &&
                                                    unit.unitType === item &&
                                                    <Fragment>
                                                        {unit.name} ${unit.price}<br />
                                                    </Fragment>
                                                }
                                            </Fragment>)}
                                        </Fragment>}

                                        {(roomChargeHotel === "Wyndham" && roomEnding < 70) && <Fragment>
                                            {equipmentData.hotelAddons.map((unit, index)=>
                                                <Fragment key={index}>
                                                    {
                                                        parseInt(unit.startTime) <= currentTime &&
                                                        parseInt(unit.endTime) >= currentTime &&
                                                        unit.unitType === item &&
                                                        <Fragment>
                                                            {unit.name} ${unit.price}<br />
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            )}
                                        </Fragment>}
                                    </Fragment>
                                )}

                            </div>
                            <div className="col-3 text-right">
                                <button onClick={() => cancelSelectedEquipment()} className="btn btn-outline-danger btn-lg mr-2"><i className="fas fa-times" /></button>
                            </div>
                        </div>}

                        {nameSelected === true && roomNumber === null && <div className="row my-2">
                            <div className="col-9">
                                <h5 className="text-right mt-2">Customer Name : {lastName}</h5>
                            </div>
                            <div className="col-3 text-right">
                                <button onClick={() => cancelGuestName()} className="btn btn-outline-danger btn-lg mr-2"><i className="fas fa-times"/></button>
                            </div>
                        </div>}

                        <div className="row my-2">
                            <div className="col-9">
                                <h5 className="text-right mt-2">Charge Total : ${orderTotal.toFixed(2) }</h5>
                            </div>
                            <div className="col-3 text-right">
                                &nbsp;
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                                <button
                                    className="btn btn-block btn-secondary btn-lg my-4"
                                    onClick={() => saveOrderReview()}
                                >CONTINUE</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                                <button
                                    className="btn btn-warning btn-sm mt-4"
                                    onClick={() => { selectMultipleUnits();}}
                                >Add Additional Unit(s)</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default ReviewOrder
