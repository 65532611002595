import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import ItemText from "../../../form/itemText";
import ItemRadio from "../../../form/itemRadio";
import { checkReceiptInfo } from "../../../../client/actions/apiActions";
import Loading from "../../../layout/Loading";

class SelectReceiptType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage : '',
            receiptType : '',
            displayType : 'loading',
            customerPhone : '',
            customerEmail : '',
            editMode : false
        }
    }
    componentDidMount() {
        const { receiptType, customerPhone, customerEmail, editMode } = this.props;
        this.setState({ editMode: editMode });
        this.props.checkReceiptInfo(receiptType, customerPhone, customerEmail);
        // commented 3/2/2021 because when clicking edit on a units receipt it would just auto send instead of allowing me to edit.
    }
    componentWillUnmount() {
        this.setState({
            errorMessage: '',
            receiptType:'',
            customerPhone:'',
            customerEmail:''
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { editMode } = this.state;

        if (prevProps !== this.props)
        {
            if (typeof(this.props.receiptData) !== 'undefined') {
                if (typeof(this.props.receiptData.status) !== 'undefined') {
                    const { status, message } = this.props.receiptData;
                    if (status === false) {
                        this.setState({ receiptErrorMessage: message, displayType: 'edit' });
                    }
                    if (status === true && editMode !== true) {
                        this.saveReceiptInfo();
                    }

                    this.setState({ displayType: 'edit' });
                }
            } else if (this.props.pageType === 'receiptSuccess') {

            } else {
                if (this.state.receiptErrorMessage !== '') {
                    this.setState({
                        receiptErrorMessage: '',
                        displayType: 'edit'
                    });
                }
            }
        }
    }

    saveReceiptInfo = () => {
        this.props.saveReceiptType();
    }
    checkReceiptInfo = (receiptType, customerPhone, customerEmail) => {
        this.props.checkReceiptInfo(receiptType, customerPhone, customerEmail);
    }
    render() {
        const { customerPhone, customerEmail, onChange, onChangeRadio } = this.props;
        const { receiptErrorMessage, displayType } = this.state;
        let receiptType = this.props.receiptType;
        if (receiptType === '' || typeof(receiptType) === 'undefined') receiptType = 'phone';

        if (displayType === 'loading') {
            return (<Loading />);
        }
        if (displayType === 'edit') {
            return (<div className="row">
                <div className="col-sm-12 text-center">
                    {receiptErrorMessage && <div className="alert alert-danger">{receiptErrorMessage}</div>}

                    <div className="row mb-4">
                        <div className="col-6 px-1">
                            <ItemRadio
                                label="Send by Phone"
                                name="receiptType"
                                radioId="receiptTypePhone"
                                value="phone"
                                placeholder="Phone"
                                selectedValue={receiptType}
                                onChangeRadio={onChangeRadio}
                            />
                        </div>
                        <div className="col-6 px-1">
                            <ItemRadio
                                label="Send by Email"
                                name="receiptType"
                                radioId="receiptTypeEmail"
                                value="email"
                                placeholder="Email"
                                selectedValue={receiptType}
                                onChangeRadio={onChangeRadio}
                            />
                        </div>
                    </div>

                    {receiptType === "phone" && <ItemText
                        label={"Customer Mobile Phone Number"}
                        type={"number"}
                        id={"customerPhone"}
                        value={customerPhone}
                        autoFocus={true}
                        placeholder={"enter mobile phone number"}
                        onChange={onChange}
                        name="customerPhone"
                    />}

                    {receiptType === "email" && <ItemText
                        label={"Customer Email"}
                        type={"text"}
                        id={"customerEmail"}
                        value={customerEmail}
                        placeholder={"enter email address"}
                        onChange={onChange}
                        name="customerEmail"
                    />}


                    {(receiptType === "email" || receiptType === "phone") && <div className="row">
                        <div className="col-12">
                            <button className="btn btn-success" onClick={() => {
                                this.setState({ editMode: false });
                                this.checkReceiptInfo(receiptType, customerPhone, customerEmail)
                            }}>Continue</button>
                        </div>
                    </div>}
                </div>
            </div>);
        }
    }
}
const mapStateToProps = (state) => ({
    receiptData: state.units.receiptData,
});

export default connect(mapStateToProps, { checkReceiptInfo })(SelectReceiptType);
