import React, {Component} from 'react';

class SelectPaymentMethod extends Component {
    render() {
        let pmtRC = true;
        let pmtCC = true;
        let pmtVC = true;
        const { selectPaymentMethod, unitStatus, markOccupied, markOccupiedEmpty } = this.props;

        return (<div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                { pmtCC === true && <button
                    className="btn btn-block btn-secondary btn-lg mt-4"
                    onClick={() => selectPaymentMethod('Credit Card')}
                >CREDIT CARD</button>}

                { pmtRC === true && <button
                    className="btn btn-block btn-secondary btn-lg mt-4"
                    onClick={() => selectPaymentMethod('Room Charge')}
                >HOTEL CHARGE</button>}

                { pmtVC === true && <button
                    className="btn btn-block btn-secondary btn-lg mt-4"
                    onClick={() => selectPaymentMethod('Voucher')}
                >VOUCHER</button>}

                <br/>
                <br/>

                { unitStatus === 5 && <button
                    className="btn btn-block btn-primary btn-lg"
                    onClick={() => markOccupiedEmpty()}
                >MARK EMPTY</button>}
                { unitStatus !== 5 && <button
                    className="btn btn-block btn-occupied btn-lg"
                    onClick={() => markOccupied()}
                >MARK OCCUPIED</button>}
            </div>
        </div>)
    }
}

export default SelectPaymentMethod
