import {
    GET_PAGE_DROPOFF,
    SAVE_DROPOFF,
    GET_CONFIG,
    GET_DATA,
    SAVE_CONFIG,

    GET_PEOPLE_LIST,
    GET_PEOPLE,
    SAVE_PEOPLE,
    SAVE_PEOPLE_SUCCESS,
    DELETE_PEOPLE_ERROR,
    DELETE_PEOPLE,

    GET_LOCATION_LIST,
    GET_LOCATION,
    SAVE_LOCATION,
    SAVE_LOCATION_SUCCESS,
    DELETE_LOCATION_ERROR,
    DELETE_LOCATION,

    GET_HOTEL_LIST,
    GET_HOTEL,
    SAVE_HOTEL,
    SAVE_HOTEL_SUCCESS,
    DELETE_HOTEL,

    GET_PRICE_LIST,
    GET_PRICE,
    SAVE_PRICE,
    SAVE_PRICE_SUCCESS,
    DELETE_PRICE,

    GET_LOGIN_STATUS,
    PRINT_RECEIPT,
    PRINT_NOTES,
    PRINT_NOTE,
    PRINT_UNIT_NOTES,
    PROCESS_CC,
    DELETE_TRANSACTION,
    LOOKUP_TRANSACTION,
    GET_REPORT,
    SAVE_EMAIL_ADDRESS,
    SAVE_EMAIL_ADDRESS_ERROR
} from "../actions/types";

const initialState = {
    dropoffReport: {},
    data: {},
    dataList: {},
    peopleList: {},
    peopleData: {},
    locationData: {},
    locationList: {},
    getLoginStatus: {},
    transactionData: {},
    saveData: {},
    printCode: {},
    ccResponse: {},
    priceData: {},
    priceList: {},
    reportData: {},
    outputData: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_PAGE_DROPOFF:
        case SAVE_DROPOFF:
            return {
                ...state,
                dropoffReport: action.payload
            };

        case GET_REPORT:
        case DELETE_TRANSACTION:
            return {
                ...state,
                reportData: action.payload
            };

        case LOOKUP_TRANSACTION:
            return {
                ...state,
                transactionData: action.payload
            };

        case GET_CONFIG:
        case GET_DATA:
            return {
                ...state,
                data: action.payload
            };

        case SAVE_EMAIL_ADDRESS:
        case SAVE_EMAIL_ADDRESS_ERROR:
            return {
                ...state,
                emails: action.payload
            };

        case DELETE_PRICE:
        case DELETE_HOTEL:
        case GET_PRICE_LIST:
        case GET_HOTEL_LIST:
            return {
                ...state,
                dataList: action.payload
            };


        case GET_PRICE:
        case GET_HOTEL:
            return {
                ...state,
                outputData: action.payload
            };

        case DELETE_LOCATION:
        case GET_LOCATION_LIST:
            return {
                ...state,
                locationList: action.payload
            };

        case DELETE_PEOPLE:
        case GET_PEOPLE_LIST:
            return {
                ...state,
                peopleList: action.payload
            };

        case GET_LOCATION:
            return {
                ...state,
                locationData: action.payload
            };

        case GET_PEOPLE:
            return {
                ...state,
                peopleData: action.payload
            };

        case SAVE_CONFIG:
        case DELETE_PEOPLE_ERROR:
        case SAVE_PEOPLE:
        case SAVE_PEOPLE_SUCCESS:
        case DELETE_LOCATION_ERROR:
        case SAVE_LOCATION:
        case SAVE_LOCATION_SUCCESS:
        case SAVE_PRICE:
        case SAVE_PRICE_SUCCESS:
        case SAVE_HOTEL:
        case SAVE_HOTEL_SUCCESS:
            return {
                ...state,
                saveData: action.payload
            };

        case GET_LOGIN_STATUS:
            return {
                ...state,
                loginStatus: action.payload
            };

        case PRINT_RECEIPT:
        case PRINT_NOTES:
        case PRINT_NOTE:
        case PRINT_UNIT_NOTES:
            return {
                ...state
            };

        case PROCESS_CC:
            return {
                ...state,
                ccResponse: action.payload
            };

        default:
            return state;
    }
}