import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { saveLocation, getLocation } from "../../client/actions/apiActions";

import Loading from "../layout/Loading"
import {Redirect} from "react-router-dom";
import ItemText from '../form/itemText'

class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            cancelEdit: false,
            name: '',
            fullName: '',
            umbrellas: '',
            umbrellaRows: '',
            errors : {}
        }
    };
    componentDidMount() {
        const {id} = this.props.match.params;
        try {
            this.props.getLocation(id);
        }
        catch (e) {
            console.log("e", e);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        const { locationData } = nextProps;
        if (locationData)
        {
            this.setState({
                id: locationData.id,
                name: locationData.name,
                fullName: locationData.fullName,
                umbrellas: locationData.umbrellas,
                umbrellaRows: locationData.umbrellaRows,
            });
        }
    }
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onChangeRadio = (name, value) => {
        this.setState({
            [name]: value
        });
    };
    saveLocation = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        const { id } = this.state;

        let noerror = true;
        let errors = {};
        const { name, fullName, umbrellas, umbrellaRows } = this.state;

        if (name === '') {
            noerror = false;
            errors = {...errors, name: 'You left the users "Name" blank'};
        }
        if (fullName === '') {
            noerror = false;
            errors = {...errors, fullName: 'You left the users "Full Name" blank'};
        }
        if (umbrellas === '') {
            noerror = false;
            errors = {...errors, umbrellas: 'You left the users "Umbrellas" blank'};
        }
        if (umbrellaRows === '') {
            noerror = false;
            errors = {...errors, umbrellaRows: 'You left the users "Umbrellas per Row" blank'};
        }

        if (noerror === true) {
            let locationData = { id, name, fullName, umbrellas, umbrellaRows };
            this.props.saveLocation( locationData );
            this.props.history.push('/config/locations');
        } else {
            this.setState({errors});
        }
    };
    cancelEdit = () => {
        this.setState({
            errorMessage: '',
            cancelEdit: true,
            name: '',
            fullName: '',
            umbrellas: '',
            umbrellaRows: ''
        });
    };

    render() {
        const {
            errorMessage,
            errors,
            name,
            fullName,
            umbrellas,
            umbrellaRows,
            cancelEdit
        } = this.state;
        if (cancelEdit === true) {
            return (<Redirect to="/config/locations"/> )
        } else if (typeof(fullName) !== 'undefined' && fullName !== null && fullName !== '') {
            return (
                <div className="container">
                    <form name="editLocationForm" id="editLocationForm" autoComplete="off" onSubmit={this.saveLocation}>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Edit Location : {name}</h1>
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}

                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveLocation()}>SAVE LOCATION</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>

                        <ItemText
                            label="Full Name"
                            type="text"
                            id="fullName"
                            value={fullName}
                            placeholder="Enter full name ..."
                            onChange={this.onChange}
                            errors={errors.fullName}
                        />

                        <ItemText
                            label="Initials"
                            id="name"
                            placeholder="Enter initials ..."
                            value={name}
                            type="text"
                            onChange={this.onChange}
                            errors={errors.name}
                        />

                        <ItemText
                            label="Umbrellas Per Row"
                            id="umbrellas"
                            placeholder="Umbrellas per row ..."
                            value={umbrellas}
                            type="text"
                            onChange={this.onChange}
                            errors={errors.umbrellas}
                        />

                        <ItemText
                            label="Umbrella Rows"
                            id="umbrellaRows"
                            placeholder="Umbrella rows ..."
                            value={umbrellaRows}
                            type="text"
                            onChange={this.onChange}
                            errors={errors.umbrellaRows}
                        />

                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveLocation()}>SAVE LOCATION</button></div>
                    </div>
                    </form>
                </div>
            )
        } else  {
            return (<Loading />)
        }
    }
}

Location.propTypes = {
    locationData: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveLocation: PropTypes.func.isRequired,
    getLocation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    locationData: state.pages.locationData,
    saveData: state.pages.saveData
});

export default connect( mapStateToProps, { saveLocation, getLocation })(Location);