import React, {Component}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getConfigReportList } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Loading from "../layout/Loading"

class ReportsConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            successMessage: '',
            errorMessage: ''
        };
    };

    componentDidMount() {
        this.props.getConfigReportList();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                dataList: this.props.dataList.reports
            });
        }
    }

    render() {
        const {
            dataList,
            successMessage
        } = this.state;

        if (!dataList) return (
            <Loading/>
        )

        if (dataList) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center py-4">Winters Beach Reports Config</h1>

                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                            <table className="table table-striped">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Name</th>
                                        <th>Emails</th>
                                        <th>Report Type</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataList.map((report, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{report.name}</td>
                                                <td>{report.emailAddresses}</td>
                                                <td>{report.reportType}</td>
                                                <td className="text-right">
                                                    <Link className="btn btn-warning btn-lg m-1" to={"/config/reports/edit/" + report.ID}><i className="fas fa-pencil-alt" /></Link>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

ReportsConfig.propTypes = {
    dataList: PropTypes.object.isRequired,
    getConfigReportList: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    dataList: state.pages.dataList
});

export default connect( mapStateToProps, { getConfigReportList })(ReportsConfig);
