import React, {Component, Fragment} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../client/actions/auth';
import queryString from "query-string";
import {ToastContainer} from "react-toastr";
let container;

class Header extends Component {

    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            appVersion: '',
            searchTerm: '',
            tmpSearchTerm: '',
            redirectToSearch: false
        };
        this.searchFunction = this.searchFunction.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if (typeof(values.AV) !== 'undefined')
        {
            this.setState({ appVersion : values.AV});
        }
    }
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };
    searchFunction = (e) => {
        e.preventDefault();
        const { tmpSearchTerm } = this.state;
        if (tmpSearchTerm !== '') {
            if (this.props.location.pathname === "/search") {
                this.setState({redirectToSearch : false, searchTerm: tmpSearchTerm});
                this.props.updateSearchTerm(tmpSearchTerm);
            } else {
                this.setState({redirectToSearch : true, searchTerm: tmpSearchTerm});
            }
        } else {
            container.error("You did not enter anything in the keyword box.", "Enter Search Criteria", {
                closeButton: true,
            });
        }
    };
    render()
    {
        const {isAuthenticated, user} = this.props.auth;
        const { appVersion, tmpSearchTerm, redirectToSearch } = this.state;
        let { guestLinks, adminLinks, supervisorLinks, managerLinks, userLinks } = '';
        if (typeof(appVersion) === 'undefined' || appVersion === '')
        {
            guestLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/login">Login</a></li>
                </ul>
            );
            adminLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/">Dashboard</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/notes">Notes / Drops</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/dailyreports">Daily Reports</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/people">People</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/locations">Locations</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/daily-log">Log</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/log-details">Log Details</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/prices">Prices</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/hotels">Hotels</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config">Config</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
            supervisorLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/">Dashboard</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/notes">Notes / Drops</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/dailyreports">Daily Reports</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/people">People</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/locations">Locations</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/daily-log">Log</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
            managerLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/">Dashboard</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/notes">Notes / Drops</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/people">People</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/config/daily-log">Log</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
            userLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/">Dashboard</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.toggleNavbar} href="/notes">Notes / Drops</a></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
        }
        else
        {
            guestLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/login?AV=" + appVersion}>Login</Link></li>
                </ul>
            );
            adminLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/?AV=" + appVersion}>Dashboard</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/notes?AV=" + appVersion}>Notes / Drops</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/dailyreports?AV=" + appVersion}>Daily Reports</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config/people?AV=" + appVersion}>Agent</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config/locations?AV=" + appVersion}>Locations</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config/prices?AV=s" + appVersion}>Prices</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config/hotels?AV=" + appVersion}>Hotels</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config?AV=" + appVersion}>Config</Link></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
            supervisorLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/?AV=" + appVersion}>Dashboard</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/notes?AV=" + appVersion}>Notes / Drops</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/dailyreports?AV=" + appVersion}>Daily Reports</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config/people?AV=" + appVersion}>Agent</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config/locations?AV=" + appVersion}>Locations</Link></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
            managerLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/?AV=" + appVersion}>Dashboard</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/notes?AV=" + appVersion}>Notes / Drops</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/config/people?AV=" + appVersion}>Agent</Link></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
            userLinks = (
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/?AV=" + appVersion}>Dashboard</Link></li>
                    <li className="nav-item"><Link className="nav-link" onClick={this.toggleNavbar} to={"/notes?AV=" + appVersion}>Notes / Drops</Link></li>
                    <li className="nav-item"><a className="nav-link" onClick={this.props.logout} href="/"><i className="fas fa-power-off"/></a></li>
                </ul>
            );
        }

        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        if (user !== null)
        {
            return (
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark text-light BorderShadow noprint">
                    {redirectToSearch && <Redirect push to={`/search?searchTerm=${this.state.searchTerm}`} />}
                    <div className="container">
                        <button onClick={this.toggleNavbar} className={`${classTwo}`} type="button"
                                data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className={`${classOne}`} id="navbarSupportedContent">
                            {isAuthenticated === true && <Fragment>
                                {user.user.userType === 'A' && adminLinks}
                                {user.user.userType === 'S' && supervisorLinks}
                                {user.user.userType === 'M' && managerLinks}
                                {user.user.userType === 'U' && userLinks}
                            </Fragment>}
                            {isAuthenticated === false && <Fragment>
                                {guestLinks}
                            </Fragment>}
                        </div>

                        <form className="form-inline my-2 my-lg-0" method="post" action="/search" id="searchscript" name="searchscript" onSubmit={this.searchFunction}>
                            <input onChange={this.onChange} className="form-control mr-sm-2" id="tmpSearchTerm" name="tmpSearchTerm" type="search" placeholder="Search" value={tmpSearchTerm} aria-label="Search" />
                            <a className="btn btn-warning my-2 my-sm-0" onClick={ this.searchFunction } href="/search"><i className="fas fa-search"/></a>
                        </form>
                    </div>
                    <ToastContainer
                        ref={ref => container = ref}
                        className="toast-top-right"
                    />
                </nav>
            )
        }
        else
        {
            return (<div>&nbsp;</div>);
        }
    }
}

const mapStateToProps = state => ({
    auth:state.auth
});

export default connect(mapStateToProps, { logout })(Header);