import React, {Component}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getLogDetailsList, deleteLogDetails } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Loading from "../layout/Loading"

class LogDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataList: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getLogDetailsList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            this.setState({
                dataList: this.props.data.logDetails
            });
        }
    }

    deleteLogDetails = (logDetailsId, logDetailsName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this logDetails "${logDetailsName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteLogDetails(logDetailsId); this.setState({successMessage: "LogDetails has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("LogDetails clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            dataList,
            successMessage
        } = this.state;

        if (dataList !== '') {
            return (<div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">Winters Beach Log Details</h1>
                        {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                        <Link className="btn btn-secondary my-2" to="/config/log-details/add">Add Details</Link>
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Category</th>
                                    <th>Field Name</th>
                                    <th>Field Label</th>
                                    <th>Field Type</th>
                                    <th>Sort</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataList.map((logDetails, index) => {
                                    return (<tr key={index}>
                                        <td>{logDetails.category}</td>
                                        <td>{logDetails.name}</td>
                                        <td>{logDetails.label}</td>
                                        <td>{logDetails.fieldType}</td>
                                        <td>{logDetails.fieldSort}</td>
                                        <td className="text-right">
                                            <Link className="btn btn-warning btn-lg m-1" to={"/config/log-details/edit/" + logDetails.id}><i className="fas fa-pencil-alt" /></Link>
                                            <button className="btn btn-danger btn-lg m-1" onClick={()=>this.deleteLogDetails(logDetails.id, logDetails.name)}><i className="fas fa-times" /></button>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>);
        }
        else
        {
            return (<Loading/>);
        }
    }
}

LogDetails.propTypes = {
    dataList: PropTypes.object.isRequired,
    getLogDetailsList: PropTypes.func.isRequired,
    deleteLogDetails: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    dataList: state.pages.dataList,
    data: state.pages.data
});

export default connect( mapStateToProps, { getLogDetailsList, deleteLogDetails })(LogDetails);
