import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../components/layout/Loading";
import queryString from 'query-string'

const PrivateRoute = ({ component: Component, auth, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (auth.isLoading || auth.isAuthenticated === null) {
                return <Loading />;
            } else if (auth.isAuthenticated === false) {
                const values = queryString.parse(props.location.search);
                if (typeof(values.AV) !== 'undefined')
                {
                    return <Redirect to={`/login?AV=` + values.AV} />;
                }
                else
                {
                    return <Redirect to={`/login`} />;
                }
            } else {
                // console.log("auth", auth);
                return <Component {...props} auth={auth} />;
        }
        }}
    />
);

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
