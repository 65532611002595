import 'babel-polyfill'
import 'core-js/es6/map';
import 'core-js/es6/set';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <App ref={(App) => {window.App = App}} location={window.location}/>,
    document.getElementById('root')
);
serviceWorker.unregister();