import React, {Component, Fragment} from 'react';
import Loading from '../../layout/Loading';

class ReportStandard extends Component {
    render() {
        if (typeof(this.props.reportData) !== 'undefined') {
            const { agents, reportTitle, reportHeaders, reportRows, reportFooter } = this.props.reportData;
            return (
                <div>
                    <h3>{reportTitle}</h3>

                    {agents &&
                        <Fragment>
                            {agents.map((agent,index) =>
                                <table key={index} className="table table-striped table-sm table-bordered">
                                    {reportHeaders && <thead className="thead-dark">
                                    <tr>
                                        {reportHeaders[agent].map((field,index) =>
                                            <th key={index}>{field}</th>
                                        )}
                                    </tr>
                                    </thead>}
                                    {reportRows && <tbody>
                                    {reportRows[agent].map((row,index)=> <tr key={index}>
                                        {row.map((data,index2) =>
                                            <td key={index2}>{data}</td>
                                        )}
                                    </tr>)}
                                    </tbody>}
                                    {reportFooter && <tfoot>
                                    <tr className="bg-warning">
                                        {reportFooter[agent].map((data,index) =>
                                            <td key={index}>{data}</td>
                                        )}
                                    </tr>
                                    </tfoot>}
                                </table>
                            )}
                        </Fragment>
                    }
                </div>
            );
        } else {
            return (<Loading />);
        }
    }
}
export default ReportStandard