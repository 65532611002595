import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {saveConfigReport, getConfigReport} from "../../client/actions/apiActions";

import Loading from "../layout/Loading"
import {Redirect} from "react-router-dom";
import ItemRadio from "../form/itemRadio";
import ItemText from "../form/itemText";
import ItemTextArea from "../form/itemTextArea";

class ReportsConfigEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            cancelEdit: false,
            errors : {},
            id:'',
            name:'',
            emailAddresses:'',
            reportType: ''
        }
    };
    componentDidMount() {
        try {
            const {id} = this.props.match.params;
            this.props.getConfigReport(id);
        }
        catch (e) {
            console.log("e", e);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const { report } = this.props.outputData;
            if (this.props.outputData.pageMode === "saveReport") {
                this.props.history.push('/config/reports');
            }
            this.setState({
                id: report.ID,
                name: report.name,
                emailAddresses: report.emailAddresses,
                reportType: report.reportType
            });
        }
    }

    onChange = (e) => this.setState({[e.target.name]: e.target.value});

    saveReport = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        const { id } = this.state;

        let noerror = true;
        let errors = {};
        const { name, emailAddresses } = this.state;

        if (name === '') {
            noerror = false;
            errors = {...errors, name: 'You left the price "Name" blank'};
        }

        if (noerror === true) {
            let reportData = { id, name, emailAddresses };
            this.props.saveConfigReport( reportData );
        } else {
            this.setState({errors});
        }
    };
    cancelEdit = () => {
        this.setState({
            errorMessage: '',
            cancelEdit: true,
            id:'', name:'', emailAddresses:'', reportType: ''
        });
    };

    render() {
        const {
            errorMessage,
            errors,
            cancelEdit,
            id, name, emailAddresses, reportType
        } = this.state;


        if (cancelEdit === true) {
            return (<Redirect to="/config/reports"/> )
        } else if (typeof(id) !== 'undefined' && id !== null && id !== '') {
            return (
                <div className="container">
                    <form name="editPriceForm" id="editPriceForm" autoComplete="off" onSubmit={this.savePrice}>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center py-4">Edit Report : {name}</h1>
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}

                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveReport()}>SAVE Report</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>

                    <ItemText
                        label="Name"
                        id="name"
                        placeholder="enter name ... "
                        value={name}
                        type="text"
                        errors={errors.name}
                        onChange={this.onChange}
                    />
                    <ItemTextArea
                        label="Email Addresses"
                        id="emailAddresses"
                        placeholder="enter email addresses ... "
                        value={emailAddresses}
                        type="textarea"
                        errors={errors.emailAddresses}
                        onChange={this.onChange}
                    />

                    <div className="row">
                        <div className="col-12">
                            Report Type: {reportType}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveReport()}>SAVE Report</button></div>
                    </div>
                    </form>
                </div>
            )
        } else  {
            return (<Loading />)
        }
    }
}

ReportsConfigEdit.propTypes = {
    outputData: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveConfigReport: PropTypes.func.isRequired,
    getConfigReport: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    outputData: state.pages.outputData,
    saveData: state.pages.saveData
});

export default connect( mapStateToProps, { saveConfigReport, getConfigReport })(ReportsConfigEdit);
