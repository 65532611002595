import React, {Component} from 'react';

class itemTextArea extends Component {
    render() {
        const { errors, label, id, value, placeholder, onChange } = this.props;
        return (
            <div className="form-group row">
                <label className="col-sm-12 col-form-label" htmlFor="exampleFormControlInput1">{label}</label>
                <div className="col-sm-12">
                    <textarea
                        className="form-control"
                        name={id}
                        id={id}
                        onChange={onChange}
                        aria-describedby={id+"Help"}
                        placeholder={placeholder}
                        value={value}
                    /></div>
                {errors && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors}</div></div>}
            </div>
        )
    }
}

export default itemTextArea