import React, {Component} from 'react';
import Loading from "../../layout/Loading"
import ReportStandard from "./ReportStandard"
import ReportHourlySales from "./ReportHourlySales"
import ReportHotel from "./ReportHotel"
import ReportUserLog from "./ReportUserLog"
import ReportAllTransactions from "./ReportAllTransactions"
import ReportCreditCard from "./ReportCreditCard"
import ReportSearch from "./ReportSearch"
import ReportTipReport from "./ReportTipReport";

class ReportWrapper extends Component {

    viewTransaction = (viewTransactionId) => {
        this.props.viewTransaction(viewTransactionId);
    };

    render() {
        const { reportType, reportData, deleteTransaction, UpdateSearchReport, showLoading, switchTransaction, editTransaction, viewTransaction, timeStamp, deleteTipMultiTransaction, deleteMultiTransaction, user } = this.props;
        if (typeof(reportData.reportTitle) != 'undefined')
        {
            console.log("reportType.indexOf('RoomCharge')", reportType.indexOf('RoomCharge'));
            switch (reportType)
            {
                case 'move':
                case 'sales':
                case 'salesLocation':
                case 'salesAgent':
                case 'salesAgentCompetitive':
                case 'charge':
                case 'creditCardDaily':
                case 'reprint':
                case 'customPrice':
                case 'agentTip':
                    return (<ReportStandard
                        user={user}
                        reportData={reportData}
                        viewTransaction={viewTransaction}
                        timeStamp={timeStamp}
                    />);

                case 'hourlySales':
                    return (<ReportHourlySales
                        user={user}
                        reportData={reportData}
                        switchTransaction={switchTransaction}
                        viewTransaction={viewTransaction}
                        timeStamp={timeStamp}
                    />);

                case 'userLog':
                    return (<ReportUserLog
                        user={user}
                        reportData={reportData}
                        switchTransaction={switchTransaction}
                        viewTransaction={viewTransaction}
                        timeStamp={timeStamp}
                    />);

                case 'allTransactions':
                    return (<ReportAllTransactions
                        user={user}
                        reportType={reportType}
                        reportData={reportData}
                        deleteMultiTransaction={deleteMultiTransaction}
                        switchTransaction={switchTransaction}
                        deleteTransaction={deleteTransaction}
                        timeStamp={timeStamp}
                        viewTransaction={viewTransaction}
                    />);

                case 'tip':
                case 'creditCardTips':
                    return (<ReportTipReport
                        user={user}
                        reportType={reportType}
                        reportData={reportData}
                        deleteTipMultiTransaction={deleteTipMultiTransaction}
                        switchTransaction={switchTransaction}
                        deleteTransaction={deleteTransaction}
                        timeStamp={timeStamp}
                        viewTransaction={viewTransaction}
                    />);

                case 'creditCard':
                    return (<ReportCreditCard
                        user={user}
                        reportData={reportData}
                        switchTransaction={switchTransaction}
                        viewTransaction={viewTransaction}
                        timeStamp={timeStamp}
                    />);

                case 'searchSales':
                    return (<ReportSearch
                        user={user}
                        reportData={reportData}
                        showLoading={showLoading}
                        timeStamp={timeStamp}
                        UpdateSearchReport={UpdateSearchReport}
                        viewTransaction={viewTransaction}
                        switchTransaction={switchTransaction}
                    />);

            }

            if (reportType.indexOf('RoomCharge') !== -1) {
                return (<ReportHotel
                    user={user}
                    reportType={reportType}
                    reportData={reportData}
                    switchTransaction={switchTransaction}
                    editTransaction={editTransaction}
                    viewTransaction={this.viewTransaction}
                    deleteTransaction={deleteTransaction}
                    timeStamp={timeStamp}
                />);
            }
        }
        else
        {
            return (<>&nbsp;</>);
        }
    }
}

export default ReportWrapper
