import React, {Component} from 'react';

class SelectHotel extends Component {
    render() {
        const { selectHotel, hotels } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                        <>
                            {hotels && hotels.map((hotel, index) => {
                                return (<button key={index} className="btn btn-block btn-secondary btn-lg mt-4" onClick={() => selectHotel(hotel.Name)}>{hotel.Name}</button>);
                            })}
                        </>
                    </div>
                </div>
            </>
        )
    }
}

export default SelectHotel
