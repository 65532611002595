import React, {Component} from 'react';
import ItemRadio from '../../form/itemRadio';

class UnitNumber extends Component {
    render() {
        const { saveUmbrellaNumber, errorStatus, errorMessage, onChange, onChangeRadio } = this.props;
        let unitNumber = this.props.unitNumber;
        if (unitNumber === '0' || unitNumber === 0) unitNumber = '';
        if (unitNumber === null) unitNumber = '';
        let unitType = this.props.unitType;

        return (
        <div className="card panel-primary">
            <div className="card-header">Set Unit</div>
            <div className="card-body">
                <div className="container">
                    {errorStatus === true && <div className="alert alert-danger" role="alert"><b>ERROR:</b> <span className="ErrorText">{errorMessage}</span></div>}
                    <form className="form-inline" onSubmit={saveUmbrellaNumber}>
                        <h3>Unit Number</h3>
                        <div className="col-12">
                            <input
                                type="number"
                                name="unitNumber"
                                className="form-control"
                                autoFocus={true}
                                id="unitNumber"
                                placeholder="Number"
                                onChange={onChange}
                                value={unitNumber}
                            />
                        </div>
                        <h3 className="mt-4">Unit Type</h3>
                        <div className="container">
                            <div className="row my-2">
                                <div className="col-sm-6 px-1">
                                    <ItemRadio
                                        label="Umbrella"
                                        name="unitType"
                                        radioId="unitTypeU"
                                        value="U"
                                        placeholder="Type"
                                        onChange={onChange}
                                        onChangeRadio={onChangeRadio}
                                        selectedValue={unitType}
                                    />
                                </div>
                                <div className="col-sm-6 px-1">
                                    <ItemRadio
                                        label="Cabana"
                                        name="unitType"
                                        radioId="unitTypeC"
                                        value="C"
                                        placeholder="Umbrella Type"
                                        onChange={onChange}
                                        onChangeRadio={onChangeRadio}
                                        selectedValue={unitType}
                                    />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-sm-6 px-1">
                                    <ItemRadio
                                        label="Bed"
                                        name="unitType"
                                        radioId="unitTypeB"
                                        value="B"
                                        placeholder="Umbrella Type"
                                        onChange={onChange}
                                        onChangeRadio={onChangeRadio}
                                        selectedValue={unitType}
                                    />
                                </div>
                                <div className="col-sm-6 px-1">
                                    <ItemRadio
                                        label="X Chair"
                                        name="unitType"
                                        radioId="unitTypeX"
                                        value="X"
                                        placeholder="Umbrella Type"
                                        onChange={onChange}
                                        onChangeRadio={onChangeRadio}
                                        selectedValue={unitType}
                                    />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-sm-12 pt-2">
                                    <div className="form-group">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-sm-6 px-1">
                                    <ItemRadio
                                        label="Pier"
                                        name="unitType"
                                        radioId="unitTypeP"
                                        value="P"
                                        placeholder="Umbrella Type"
                                        onChange={onChange}
                                        onChangeRadio={onChangeRadio}
                                        selectedValue={unitType}
                                    />
                                </div>
                                <div className="col-sm-6 px-1">
                                    <ItemRadio
                                        label="Tower"
                                        name="unitType"
                                        radioId="unitTypeT"
                                        value="T"
                                        placeholder="Umbrella Type"
                                        onChange={onChange}
                                        onChangeRadio={onChangeRadio}
                                        selectedValue={unitType}
                                    />
                                </div>
                            </div>
                            <div className="col-12" style={{textAlign: 'center'}}>
                                <button
                                    type="submit"
                                    className="btn btn-primary oswald mt-4"
                                    onClick={()=>saveUmbrellaNumber}
                                >
                                    SAVE
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        )
    }
}

export default UnitNumber