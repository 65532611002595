import React, {Component}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getHotelList, deleteHotel } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Loading from "../layout/Loading"

class Hotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getHotelList();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        // console.log(nextProps);
        this.setState({
            dataList: nextProps.dataList.hotel
        });
    }

    deleteHotel = (hotelId, hotelName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this hotel "${hotelName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteHotel(hotelId); this.setState({successMessage: "Hotel has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("Hotel clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            dataList,
            successMessage
        } = this.state;

        if (dataList !== '') {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Winters Beach Hotels</h1>

                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                            <Link className="btn btn-secondary my-2" to="/config/hotel/add">Add Hotel</Link>
                            <table className="table table-striped">
                                <thead className="thead-dark">
                                <tr>
                                    <th>Hotel Name</th>
                                    <th>Contact</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {dataList.map((hotel, index) => (
                                        <tr key={index}>
                                            <td>{hotel.name}</td>
                                            <td>{hotel.contactName}</td>
                                            <td className="text-right">
                                                <Link className="btn btn-warning btn-lg m-1" to={"/config/hotel/edit/" + hotel.id}><i className="fas fa-pencil-alt"></i></Link>
                                                <button className="btn btn-danger btn-lg m-1" onClick={()=>this.deleteHotel(hotel.id, hotel.name)}><i className="fas fa-times"></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <Loading/>
            )
        }
    }
}

Hotel.propTypes = {
    dataList: PropTypes.object.isRequired,
    getHotelList: PropTypes.func.isRequired,
    deleteHotel: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    dataList: state.pages.dataList
});

export default connect( mapStateToProps, { getHotelList, deleteHotel })(Hotel);
