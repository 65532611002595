import React, {Component, Fragment} from 'react';
import DashboardLocationUnit from './DashboardLocationUnit'

class dashboardLocations extends Component {
    render() {
        const { name, locationId } = this.props.location;
        const { editUmbrella, getScrollPos, searchTerm, totalUnits, cartUnits, hasCartUnits, hasMoveUnit, moveUnit } = this.props;
        let units = this.props.location.units;

        return (
            <div
                id={`location` + locationId }
                style={{ width: '100%', overflow: 'auto' }}
            >
                <div className="row mt-4">
                    <div className="col-12">
                        <h2>
                            <i className="fas fa-water text-primary" />
                            <i className="fas fa-water text-primary" />
                            <i className="fas fa-water text-primary" />
                            <i className="fas fa-water text-primary" />
                            <i className="fas fa-water text-primary" />
                            <i className="fas fa-water text-primary" />
                            &nbsp; {' '}
                            {name}
                            &nbsp; {' '}
                            {searchTerm && typeof(totalUnits) !== 'undefined' && " - " + totalUnits + ' unit(s) found'}
                            {(searchTerm === '' || typeof(totalUnits) === 'undefined') && <Fragment>
                                <i className="fas fa-water text-primary" />
                                <i className="fas fa-water text-primary" />
                                <i className="fas fa-water text-primary" />
                                <i className="fas fa-water text-primary" />
                                <i className="fas fa-water text-primary" />
                                <i className="fas fa-water text-primary" />
                            </Fragment>}
                        </h2>
                    </div>
                </div>
                {units.map((row, index)=> {
                    if (row.length !== 0) {
                        return (
                        <div
                            key={index}
                            className="units"
                            style={{width: '4150px', display: 'block', float: 'left', height: '125px' }}
                        >
                            {row.map((unit, index)=> {
                                let extraStyle = '';
                                if (hasCartUnits === true && unit.status !== 0) {
                                    let cartUnitsFound = cartUnits.filter(filterUnit => filterUnit.unitId === unit.id);
                                    if (cartUnitsFound.length === 1) {
                                        extraStyle = 'inCart';
                                    }
                                }

                                if (hasMoveUnit === true && unit.status !== 0) {
                                    if (moveUnit.unitId === unit.id) {
                                        extraStyle = 'moveUnit';
                                    } else {
                                        extraStyle = '';
                                    }
                                }

                                return (<DashboardLocationUnit
                                    key={index}
                                    index={index}
                                    unit={unit}
                                    dom={this.props.dom}
                                    searchTerm={searchTerm}
                                    cartUnits={cartUnits}
                                    extraStyle={extraStyle}
                                    scrollDiv={`location` + locationId}
                                    locationName={this.props.location.name}
                                    editUmbrella={editUmbrella}
                                    getScrollPos={getScrollPos}
                                    auth={this.props.auth}
                                    currentDOY={this.props.currentDOY}
                                    endDOY={this.props.endDOY}
                                    startDOY={this.props.startDOY}
                                />);
                            })}
                        </div>);
                    } else {
                        return (null);
                    }
                }
                )}
            </div>
        )
    }
}

export default dashboardLocations