import {
    GET_UNITS,
    GET_UNIT,
    GET_DATA,
    SAVE_CUSTOMER,
    SAVE_UNIT_NUMBER,
    SEARCH_WYNDHAM,
    MARK_EMPTY,
    RESEAT_CUSTOMER,

    SAVE_NOTE,

    GET_PAGE_DROPOFF,
    SAVE_DROPOFF,

    GET_CONFIG,
    SAVE_CONFIG,

    GET_PEOPLE_LIST,
    GET_PEOPLE,
    SAVE_PEOPLE,
    SAVE_PEOPLE_ERROR,
    DELETE_PEOPLE,
    DELETE_PEOPLE_ERROR,

    GET_LOCATION_LIST,
    GET_LOCATION,
    SAVE_LOCATION,
    SAVE_LOCATION_ERROR,
    DELETE_LOCATION,
    DELETE_LOCATION_ERROR,

    GET_PRICE_LIST,
    GET_PRICE,
    SAVE_PRICE,
    SAVE_PRICE_ERROR,
    DELETE_PRICE_ERROR,
    DELETE_PRICE,

    GET_HOTEL_LIST,
    GET_HOTEL,
    SAVE_HOTEL,
    SAVE_HOTEL_ERROR,
    DELETE_HOTEL_ERROR,
    DELETE_HOTEL,

    PROCESS_CC,
    DELETE_TRANSACTION,
    LOOKUP_TRANSACTION,

    GET_REPORT,
    RESEND_RECEIPT,
    UPDATE_RECEIPT,

    SAVE_EMAIL_ADDRESS,
    SAVE_EMAIL_ADDRESS_ERROR, LOGIN_FAIL
} from './types';
import axios from 'axios';

import { ApiURL } from "../../config";
import {returnErrors} from "./messages";

export const logAction = (unitId, logText) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/logAction`, {unitId : unitId, logText : logText}, tokenConfig(getState) );
        dispatch ({
            type: SAVE_NOTE,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const logUserAction = (logText) => async (dispatch, getState) => {
    try {
        let res = await axios.post(`${ApiURL}/logUserAction`, {logText : logText}, tokenConfig(getState) );
        dispatch ({
            type: SAVE_NOTE,
            payload: res.data
        });
    }
    catch (err) {
        console.log("logAction: ", err);
    }
};

export const getUnits = (dateStamp, searchTerm='') => async (dispatch, getState) => {
    try {
        let res = null;
        if (dateStamp === '' || dateStamp === null)
        {
            res = await axios.get(`${ApiURL}/getUnits`, {
                ...tokenConfig(getState),
                params: {
                    searchTerm: searchTerm
                }
            });
        }
        else
        {
            res = await axios.get(`${ApiURL}/getUnits/${dateStamp}`, tokenConfig(getState) );
        }
        dispatch ({
            type: GET_UNITS,
            payload: res.data
        });
    }
    catch (err) {
        console.log("getUnits Errors: ", err);
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getReceipt = (receiptId) => async (dispatch, getState) => {
    console.log("API getReceipt", receiptId);
    try {
        let res = await axios.get(`${ApiURL}/getReceipt/${receiptId}`, tokenConfig(getState) );
        dispatch ({
            type: GET_PEOPLE_LIST,
            payload: res.data
        });
    }
    catch (err) {
        console.log("getUnits Errors: ", err);
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const performSearch = (dateStamp) => async (dispatch, getState) => {
    try {
        let res = null;
        if (dateStamp === '' || dateStamp === null)
        {
            res = await axios.get(`${ApiURL}/getUnits`, tokenConfig(getState) );
        }
        else
        {
            res = await axios.get(`${ApiURL}/getUnits/${dateStamp}`, tokenConfig(getState) );
        }
        dispatch ({
            type: GET_UNITS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getAllUnits = (dateStamp) => async (dispatch, getState) => {
    try {
        let res = null;
        if (dateStamp === '' || dateStamp === null)
        {
            res = await axios.get(`${ApiURL}/getUnits/all`, tokenConfig(getState) );
        }
        else
        {
            res = await axios.get(`${ApiURL}/getUnits/all/${dateStamp}`, tokenConfig(getState) );
        }
        dispatch ({
            type: GET_UNITS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getUnit = (unitId, timeStamp) => async (dispatch, getState) => {
    try {
        let res = null;
        if (timeStamp === '' || timeStamp === null)
        {
            res = await axios.get(`${ApiURL}/getUnit/${unitId}`, tokenConfig(getState) );
        }
        else
        {
            res = await axios.get(`${ApiURL}/getUnit/${unitId}/${timeStamp}`, tokenConfig(getState) );
        }
        dispatch ({
            type: GET_UNIT,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveUnitNote = (unitId, customNotes) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveUnitNotes/${unitId}`, {customNotes: customNotes}, tokenConfig(getState) );
        dispatch ({
            type: GET_UNIT,
            payload: res.data
        });
    }
    catch (e) {
        console.log("e", e);
    }
};
export const saveUnitNumber = (unitData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveUmbrellaNumber`, {unitData: unitData}, tokenConfig(getState));
        dispatch ({
            type: SAVE_UNIT_NUMBER,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveCustomer = (customerData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveCustomer`, {customerData: customerData}, tokenConfig(getState));
        dispatch ({
            type: SAVE_CUSTOMER,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const moveCustomer = (newUnitData, oldUnitData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/moveCustomer`, {
            newUnitData,
            oldUnitData
        }, tokenConfig(getState));
        dispatch ({
            type: SAVE_CUSTOMER,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const searchWyndham = (customerData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/searchWyndham`, {customerData: customerData}, tokenConfig(getState));
        dispatch ({
            type: SEARCH_WYNDHAM,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const markEmpty = (unitData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/markEmpty`, {unitData: unitData}, tokenConfig(getState));
        dispatch ({
            type: MARK_EMPTY,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const markOccupied = (unitData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/markOccupied`, {unitData: unitData}, tokenConfig(getState));
        dispatch ({
            type: MARK_EMPTY,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const markOccupiedEmpty = (unitData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/markOccupiedEmpty`, {unitData: unitData}, tokenConfig(getState));
        dispatch ({
            type: MARK_EMPTY,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const reseatCustomer = (unitData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/reseatCustomer`, {unitData: unitData}, tokenConfig(getState));
        dispatch ({
            type: RESEAT_CUSTOMER,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const getDropoffReport = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getDropoffReport`, tokenConfig(getState));
        dispatch ({
            type: GET_PAGE_DROPOFF,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveDropoffNotes = (dropoffAmount, dropoffNotes) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveDropoffNotes`, { dropoffAmount: dropoffAmount, dropoffNotes: dropoffNotes }, tokenConfig(getState));
        dispatch ({
            type: SAVE_DROPOFF,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const saveConfigSettings = (fromName, fromEmail, paymentSubject, reportEmailTime, reportEmailSubject, reportEmailMessage, receiptTextMessage, receiptEmailSubject, receiptEmailBody) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveConfigSettings`, { fromName, fromEmail, paymentSubject, reportEmailTime, reportEmailSubject, reportEmailMessage, receiptTextMessage, receiptEmailSubject, receiptEmailBody }, tokenConfig(getState));
        dispatch ({
            type: SAVE_CONFIG,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const getConfigSettings = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getConfigSettings`, tokenConfig(getState));
        dispatch ({
            type: GET_CONFIG,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const getPeopleList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getPeopleList`, tokenConfig(getState));
        dispatch ({
            type: GET_PEOPLE_LIST,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getPeople = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getPeopleList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_PEOPLE,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const savePeople = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/savePeople`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_PEOPLE, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_PEOPLE_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};
export const deletePeople = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deletePeople`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_PEOPLE, payload: res.data });
    }
    catch (err) {
        dispatch({ type: DELETE_PEOPLE_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};

export const getLocationList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getLocationList`, tokenConfig(getState));
        dispatch ({
            type: GET_LOCATION_LIST,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getLocation = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getLocationList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_LOCATION,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveLocation = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveLocation`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_LOCATION, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_LOCATION_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};
export const deleteLocation = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteLocation`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_LOCATION, payload: res.data });
    }
    catch (err) {
        dispatch({ type: DELETE_LOCATION_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};


export const getConfigReportList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getReportList`, tokenConfig(getState));
        dispatch ({
            type: GET_PRICE_LIST,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getConfigReport = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getReportList/${id}`, tokenConfig(getState));
        dispatch ({
            type: GET_PRICE,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveConfigReport = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveReportList`, { postData }, tokenConfig(getState));
        dispatch ({ type: GET_PRICE, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_PRICE_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};



export const getPriceList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getPriceList`, tokenConfig(getState));
        dispatch ({
            type: GET_PRICE_LIST,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getPrice = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getPriceList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_PRICE,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const savePrice = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/savePrice`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_PRICE, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_PRICE_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};
export const deletePrice = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deletePrice`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_PRICE, payload: res.data });
    }
    catch (err) {
        dispatch({ type: DELETE_PRICE_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};

export const getHotelList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getHotelList`, tokenConfig(getState));
        dispatch ({
            type: GET_HOTEL_LIST,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getHotel = (userId) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getHotelList/${userId}`, tokenConfig(getState));
        dispatch ({
            type: GET_HOTEL,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveHotel = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveHotel`, { postData }, tokenConfig(getState));
        dispatch ({ type: SAVE_HOTEL, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_HOTEL_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};
export const deleteHotel = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteHotel`, { postData }, tokenConfig(getState));
        dispatch ({ type: DELETE_HOTEL, payload: res.data });
    }
    catch (err) {
        dispatch({ type: DELETE_HOTEL_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};

export const getHotels = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getHotels`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const getLogDetailsList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getLogDetailsList`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getLogDetails = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getLogDetailsList/${id}`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const checkItemPrice = (itemType, extraCharge, roomChargeHotel) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/checkItemPrice`, { itemType, extraCharge, roomChargeHotel }, tokenConfig(getState));
        dispatch ({
            type: RESEND_RECEIPT,
            payload: res.data
        });
    }
    catch (err) {
        console.log(err);
    }
};
export const saveLogDetails = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveLogDetails`, { postData }, tokenConfig(getState));
        dispatch ({ type: GET_DATA, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_HOTEL_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};
export const deleteLogDetails = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteLogDetails`, { postData }, tokenConfig(getState));
        dispatch ({ type: GET_DATA, payload: res.data });
    }
    catch (err) {
        dispatch({ type: DELETE_HOTEL_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};

export const getDailyLogList = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getDailyLogList`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getDailyLog = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getDailyLogList/${id}`, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveDailyLog = (id, logDate, data) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveDailyLog`, { id, logDate, data }, tokenConfig(getState));
        dispatch ({ type: GET_DATA, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_HOTEL_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};
export const deleteDailyLog = (postData) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteDailyLog`, { postData }, tokenConfig(getState));
        dispatch ({ type: GET_DATA, payload: res.data });
    }
    catch (err) {
        dispatch({ type: DELETE_HOTEL_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};

export const getPrintReceipt = (unitId, reprint) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/printReceipt`, { unitId: unitId, reprint:reprint }, tokenConfig(getState));
        if (window.Android) {
            dispatch (
                window.Android.PrintReceipt(res.data.PrintCode)
            );
        } else {
            //dispatch(null);
        }
    }
    catch (err) {
        console.log(err);
    }
};
export const checkReceiptInfo = (receiptType, receiptPhone, receiptEmail) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/checkReceiptInfo`, { receiptType, receiptPhone, receiptEmail }, tokenConfig(getState));
        dispatch ({
            type: RESEND_RECEIPT,
            payload: res.data
        });
    }
    catch (err) {
        console.log(err);
    }
};
export const getResendReceipt = (customerId, reprint) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/resendReceipt`, { customerId, reprint }, tokenConfig(getState));
        dispatch ({
            type: RESEND_RECEIPT,
            payload: res.data
        });
    }
    catch (err) {
        console.log(err);
    }
};
export const getUpdateReceipt = (unitId, customerId, receiptType, receiptPhone, receiptEmail) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/updateReceipt`, { unitId, customerId, receiptType, receiptPhone, receiptEmail }, tokenConfig(getState));
        dispatch ({
            type: UPDATE_RECEIPT,
            payload: res.data
        });
    }
    catch (err) {
        console.log(err);
    }
};

export const getPrintUnitNote = (noteId) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/printUnitNotes`, { noteId: noteId }, tokenConfig(getState));
        dispatch (
            window.Android.PrintReceipt(res.data.PrintCode)
        );
    }
    catch (err) {
        console.log(err);
    }
};
export const getPrintNotes = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getPrintNotes`, tokenConfig(getState));
        dispatch (
            window.Android.PrintReceipt(res.data.PrintCode)
        );
    }
    catch (err) {
        console.log(err);
    }
};

export const deleteTransaction = (deleteId, reportTime, reportType) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteTransaction`, { deleteId: deleteId, reportTime: reportTime, reportType:reportType }, tokenConfig(getState));
        dispatch ({
            type: DELETE_TRANSACTION,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const deleteMultiTransaction = (deleteIds, reportTime) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteMultiTransaction`, { deleteIds: deleteIds, reportTime: reportTime }, tokenConfig(getState));
        dispatch ({
            type: DELETE_TRANSACTION,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const deleteTipMultiTransaction = (deleteIds, reportTime) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/deleteTipMultiTransaction`, { deleteIds: deleteIds, reportTime: reportTime }, tokenConfig(getState));
        dispatch ({
            type: DELETE_TRANSACTION,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const switchTransaction = (customerId, reportType) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/switchTransaction`, { customerId: customerId, reportType: reportType }, tokenConfig(getState));
        dispatch ({
            type: GET_REPORT,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveTransaction = (price, transactionId, reportType, reportTime) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveTransaction`, { price: price, transactionId: transactionId, reportType: reportType, reportTime: reportTime }, tokenConfig(getState));
        dispatch ({
            type: GET_REPORT,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const getTip = (transactionId) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/getTip`, { transactionId }, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveTip = (tip, transactionId) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveTip`, { tip, transactionId }, tokenConfig(getState));
        dispatch ({
            type: GET_DATA,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const lookupTransaction = (viewTransactionId) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/lookupTransaction`, { viewTransactionId: viewTransactionId }, tokenConfig(getState));
        dispatch ({
            type: LOOKUP_TRANSACTION,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};

export const getReport = (reportType, reportTime) => async (dispatch, getState) => {
    try {
        const res = await axios.get(`${ApiURL}/getReport/${reportType}/${reportTime}`, tokenConfig(getState));
        dispatch ({
            type: GET_REPORT,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const sendEmailReport = (reportType, reportTime) => async (dispatch, getState) => {
    try {
        const res = axios.get(`${ApiURL}/sendEmailReport/${reportType}/${reportTime}`, tokenConfig(getState));
        dispatch ({
            type: GET_REPORT,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err, err.response));
        dispatch({type: LOGIN_FAIL});
    }
};
export const searchReport = (reportType, startDate, endDate) => async (dispatch, getState) => {
    let newStartDate = new Date(startDate);
    let outputStartDate = newStartDate.getTime();

    let newEndDate = new Date(endDate);
    let outputEndDate = newEndDate.getTime();

    try {
        const res = await axios.get(`${ApiURL}/getSearchReport/${reportType}/${outputStartDate}/${outputEndDate}`, tokenConfig(getState));
        dispatch ({
            type: GET_REPORT,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({type: LOGIN_FAIL});
    }
};
export const saveEmailAddresses = (emailAddresses, reportType) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/saveEmailAddresses`, { emailAddresses, reportType }, tokenConfig(getState));
        dispatch ({ type: SAVE_EMAIL_ADDRESS, payload: res.data });
    }
    catch (err) {
        dispatch({ type: SAVE_EMAIL_ADDRESS_ERROR, payload: err.response });
        dispatch({type: LOGIN_FAIL});
    }
};

export const tokenConfig = (getState) => {
    // GET TOKEN FROM STATE
    const token = getState().auth.token;

    // HEADERS
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type":"application/json"
        }
    };

    // IF TOKEN ADD TO HEADERS
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config;
};
