import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { getDailyLogList, getDailyLog, saveDailyLog, deleteDailyLog } from "../../client/actions/apiActions";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { withAlert } from 'react-alert';
import Loading from '../layout/Loading';
import FormInput from "../new-form/FormInput";

class DailyLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayMode: 'loading',
            dailyLogs: [],
            successMessage: '',
            editId: 0,
            dailyLogData: {},
            dailyLogFieldData: {},
            dataErrors: [],
            dataResults: {}
        };
    }
    componentDidMount() {
        let newDate = new Date();
        let getTime = newDate.getTime().toString();
        this.props.getDailyLogList();
        this.setState({ timeStamp: getTime.substring(0, 10) });
    }
    componentWillUnmount() {
        this.setState ({
            timeStamp: '',
            currentDate: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            reportType: '',
            reportData: {},
            emailAddresses: ''
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps !== this.props)
        {
            // LIST
            if (this.props.data && this.props.data.pageType === 'getDailyLogList') {
                this.setState({
                    displayMode: 'list',
                    dailyLogs: this.props.data.dailyLogs,
                    dailyLogFieldData: this.props.data.dailyLogFieldData
                })
            }

            // SAVE
            if (this.props.data && this.props.data.pageType === 'saveDailyLog') {
                this.setState({
                    displayMode: 'list',
                    dailyLogs: this.props.data.dailyLogs,
                    dailyLogFieldData: this.props.data.dailyLogFieldData
                })
            }

            // EDIT
            if (this.props.data && this.props.data.pageType === 'getDailyLog') {
                this.setState({
                    displayMode: 'edit',
                    dataResults: Object.assign({}, this.props.data.data),
                    dailyLogFieldData: this.props.data.dailyLogFieldData
                })
            }

        }
    }

    addDailyLog = () => {
        this.setState({
            displayMode: 'add',
            editId: 0,
            dailyLogData: {},
            dataErrors: [],
            dataResults: {}
        });
    }
    editDailyLog = (id) => {
        this.setState({
            displayMode: 'loading',
            editId: id,
            dailyLogData: {},
            dataErrors: [],
            dataResults: {}
        });

        this.props.getDailyLog(id);
    }
    saveDailyLog = () => {
        this.setState({dataErrors: {}, displayMode: 'loading'});
        let id = this.state.editId;
        let dataResults = this.state.dataResults;
        let dateDailyLog = '';
        if (typeof(dataResults["Date"]) !== 'undefined') {
            dateDailyLog = dataResults["Date"]["value"];

            console.log("dataResults", dataResults, typeof(dataResults), JSON.stringify(dataResults));
            this.props.saveDailyLog(id, dateDailyLog, dataResults);
        } else {
            let dataErrors = {};
            dataErrors["Date"] = "You did not enter a date";
            this.setState({dataErrors, displayMode: 'edit'});
        }
    }

    cancelEdit = () => {
        this.setState({
            displayMode: 'list',
            dailyLogData: {},
            dataErrors: [],
            dataResults: {}
        });
    }
    onChange = (type, e, field) => {
        let dataResults = this.state.dataResults;
        if (typeof(dataResults[field.fieldName]) === 'undefined') {
            dataResults[field.fieldName] = {};
        }
        let tmpValue = '';
        if (type === 'text')
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }
        else if (type === 'phone')
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }
        else if (type === 'email')
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }
        else if (type === 'textarea')
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) dataResults[field.fieldName]["value"] = '1';
            if (e.target.checked === false) dataResults[field.fieldName]["value"] = '0';
        }
        else if (type === 'image')
        {
            dataResults[field.fieldName]["value"] = e;
        }
        else if (type === 'editor')
        {
            dataResults[field.fieldName]["value"] = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            dataResults[e.target.name]["value"] = e.target.value;
        }
        else if (type === 'password')
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }
        else if (type === 'checkbox')
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }
        else if (type === 'radio')
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }
        else
        {
            dataResults[field.fieldName]["value"] = e.target.value;
        }

        if (typeof(field) !== 'undefined')
        {
            if (typeof(field.callBackFunction) !== "undefined")
            {
                console.log("callBackFunction", field.callBackFunction);
                this.props[field.callBackFunction](tmpValue);
            }
        }

        dataResults[field.fieldName]["user"] = this.props.auth.user.user.initials;
        this.setState({dataResults: dataResults});
    };
    changeValue = (name, value) => {
        let dataResults = this.state.dataResults;
        dataResults[name] = value;
        this.setState({dataResults: dataResults});
    };
    handleCheckboxChange = (name, label) => {
        if (typeof(this.selectedCheckboxes[name]) === 'undefined') this.selectedCheckboxes[name] = new Set();
        if (this.selectedCheckboxes[name].has(label)) {
            this.selectedCheckboxes[name].delete(label);
        } else {
            this.selectedCheckboxes[name].add(label);
        }

        let checkedBoxes = ',';
        for (const checkbox of this.selectedCheckboxes[name]) {
            checkedBoxes += checkbox + ",";
        }
        this.setState({
            dataResults: {...this.state.dataResults, [name]: checkedBoxes}
        })
    };

    render() {
        const {
            dailyLogs,
            successMessage,
            displayMode,
            dailyLogFieldData,
            dataErrors,
            dataResults
        } = this.state;

        let currentCategory = '';

        if (displayMode === "loading")
        {
            return (<Loading />);
        }
        if (displayMode === "list")
        {
            return (<div className="container">
                <div className="onlyprint"><h1>Winters Beach</h1></div>
                <div className="row noprint">
                    <div className="col-12">
                        <h1 className="text-center my-4">Daily Log</h1>

                        {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                        <button className="btn btn-secondary my-2" onClick={() => this.addDailyLog()}>Add Daily Log</button>
                        <table className="table table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Date</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            {dailyLogs && dailyLogs.map((log, index) => {
                                return (<tr key={index}>
                                    <td>{log.logDate}</td>
                                    <td className="text-right">
                                        <button className="btn btn-warning btn-lg m-1" onClick={() => this.editDailyLog(log.id)}><i className="fas fa-pencil-alt" /></button>
                                    </td>
                                </tr>);
                            })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>);
        }
        if (displayMode === "add" || displayMode === "edit")
        {
            return (<div className="container">
                <div className="onlyprint"><h1>Winters Beach</h1></div>
                <div className="row noprint">
                    <div className="col-12">
                        {displayMode === "edit" && <h1 className="text-center my-4">Edit Daily Log</h1>}
                        {displayMode === "add" && <h1 className="text-center my-4">Add Daily Log</h1>}
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn btn-success" onClick={() => this.saveDailyLog()}>Save</button>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-secondary" onClick={() => this.cancelEdit()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                        <hr noshade={"true"} />

                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {dailyLogFieldData && dailyLogFieldData.map((field, index) => {

                                        let outputCategoryName = '';
                                        if (field.category !== currentCategory)
                                        {
                                            currentCategory = field.category;
                                            outputCategoryName = field.category;
                                        }

                                        return (<Fragment key={index}>
                                            {outputCategoryName && <h1>{outputCategoryName}</h1>}
                                            <FormInput
                                                id={field.fieldName + "-" + index}
                                                field={field}
                                                type={field.fieldType}
                                                name={field.fieldName}
                                                label={field.fieldLabel}
                                                placeholder={field.placeholder}
                                                helpText={dataResults[field.fieldName] && "Updated by " + dataResults[field.fieldName]["user"]}
                                                colSize={field.colSize}
                                                error={dataErrors[field.fieldName]}
                                                value={dataResults[field.fieldName] && dataResults[field.fieldName]["value"]}
                                                onChange={this.onChange}
                                                changeValue={this.changeValue}
                                                handleCheckboxChange={this.handleCheckboxChange}
                                            />
                                        </Fragment>);
                                    })}
                                </div>
                            </div>
                        </div>

                        <hr noshade={"true"} />
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn btn-success" onClick={() => this.saveDailyLog()}>Save</button>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-secondary" onClick={() => this.cancelEdit()}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }
    }
}

DailyLog.propTypes = {
    data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect( mapStateToProps, { getDailyLogList, getDailyLog, saveDailyLog, deleteDailyLog })(withAlert(DailyLog));
