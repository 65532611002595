import React, {Component} from 'react';
import Loading from '../../layout/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class ReportAllTransactions extends Component {
    state = {
        isChecked: false,
        boxChecked: {}
    };
    componentDidMount() {
        let boxChecked = this.state.boxChecked;
        const { reportRows } = this.props.reportData;
        if (typeof(reportRows) !== 'undefined') {
            reportRows.map((row)=> {
                let changeBox = row[6];
                boxChecked[changeBox] = false;
                return null;
            });

            this.setState({ boxChecked : boxChecked });
        }
    }
    toggleCheckboxChange = (box) => {
        let boxChecked = this.state.boxChecked;
        if (box === 'all')
        {
            if (boxChecked[box] === true)
            {
                boxChecked[box] = false;
                Object.keys(boxChecked).map((changeBox, i) => {
                    boxChecked[changeBox] = false;
                    return null;
                });
            } else {
                boxChecked[box] = true;
                Object.keys(boxChecked).map((changeBox, i) => {
                    boxChecked[changeBox] = true;
                    return null;
                });
            }
        } else {
            boxChecked[box] = !boxChecked[box];
        }

        this.setState({ boxChecked : boxChecked });
    };
    confirmGroupDelete = () => {
        let showConfirmDialog = false;
        let boxChecked = this.state.boxChecked;
        let rowIds = '';
        const { reportRows } = this.props.reportData;
        if (typeof(reportRows) !== 'undefined') {
            reportRows.map((row)=> {
                if (boxChecked[row[7]] === true) {
                    showConfirmDialog = true;
                    if (rowIds !== '') rowIds += ",";
                    rowIds += row[7];
                }
                return null;
            });
        }

        const options = {
            title: 'Confirm Delete',
            message: 'Confirm you wish to delete these transactions',
            buttons: [{
                label: 'Yes',
                onClick: () => this.props.deleteMultiTransaction(rowIds)
            },
            {
                label: 'No',
                onClick: () => this.onCancelDelete()
            }],
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
        };

        if (showConfirmDialog === true) confirmAlert(options);
    };
    onCancelDelete = () => {
        let boxChecked = this.state.boxChecked;
        boxChecked['all'] = false;

        Object.keys(boxChecked).map((changeBox, i) => {
            boxChecked[changeBox] = false;
            return null;
        });
        this.setState({ boxChecked : boxChecked });
    };

    render() {
        if (typeof(this.props.reportData) !== 'undefined') {
            const { boxChecked } = this.state;
            const { reportTitle, reportHeaders, reportRows, reportFooter } = this.props.reportData;
            const { deleteTransaction, viewTransaction, user, reportType } = this.props;

            return (
                <div>
                    <h3>{reportTitle}</h3>
                    <table className="table table-striped table-sm table-bordered">

                        {reportHeaders && <thead className="thead-dark">
                            <tr>
                                {user.user.userType === 'A' && <th><input
                                    type="checkbox"
                                    name="CheckAll"
                                    id="CheckAll"
                                    defaultChecked={boxChecked && boxChecked['all'] === true ? 'checked' : null }
                                    checked = {boxChecked['all']}
                                    onChange={() => this.toggleCheckboxChange('all')}
                                /></th>}
                                {reportHeaders.map((field,index) =>
                                    <th key={index}>{field}</th>
                                )}
                                <th>&nbsp;</th>
                            </tr>
                        </thead>}

                        {reportRows && <tbody>

                        {reportRows.map((row, index)=> {
                            let checkBoxName=row[7];
                            return (<tr key={index}>
                                {user.user.userType === 'A' && <td><input
                                    type="checkbox"
                                    name={"check" + row[7]}
                                    id={"check" + row[7]}
                                    defaultChecked={boxChecked && boxChecked[checkBoxName] === true ? 'checked' : null }
                                    checked = {boxChecked[checkBoxName]}
                                    onChange={() => this.toggleCheckboxChange(checkBoxName)}
                                /></td>}
                                {row.map((data,index2) => {
                                    if ( index2 !== 7 && index2 !== 8 && index2 !== 9) {
                                        return (<td>{data}</td>);
                                    } else {
                                        return null;
                                    }
                                })}
                                <td className="noprint">
                                    <button className="btn btn-sm btn-primary m-1" onClick={ () => viewTransaction(row[7]) }><i className="fas fa-eye" /></button>
                                    {user.user.userType === 'A' && <button className="btn btn-sm btn-danger m-1" onClick={ () => deleteTransaction(row[7], reportType) }><i className="fas fa-trash-alt" /></button>}
                                </td>
                            </tr>);
                        })}
                        </tbody>}

                        {reportFooter && <tfoot>
                        <tr className="bg-warning">
                            {user.user.userType === 'A' && <td>&nbsp;</td>}
                            {reportFooter.map((data,index) =>
                                <td key={index}>{data}</td>
                            )}
                            <th>&nbsp;</th>
                        </tr>
                        </tfoot>}

                    </table>
                    {user.user.userType === 'A' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <button onClick={() => this.confirmGroupDelete()} className="btn btn-danger btn-sm">Delete All Selected</button>
                            </div>
                        </div>
                    </div>}
                </div>
            );
        } else {
            return (<Loading />);
        }
    }
}
export default ReportAllTransactions;
