import React, {Component}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getLocationList, deleteLocation } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Loading from "../layout/Loading"

class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationList: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getLocationList();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        //console.log(nextProps);
        this.setState({
            locationList: nextProps.locationList.location
        });
    }

    deleteLocation = (locationId, locationName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this location "${locationName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteLocation(locationId); this.setState({successMessage: "Location has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("Location clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            locationList,
            successMessage
        } = this.state;

        if (locationList !== '') {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Winters Beach Locations</h1>

                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                            <Link className="btn btn-secondary my-2" to="/config/location/add">Add Location</Link>
                            <table className="table table-striped">
                                <thead className="thead-dark">
                                <tr>
                                    <th>Name</th>
                                    <th>Initials</th>
                                    <th>Umbrellas</th>
                                    <th>Rows</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {locationList.map((location, index) => (
                                        <tr key={index}>
                                            <td>{location.fullName}</td>
                                            <td>{location.name}</td>
                                            <td>{location.umbrellas}</td>
                                            <td>{location.umbrellaRows}</td>
                                            <td className="text-right">
                                                <Link className="btn btn-warning btn-lg m-1" to={"/config/location/edit/" + location.id}><i className="fas fa-pencil-alt" /></Link>
                                                <button className="btn btn-danger btn-lg m-1" onClick={()=>this.deleteLocation(location.id, location.name)}><i className="fas fa-times" /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <Loading/>
            )
        }
    }
}

Location.propTypes = {
    locationList: PropTypes.object.isRequired,
    getLocationList: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    locationList: state.pages.locationList
});

export default connect( mapStateToProps, { getLocationList, deleteLocation })(Location);
