import React, {Component} from 'react';
import ReportWrapper from './reports/ReportWrapper';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {
    getReport,
    deleteTransaction,
    deleteMultiTransaction,
    deleteTipMultiTransaction,
    saveTransaction,
    getTip,
    saveTip,
    saveEmailAddresses,
    searchReport,
    sendEmailReport,
    switchTransaction,
    lookupTransaction,
    getHotels
} from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Modal, Button } from 'react-bootstrap';
import { withAlert } from 'react-alert';
import Loading from '../layout/Loading';

class DailyReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeStamp : '',
            currentDate : '',
            showHotels: false,
            startDate : '',
            startTime : '',
            endDate : '',
            endTime : '',
            reportType : '',
            reportData : {},
            showEmailModal : false,
            showModal : false,
            modalSpecialContent : false,
            modalTitle : '',
            modalContent : '',
            emailAddresses : '',
            editTransactionPrice: 0,
            editTransactionTip: 0,
            transactionId:'',
            hotels: []
        };
        this.editTransaction = this.editTransaction.bind(this);
    }
    componentDidMount() {
        let newDate = new Date();
        let getTime = newDate.getTime().toString();
        this.setState({ timeStamp: getTime.substring(0, 10) });
        this.props.getHotels();
    }
    componentWillUnmount() {
        this.setState ({
            timeStamp: '',
            currentDate: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            reportType: '',
            reportData: {},
            emailAddresses: '',
            hotels: []
        });
    }
    componentWillReceiveProps(nextProps, nextState) {
        if (typeof(nextProps.data) !== 'undefined')
        {
            if (nextProps.data.pageName === 'getTip')
            {
                this.setState({
                    editTransactionTip: nextProps.data.Tip_Amount
                });
            }
            if (nextProps.data.pageName === 'getHotels')
            {
                this.setState({
                    hotels: nextProps.data.hotels
                });
            }
        }
        if (typeof(nextProps.reportData) !== 'undefined')
        {
            if (typeof(nextProps.reportData.reportSettings) !== 'undefined')
            {
                if (typeof(nextProps.reportData.reportSettings.emailAddresses) !== 'undefined' && this.state.emailAddresses === '')
                {
                    this.setState({
                        emailAddresses: nextProps.reportData.reportSettings.emailAddresses
                    });
                }
            }
            if (nextProps.reportData !== this.state.reportData) {
                this.setState({
                    reportData: nextProps.reportData.reportData,

                    startDate: nextProps.reportData.startDate,
                    startTime: nextProps.reportData.startTime,

                    endDate: nextProps.reportData.endDate,
                    endTime: nextProps.reportData.endTime,

                    timeStamp: nextProps.reportData.timeStamp,
                    currentDate: nextProps.reportData.currentDate,

                    showLoading: 0
                });
            }
        }
        if ( typeof(nextProps.emails) !== 'undefined' )
        {
            if (nextProps.emails.success === 1)
            {
                this.closeEmailModal();
            }
        }
        if (typeof(nextProps.transactionData) != 'undefined')
        {
            let modalContent = '';
            modalContent += "Date : " + nextProps.transactionData.doyFriendly + "\n";
            modalContent += nextProps.transactionData.notes + '\n';
            this.setState({ modalContent: modalContent });
        }
    }

    deleteTransaction = (deleteId, reportType) => {
        const { timeStamp } = this.state;
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this transaction`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deleteTransaction(deleteId, timeStamp, reportType); }
                },
                {
                    label: 'No',
                    onClick: () => {  }
                }
            ]
        };
        confirmAlert(deleteOptions);
    };

    deleteMultiTransaction = (deleteIds) => {
        const { timeStamp } = this.state;
        this.props.deleteMultiTransaction(deleteIds, timeStamp);
    };
    deleteTipMultiTransaction = (deleteIds) => {
        const { timeStamp } = this.state;
        this.props.deleteTipMultiTransaction(deleteIds, timeStamp);
    };

    switchTransaction = (customerId) => {
        const { reportType } = this.state;
        this.props.switchTransaction(customerId, reportType);
    };
    editTransaction = (price, transactionId, tip = 0) => {
        this.props.getTip(transactionId);
        this.setState({showModal: true, modalTitle: 'Edit Transaction Price', modalContent: '', modalSpecialContent: true, editTransactionPrice:price, editTransactionTip:tip, transactionId:transactionId});
    };
    saveTransaction = (price, transactionId) => {
        this.closeModal();
        this.props.saveTransaction(price, transactionId, this.state.reportType, this.state.timeStamp);
    };
    saveTip = (tip, transactionId) => {
        this.closeModal();
        this.props.saveTip(tip, transactionId, this.state.reportType, this.state.timeStamp);
    };
    viewTransaction = (viewTransactionId) => {
        this.setState({ showModal: true, modalTitle: 'View Transaction Details', modalContent: '', modalSpecialContent: false, editTransactionPrice:0, editTransactionTip:0, transactionId:'' });
        this.props.lookupTransaction(viewTransactionId);
    };
    UpdateSearchReport = (reportType, startDate, endDate) => {
        this.setState({ showLoading: 1 });
        this.props.searchReport(reportType, startDate, endDate);
    };
    onSendEmail = () => {
        const { reportType, timeStamp } = this.state;
        this.props.sendEmailReport(reportType, timeStamp);
        this.props.alert.success('Report has been emailed!');
    };
    saveEmailAddresses = () => {
        const { emailAddresses, reportType } = this.state;
        this.props.saveEmailAddresses( emailAddresses, reportType );
    };
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    getReport = (reportType, newtimeStamp) => {
        let timeStamp = this.state.timeStamp;
        if (newtimeStamp !== '') timeStamp = newtimeStamp;
        this.setState({reportType: reportType, reportData: {}, emailAddresses: ''});
        this.props.getReport(reportType, timeStamp);
    };
    loadSubscriberModal = () => {
        this.setState({ showEmailModal: true });
    };
    closeEmailModal = () => {
        this.setState({ showEmailModal: false });
    };
    closeModal = () => {
        this.setState({ showModal: false, modalContent: '', modalSpecialContent: false, editTransactionPrice:0, editTransactionTip:0, transactionId:'' });
    };

    ShowHotels = () => {
        this.setState( { showHotels: true });
    };
    HideHotels = () => {
        this.setState( { showHotels: false });
    };

    convertToCamleCase = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '').replace('&', '');
    }

    render() {
        const { user } = this.props.auth;
        const {
            showLoading,
            startDate,
            startTime,
            endDate,
            endTime,
            currentDate,
            reportType,
            timeStamp,
            reportData,
            showModal,
            modalTitle,
            modalContent,
            modalSpecialContent,
            showEmailModal,
            emailAddresses,
            showHotels,
            hotels
        } = this.state;

        return (<div className="container">
            <div className="onlyprint"><h1>Winters Beach</h1></div>
            <div className="row noprint">
                <div className="col-12">
                    <h1 className="text-center my-4">Reports</h1>

                    {user.user.userType === 'A' && showHotels === false && <>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.ShowHotels()}}>Hotels</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('userLog')}}>User Log</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('tip')}}>All Transaction</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('agentTip')}}>By Agent</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('creditCardTips')}}>Credit Card</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('move')}}>Move</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('searchSales')}}>Search</button>
                    </>}

                    {user.user.userType === 'S' && showHotels === false && <>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.ShowHotels()}}>Hotels</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('salesLocation')}}>Location</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('salesAgent')}}>Agent</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('hourlySales')}}>Hourly</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('allTransactions')}}>Transactions</button>
                        <button className="btn btn-sm btn-secondary m-1" onClick={() => {this.getReport('userLog')}}>User Log</button>
                    </>}

                    {showHotels === true && <>
                        {user.user.userType === 'S' || user.user.userType === 'A' && <>
                            <button className="btn btn-sm btn-warning m-1" onClick={() => {this.HideHotels()}}>Back</button>

                            {hotels && hotels.map((hotel, index) => {
                                return (<button key={index} className="btn btn-sm btn-secondary m-1" onClick={() => { this.getReport(this.convertToCamleCase(hotel.Name) + "RoomCharge"); }}>{hotel.Name}</button>);
                            })}

                        </>}
                    </>}

                    <hr noshade="true" />
                </div>
            </div>
            {reportType === '' && <div className="row">
                <div className="col-12 text-center">
                    <h3>Select a report to view</h3>
                </div>
            </div>}
            {currentDate && reportType && <div className="row">
                <div className="col-10 my-0">
                    {reportType !== 'searchSales' &&
                        <>
                            {user.user.userType === 'A' && <button
                                className="noprint btn btn-warning btn-sm mx-3"
                                onClick={() => { this.getReport(reportType, startTime) }}
                            >{startDate}</button>}

                            <h3 className="onlyprint">{currentDate}</h3>
                            <span className="noprint">{currentDate}</span>

                            {user.user.userType === 'A' && <button
                                className="noprint btn btn-warning btn-sm mx-3"
                                onClick={() => { this.getReport(reportType, endTime) }}
                            >{endDate}</button>}
                        </>
                    }
                </div>
                {user.user.userType === 'A' && <div className="noprint col-2 text-right my-0">
                    <button
                        className="btn btn-warning btn-sm"
                        onClick={() => window.print()}
                    ><i className="fas fa-print"/> PRINT</button>
                </div>}
            </div>}
            <hr noshade="true" />
            { typeof(reportType) !== 'undefined' && <>
                { reportType && reportData.length !== 0 && <>
                    { reportType !== 'searchSales' && user.user.userType === 'A' && <div className="row noprint">
                        <div className="col-12 text-right">
                            <button className="btn btn-warning btn-sm mr-2" onClick={() => { this.onSendEmail(); }}><i className="fas fa-envelope" /></button>
                            <button className="btn btn-secondary btn-sm" onClick={() => { this.loadSubscriberModal(); }}><i className="fas fa-plus-square" /> &nbsp; Add Subscriber</button>
                        </div>
                    </div>}
                    <ReportWrapper
                        user={user}
                        reportType={reportType}
                        timeStamp={timeStamp}
                        reportData={reportData}
                        showLoading={showLoading}
                        switchTransaction = { this.switchTransaction }
                        viewTransaction = { this.viewTransaction }
                        editTransaction = { this.editTransaction }
                        deleteTransaction = { this.deleteTransaction }
                        deleteMultiTransaction = { this.deleteMultiTransaction }
                        deleteTipMultiTransaction = { this.deleteTipMultiTransaction }
                        UpdateSearchReport = { this.UpdateSearchReport }
                    />
                </>}
            </>}
            <Modal show={showEmailModal} onHide={this.closeEmailModal}>
                <Modal.Header closeButton><Modal.Title>Add / View Subscribers</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>Enter the list of email addresses you want to receive an email copy of this report. Separate each one by a comma.</p>
                            <textarea cols="10" rows="4" className="form-control" name="emailAddresses" onChange={this.onChange} value={emailAddresses} />
                            <button onClick={() => { this.saveEmailAddresses(); }} className="btn btn-block btn-success mt-3">Save Email Addresses</button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer><Button variant="secondary" onClick={this.closeEmailModal}>Close</Button></Modal.Footer>
            </Modal>
            <Modal show={showModal} onHide={this.closeModal}>
                <Modal.Header closeButton><Modal.Title>{modalTitle}</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            { modalSpecialContent === false && (modalContent === '' || typeof(modalContent) === 'undefined') && <Loading /> }
                            { modalSpecialContent === false && modalContent !== '' && <pre>{modalContent}</pre> }
                            { modalSpecialContent === true && <>
                                <div className="container">
                                    <div className="row">
                                        <label htmlFor="editTransactionPrice" className="col-3">Price</label>
                                        <div className="col-6">
                                            <input
                                                type="text"
                                                id="editTransactionPrice"
                                                name="editTransactionPrice"
                                                className="form-control"
                                                placeholder="Price"
                                                onChange={this.onChange}
                                                value={this.state.editTransactionPrice}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <button
                                                className="btn btn-sm btn-success"
                                                onClick={()=>this.saveTransaction(this.state.editTransactionPrice, this.state.transactionId)}
                                            >Save</button>
                                        </div>
                                    </div>

                                    {user.user.userType === 'A' && <>
                                        <hr noshade="true" />
                                        <div className="row">
                                            <label htmlFor="editTransactionPrice" className="col-3">Tip</label>
                                            <div className="col-6">
                                                <input
                                                    type="text"
                                                    id="editTransactionTip"
                                                    name="editTransactionTip"
                                                    className="form-control"
                                                    placeholder="Price"
                                                    onChange={this.onChange}
                                                    value={this.state.editTransactionTip}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <button
                                                    className="btn btn-sm btn-success"
                                                    onClick={()=>this.saveTip(this.state.editTransactionTip, this.state.transactionId)}
                                                >Save</button>
                                            </div>
                                        </div>
                                    </>}

                                </div>
                            </>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer><Button variant="secondary" onClick={this.closeModal}>Close</Button></Modal.Footer>
            </Modal>
        </div>)
    }
}

DailyReports.propTypes = {
    reportData: PropTypes.object.isRequired,
    getReport: PropTypes.func.isRequired,
    saveEmailAddresses: PropTypes.func.isRequired,
    sendEmailReport: PropTypes.func.isRequired,
    searchReport: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data,
    reportData: state.pages.reportData,
    emails: state.pages.emails,
    transactionData: state.pages.transactionData
});

export default connect( mapStateToProps, {
    getReport,
    deleteTransaction,
    deleteTipMultiTransaction,
    deleteMultiTransaction,
    saveTransaction,
    saveTip,
    getTip,
    saveEmailAddresses,
    searchReport,
    sendEmailReport,
    switchTransaction,
    lookupTransaction,
    getHotels
})(withAlert(DailyReports));
