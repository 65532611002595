import React, {Component, Fragment} from 'react';
import connect from "react-redux/es/connect/connect";
import SelectReceiptType from "./UnitPurchase/SelectReceiptType";
import { getResendReceipt, getUpdateReceipt } from "../../../client/actions/apiActions";
import {withAlert} from "react-alert";
import {confirmAlert} from "react-confirm-alert";

class UnitCustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditReceipt: false,
            customerId: '',
            receiptType: '',
            receiptPhone: '',
            receiptEmail: ''
        }
    }
    componentWillUnmount() {
        this.setState({
            showEditReceipt: false,
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (typeof(this.props.receiptData) !== "undefined") {
                if (typeof (this.props.receiptData.saveReceiptSuccess) !== "undefined") {
                    if (this.props.receiptData.phoneSent === true || this.props.receiptData.emailSent === true) {
                        this.setState({
                            showEditReceipt: false,
                            customerId: '',
                            receiptType: '',
                            receiptPhone: '',
                            receiptEmail: ''
                        });
                    }
                }
            }
        }
    }

    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onChangeRadio = (name, value) => this.setState({[name]: value});

    cancelEditReceipt = () => {
        this.setState({
            showEditReceipt: false,
            customerId:'',
            receiptType:'',
            customerPhone: '',
            customerEmail: ''
        });
    }
    editReciept = (customerId, receiptType, receiptPhone, receiptEmail) => {
        this.setState({
            showEditReceipt: true,
            customerId,
            receiptType,
            customerPhone: receiptPhone,
            customerEmail: receiptEmail
        });
    }
    saveReceiptType = () => {
        const { customerId, receiptType, customerPhone, customerEmail } = this.state;
        const { unitId } = this.props;
        this.props.getUpdateReceipt(unitId, customerId, receiptType, customerPhone, customerEmail)
    }
    resendReciept = (customerId) => {
        this.props.getResendReceipt(customerId, 1);
    };

    confirmMoveCustomer = (customerId, unitId) => {
        confirmAlert({
            title: 'Confirm Move',
            message: `Move this customer?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.moveCustomer(customerId, unitId); }
                },
                {
                    label: 'No',
                    onClick: () => {  }
                }
            ]
        });
    };

    render() {
        const { customers, reseatCustomer, markUnitEmpty, unitId, moveCustomer } = this.props;
        const { showEditReceipt, receiptType, customerPhone, customerEmail     } = this.state;
        return (
            <div className="card card-primary">
                <div className="card-header">Customers</div>
                <div className="card-body">

                    {showEditReceipt === false && <>
                        <button type="submit" className="btn btn-primary mb-3" onClick={() => markUnitEmpty(unitId)}>Mark Empty</button>

                        <table className="table table-striped">
                            <thead>
                                <tr className="bg-dark text-light">
                                    <th>Time</th>
                                    <th>Name</th>
                                    <th>Payment</th>
                                    <th>Price</th>
                                    <th>Tools</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers && <>
                                    {customers.map((customer, index) => {
                                        return (<React.Fragment key={index}>
                                            <tr>
                                                <td>{customer.epoc}</td>
                                                <td>{customer.customerName}</td>
                                                <td>{customer.paymentMethod}</td>
                                                <td>{customer.price}</td>
                                                <td>
                                                    <button className="btn btn-secondary btn-sm mr-1" onClick={() => reseatCustomer(customer.id, unitId)}><i className="fas fa-umbrella-beach" /> Re-Seat</button>

                                                    {customer.receiptType !== '' && <button className="btn btn-secondary btn-sm mr-1" onClick={() => this.resendReciept(customer.id)}>
                                                        <i className="fas fa-receipt" />
                                                    </button>}

                                                    {customer.receiptType !== '' && <button className="btn btn-secondary btn-sm mr-1" onClick={() => this.editReciept(customer.id, customer.receiptType, customer.receiptPhone, customer.receiptEmail)}>
                                                        <i className="fas fa-pencil-alt" />
                                                    </button>}

                                                    {' '}

                                                    <button className="btn btn-warning btn-sm mr-1" onClick={() => this.confirmMoveCustomer(customer.id, unitId)}><i className="fas fa-shipping-fast" /></button>
                                                </td>
                                            </tr>
                                        </React.Fragment>);
                                    })}
                                </>}
                            </tbody>
                        </table>

                        <button type="submit" className="btn btn-primary" onClick={() => markUnitEmpty(unitId)}>Mark Empty</button>
                    </>}

                    {showEditReceipt === true && <>
                        <SelectReceiptType
                            receiptType={receiptType}
                            customerPhone={customerPhone}
                            customerEmail={customerEmail}
                            onChange={this.onChange}
                            onChangeRadio={this.onChangeRadio}
                            saveReceiptType={this.saveReceiptType}
                            editMode={true}
                        />
                        <div className="col-12 text-center mt-2"><button type="submit" className="btn btn-warning" onClick={() => this.cancelEditReceipt()}>Cancel</button></div>
                    </>}

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    receiptData: state.units.receiptData,
});

export default connect(mapStateToProps, { getUpdateReceipt, getResendReceipt })(withAlert(UnitCustomerList));
