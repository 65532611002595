import React, {Component, Fragment} from 'react';
import ItemRadio from "../../../form/itemRadio";
import ItemCheckbox from "../../../form/itemCheckbox";

class SelectEquipment extends Component {
    render() {
        const { wyndhamExtraCharge, equipmentData, roomChargeHotel, roomNumber, hotelRoomNumber, onChangeRadio, selectedUnit, handleCheckboxChange, saveSelectedEquipment } = this.props;
        let currentDateTime = new Date();
        let currentTime = currentDateTime.getHours();
        let currentMin = currentDateTime.getMinutes();

        // check to see if minutes is 45 or later meaning its 2:45 or later and update the equipment time to show cheaper price.
        if (currentTime === 14 && currentMin >= 45) currentTime = 15;

        let roomEnding = 0;

        if (typeof (roomNumber) !== 'undefined' && typeof (roomNumber) !== 'object' && roomNumber !== '') {
            let roomNumberLength = roomNumber.toString().length;
            if (hotelRoomNumber !== '' && hotelRoomNumber !== null && typeof (hotelRoomNumber) !== 'undefined' && typeof (roomNumber) !== 'undefined') {
                roomEnding = roomNumber.toString().substring(roomNumberLength - 2, roomNumberLength);
            } else if (typeof (roomNumber) !== 'undefined' && roomNumber !== '') {
                roomEnding = roomNumber.toString().substring(roomNumberLength - 2, roomNumberLength);
            }
        }

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <h3 className="mt-3">Equipment</h3>
                    </div>
                </div>
                {roomChargeHotel === "Wyndham" && roomEnding >= 70 && <Fragment>WVO Guest<br /></Fragment>}
                <div className="row">

                    {
                        wyndhamExtraCharge !== 1 &&
                        (
                            (roomChargeHotel !== "Wyndham") ||
                            (roomChargeHotel === "Wyndham" && roomEnding >= 70)
                        )
                        &&
                        <React.Fragment>
                        {equipmentData && equipmentData.equipment.map((unit, index)=>
                            <React.Fragment key={index}>
                                {parseInt(unit.startTime) <= currentTime && parseInt(unit.endTime) >= currentTime &&
                                    <div className="col-6 px-1">
                                        <ItemRadio
                                            label={unit.name}
                                            unitPrice={unit.price}
                                            name="selectedUnit"
                                            radioId={"unitType" + unit.unitType}
                                            value={unit.unitType}
                                            placeholder="Umbrella Type"
                                            onChangeRadio={onChangeRadio}
                                            selectedValue={selectedUnit}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        )}
                        </React.Fragment>
                    }
                    {
                        wyndhamExtraCharge !== 1 &&
                        (roomChargeHotel === "Wyndham" && roomEnding < 70)
                        &&
                        <React.Fragment>
                        {equipmentData && equipmentData.hotelEquipment.map((unit, index)=>
                            <React.Fragment key={index}>
                                {parseInt(unit.startTime) <= currentTime && parseInt(unit.endTime) >= currentTime &&
                                    <div className="col-6 px-1">
                                        <ItemRadio
                                            label={unit.name}
                                            unitPrice={unit.price}
                                            name="selectedUnit"
                                            radioId={"unitType" + unit.unitType}
                                            value={unit.unitType}
                                            placeholder="Umbrella Type"
                                            onChangeRadio={onChangeRadio}
                                            selectedValue={selectedUnit}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        )}
                        </React.Fragment>
                    }
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <h3 className="mt-3">Extras</h3>
                    </div>
                </div>
                <div className="row">
                    {
                        (
                            (roomChargeHotel !== "Wyndham") ||
                            (roomChargeHotel === "Wyndham" && roomEnding >= 70)
                        )
                        &&
                        <React.Fragment>
                        {equipmentData && equipmentData.addons && equipmentData.addons.map((unit, index)=>
                            <React.Fragment key={index}>
                            {parseInt(unit.startTime) <= currentTime && parseInt(unit.endTime) >= currentTime &&
                                <div className="col-6 px-1">
                                    <ItemCheckbox
                                        label={unit.name + " ($" + unit.price + ")"}
                                        name="selectedUnit"
                                        radioId={"unitType" + unit.unitType}
                                        value={unit.unitType}
                                        checkedValues={selectedUnit}
                                        handleCheckboxChange={handleCheckboxChange}
                                    />
                                </div>
                            }
                            </React.Fragment>
                        )}
                        </React.Fragment>
                    }
                </div>

                {selectedUnit &&
                    <div className="row">
                        <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                            <button
                                className="btn btn-block btn-secondary btn-lg mt-4"
                                onClick={() => saveSelectedEquipment()}
                            >
                                CONTINUE
                            </button>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default SelectEquipment
