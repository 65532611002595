import axios from "axios";
import { returnErrors } from "./messages";
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGIN_2FA
} from './types';
import { ApiURL } from "../../config";

// CHECK TOKEN AND LOAD USER
export const loadUser = (appVersion) => async (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    try {
        let res = null;
        if (appVersion === '' || appVersion === null || typeof(appVersion) === 'undefined')
        {
            res = await axios.get(`${ApiURL}/auth/user`, tokenConfig(getState));
        }
        else
        {
            res = await axios.get(`${ApiURL}/auth/user/${appVersion}`, tokenConfig(getState));
        }
        dispatch({type: USER_LOADED, payload: res.data});
    }
    catch (err) {
        dispatch({type: AUTH_ERROR});
    }
};
export const login = (username, password, appVersion) => async (dispatch) => {
    try {
        let res = null;
        res = await axios.post(
        `${ApiURL}/auth/login`,
        { username: username, password: password, appVersion: appVersion },
        {withCredentials: true}
        );

        if (res.data.AUTH2FA === true) {
            dispatch({
                type: LOGIN_2FA,
                payload: res.data
            });
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
        }

    }
    catch (err) {
        dispatch(returnErrors(err.response, err.error));
        dispatch({
            type: LOGIN_FAIL
        })
    }
};
export const loginAuthCode = (loginAuthCode) => async (dispatch) => {
    try {
        let res = null;
        res = await axios.post(
        `${ApiURL}/auth/loginAuthCode`,
        { loginAuthCode },
            {withCredentials: true}
        );
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response, err.error));
        dispatch({
            type: LOGIN_FAIL
        })
    }
};
export const logout = (e) => (dispatch, getState) => {
    e.preventDefault();
    axios
        .post(`${ApiURL}/auth/logout`, null, tokenConfig(getState))
        .then(res=>{
            dispatch({type: LOGOUT_SUCCESS});
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};

export const tokenConfig = (getState) => {
    // GET TOKEN FROM STATE
    const token = getState().auth.token;

    // HEADERS
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type":"application/json"
        }
    };

    // IF TOKEN ADD TO HEADERS
    if (token) {
        config.headers['Authorization']= token;
    }
    return config;
};
