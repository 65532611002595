import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import { checkItemPrice } from "../../../../client/actions/apiActions";
import Loading from "../../../layout/Loading";

class SelectReceiptType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      roomChargeHotel: "",
      tipAmount: 0,
      retailRate: 0,
      tipableTotal: 0,
      androidMessage: "",
      cartUnits: [],
      hasCartUnits: false,
      thisUnit: false,
      readerConnected: false,
      paymentMethod: "",
      loading: true,
    };
    this.intervalHandle = null;
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
  }

  componentDidMount() {
    this.checkPrices();
    this.props.logAction(
      "tip screen loaded - multi unit: " +
        this.props.hasCartUnits +
        " paymentMethod " +
        this.props.paymentMethod
    );
  }

  componentWillUnmount() {
    this.stopTimer();
    this.setState({
      errorMessage: "",
      receiptType: "",
      customerPhone: "",
      customerEmail: "",
      showModal: false,
      androidMessage: "",
      cartUnits: [],
      hasCartUnits: false,
      thisUnit: false,
      readerConnected: false,
      paymentMethod: "",
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (
        this.props.itemPriceData &&
        this.props.pageName === "checkItemPrice"
      ) {
        if (
          this.props.itemPriceData?.retailRate !== this.state.retailRate ||
          this.state.tipableTotal !== this.props.itemPriceData.retailRate
        ) {
          this.updatePrices();
        }
      }
    }
  }

  checkPrices = () => {
    let extraCharge = this.props.wyndhamExtraCharge
      ? 1
      : this.props.hyattExtraCharge
      ? 1
      : 0;
    let selectedUnits = [];
    let roomChargeHotel = "";

    if (this.props.hasCartUnits === true) {
      this.props.cartUnits.map((unit) => {
        selectedUnits.push(unit.selectedUnit);

        let selectedAddons = "";
        if (
          unit.selectedAddons !== null &&
          typeof unit.selectedAddons !== "undefined"
        ) {
          selectedAddons = unit.selectedAddons.split(",");
          if (selectedAddons.length > 0) {
            selectedAddons.map((item) => {
              if (item) {
                selectedUnits.push(item);
              }
              return null;
            });
          }
        }

        roomChargeHotel = unit.roomChargeHotel;
        return null;
      });
    } else {
      selectedUnits.push(this.props.selectedUnit);
      roomChargeHotel = this.props.roomChargeHotel;

      let selectedAddons = "";
      if (
        this.props.selectedAddons !== null &&
        typeof this.props.selectedAddons !== "undefined"
      ) {
        selectedAddons = this.props.selectedAddons.split(",");
        if (selectedAddons.length > 0) {
          selectedAddons.map((item) => {
            if (item) {
              selectedUnits.push(item);
            }
            return null;
          });
        }
      }
    }

    this.props.checkItemPrice(selectedUnits, extraCharge, roomChargeHotel);
    this.updatePrices();
    this.setState({
      roomChargeHotel: roomChargeHotel,
    });
  };
  updatePrices = () => {
    let addonTotal = this.props.addonTotal;
    let currentDateTime = new Date();
    let currentTime = currentDateTime.getHours();
    let cartUnits = this.props.cartUnits;
    let equipmentData = this.props.equipmentData;
    let hasCartUnits = this.props.hasCartUnits;
    let lastName = this.props.lastName;
    let originalTotal = this.props.orderTotal;
    let orderTotal = this.props.orderTotal;
    let paymentMethod = this.props.paymentMethod;
    let retailRate = this.props.orderTotal;
    let roomChargeHotel = this.props.roomChargeHotel;
    let roomEnding = 0;
    let roomNumber = this.props.roomNumber;
    let selectedAddons = this.props.selectedAddons;
    let selectedAddonList = [];
    let selectedUnitPrice = this.props.selectedUnitPrice;
    let tipableTotal = 0;

    if (this.props.itemPriceData && this.props.itemPriceData.retailRate) {
      retailRate = parseInt(this.props.itemPriceData.retailRate);
      tipableTotal = retailRate;
    }

    if (this.props.hasCartUnits === true) {
      let thisUnit = false;
      this.props.cartUnits.map((unit) => {
        if (unit.unitId === this.props.unitId) {
          thisUnit = true;
        }
        return null;
      });

      if (thisUnit === true) {
        this.setState({
          cartUnits: this.props.cartUnits,
          hasCartUnits: this.props.hasCartUnits,
          thisUnit: thisUnit,
        });
      } else {
        const { cartUnits } = this.props;
        paymentMethod = cartUnits[0].paymentMethod;
        let unitInfo = {
          unitId: this.props.unitId,
          unitType: this.props.unitType,
          unitNumber: this.props.unitNumber,
          unitLocation: this.props.unitLocation,
          thisUnit: thisUnit,

          roomChargeHotel: cartUnits[0].roomChargeHotel,
          roomNumber: cartUnits[0].roomNumber,
          lastName: cartUnits[0].lastName,
          manualEntry: cartUnits[0].manualEntry,

          selectedUnitPrice: this.props.selectedUnitPrice,
          selectedUnit: this.props.selectedUnit,
          selectedAddons: selectedAddons,

          orderTotal: orderTotal,
          retailRate: retailRate,
          addonTotal: addonTotal,

          wyndhamExtraCharge: this.props.wyndhamExtraCharge,
          wyndhamAdditionalCharge: this.props.wyndhamAdditionalCharge,

          hyattExtraCharge: this.props.hyattExtraCharge,
          hyattAdditionalCharge: this.props.hyattAdditionalCharge,
        };
        let newCartUnits = {};

        let cartUnitsFound = cartUnits.filter(
          (filterUnit) => filterUnit.unitId !== this.props.unitId
        );
        newCartUnits = [...cartUnitsFound, unitInfo];
        this.setState({
          tipableTotal: tipableTotal,
          cartUnits: newCartUnits,
          retailRate: retailRate,
          hasCartUnits: this.props.hasCartUnits,
        });
      }
    }

    orderTotal += addonTotal;
    if (selectedAddons !== null) {
      selectedAddonList = selectedAddons.split(",");
    }

    if (hasCartUnits === true) {
      lastName = cartUnits[0].lastName;
      roomChargeHotel = cartUnits[0].roomChargeHotel;
      roomNumber = cartUnits[0].roomNumber;
      orderTotal = 0;
      if (paymentMethod === "" || paymentMethod === null) {
        paymentMethod = cartUnits[0].paymentMethod;
      }
    }

    if (hasCartUnits !== true) {
      if (
        selectedUnitPrice === 0.0 ||
        selectedUnitPrice === "0.00" ||
        selectedUnitPrice === ""
      ) {
        //tipableTotal += Number.parseFloat(35);
      } else {
        //tipableTotal += Number.parseFloat(selectedUnitPrice);
      }
    }

    if (hasCartUnits === true) {
      cartUnits.map((unit) => {
        let selectedAddons = "";
        if (
          unit.selectedAddons !== null &&
          typeof unit.selectedAddons !== "undefined"
        ) {
          selectedAddons = unit.selectedAddons.split(",");
        }
        orderTotal = Number.parseFloat(unit.selectedUnitPrice) + orderTotal;
        if (
          unit.selectedUnitPrice === "0" ||
          unit.selectedUnitPrice === "0.00" ||
          unit.selectedUnitPrice === 0
        ) {
          //tipableTotal += Number.parseFloat(35);
        } else {
          //tipableTotal += Number.parseFloat(unit.selectedUnitPrice);
        }

        if (selectedAddons !== "" && selectedAddons.length > 1) {
          selectedAddons.map((addon) => {
            if (addon !== "") {
              equipmentData.addons.map((addonName) => {
                if (addonName.unitType === addon) {
                  orderTotal = Number.parseFloat(addonName.price) + orderTotal;
                  //tipableTotal += Number.parseFloat(addonName.price);
                }
                return null;
              });
            }
            return null;
          });
        }
        return null;
      });
    } else {
      tipableTotal = retailRate;
    }

    if (retailRate < orderTotal) {
      retailRate = orderTotal;
    }

    this.setState({
      paymentMethod,
      originalTotal,
      retailRate,
      orderTotal,
      addonTotal,
      roomChargeHotel,
      selectedAddonList,
      currentTime,
      tipableTotal,
      lastName,
      roomNumber,
      selectedUnitPrice,
      roomEnding,
      loading: false,
    });

    if (
      paymentMethod !== "Voucher" &&
      paymentMethod !== "Cash" &&
      !this.props.tipType
    ) {
      this.selectTip(5);
    }
    this.props.logAction(
      "tip screen updated - multi unit: " +
        this.props.hasCartUnits +
        " paymentMethod " +
        paymentMethod +
        " tipable Total : " +
        tipableTotal
    );
  };

  selectTip = (clickedTipAmount, tipType) => {
    this.setState({ tipAmount: clickedTipAmount, tipType: tipType });
    this.props.updateTipAmount(clickedTipAmount, tipType);
  };

  connectToSQCC = (totalAmount, customerName) => {
    this.props.logAction(
      "loading square to charge " + customerName + " for " + totalAmount
    );

    if (window.Android) {
      try {
        this.setState({
          androidMessage: "Connecting to Square Checkout ...",
          readerConnected: true,
        });
        window.Android.ChargeAmount(totalAmount, customerName);
        this.startTimer();
      } catch (e) {
        this.setState({
          androidMessage: "Error running Square Checkout ...",
          readerConnected: false,
        });
      }
    } else if (!window.Android) {
      this.setState({
        androidMessage: "Couldn't connect to 'Android' App",
        readerConnected: false,
      });
      this.startTimer();

      // REMOVE BELOW WHEN DONE TESTING CC
      window.updateReaderData(true, "success");
    }
  };
  checkReaderData = () => {
    try {
      this.stopTimer();
      let readerData = window["getReaderData"]();
      if (
        readerData["success"] === false ||
        readerData["success"] === "false"
      ) {
        this.startTimer();
      } else if (
        readerData["success"] === true ||
        readerData["success"] === "true"
      ) {
        this.props.completeOrder();
      } else {
        this.startTimer();
      }
    } catch (e) {
      this.setState({ androidMessage: "Unable to read window function" });
    }
  };

  startTimer = () => {
    if (this.intervalHandle === null) {
      this.setState({ androidMessage: "Loading Square Up Reader ..." });
      this.intervalHandle = setTimeout(this.checkReaderData, 1000);
    }
  };
  stopTimer = () => {
    if (this.intervalHandle !== null) {
      clearTimeout(this.intervalHandle);
      this.intervalHandle = null;
    }
  };

  render() {
    const {
      equipmentData,
      tipAmount,
      nameSelected,
      equipmentSelected,
      selectedUnit,
      selectedUnitPrice,
      selectedAddons,
      ccErrorMessage,
    } = this.props;
    const {
      androidMessage,
      errorMessage,
      unitType,
      unitNumber,
      lastName,
      unitLocation,
      roomNumber,
      paymentMethod,
      retailRate,
      tipableTotal,
      cartUnits,
      hasCartUnits,
      orderTotal,
      roomEnding,
      roomChargeHotel,
      selectedAddonList,
      currentTime,
      loading,
    } = this.state;

    console.log("orderTotal", orderTotal);

    if (loading) {
      return <Loading />;
    }
    return (
      <>
        <div className="row">
          <div className="col-12 text-center">
            <div className="container">
              {hasCartUnits === true && (
                <>
                  {roomNumber === null && (
                    <div className="row my-1">
                      <div className="col-12">
                        <h5 className="text-center mt-2">Name : {lastName}</h5>
                      </div>
                    </div>
                  )}
                  <div className="row my-1">
                    <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                      <table className="table table-sm table-striped table-bordered">
                        <tbody>
                          <tr>
                            <td colSpan="3" className="text-center">
                              <b>Equipment</b>
                            </td>
                          </tr>
                          {cartUnits.map((unit, index) => {
                            let selectedAddons = "";
                            if (
                              unit.selectedAddons !== null &&
                              typeof unit.selectedAddons !== "undefined"
                            ) {
                              selectedAddons = unit.selectedAddons.split(",");
                            }
                            return (
                              <Fragment key={index}>
                                <tr>
                                  <td>{unit.unitType + unit.unitNumber}</td>
                                  <td>{unit.selectedUnit}</td>
                                  {Number.parseFloat(unit.selectedUnitPrice) !==
                                    0 && (
                                    <td>
                                      ${" "}
                                      {Number.parseFloat(
                                        unit.selectedUnitPrice
                                      )}
                                      .00
                                    </td>
                                  )}
                                  {Number.parseFloat(unit.selectedUnitPrice) ===
                                    0 && <td>(included)</td>}
                                </tr>
                                {selectedAddons !== "" &&
                                  selectedAddons.length > 1 && (
                                    <>
                                      {selectedAddons.map((addon, index2) => {
                                        return (
                                          <Fragment key={index2}>
                                            {addon !== "" && (
                                              <>
                                                {equipmentData.addons.map(
                                                  (addonName, index3) => {
                                                    if (
                                                      addonName.unitType ===
                                                      addon
                                                    ) {
                                                      return (
                                                        <tr key={index3}>
                                                          <td>&nbsp;</td>
                                                          <td>
                                                            {addonName.name}
                                                          </td>
                                                          <td>
                                                            ${" "}
                                                            {Number.parseFloat(
                                                              addonName.price
                                                            )}
                                                            .00
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                    return null;
                                                  }
                                                )}
                                              </>
                                            )}
                                          </Fragment>
                                        );
                                      })}
                                    </>
                                  )}
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
              {hasCartUnits !== true && (
                <>
                  {equipmentSelected === true && (
                    <div className="row my-0">
                      <div className="col-12">
                        <h5 className="text-right mt-0">
                          Equipment : {selectedUnit}{" "}
                          {selectedUnitPrice !== "0.00" &&
                            selectedUnitPrice !== 0.0 &&
                            selectedUnitPrice !== 0 &&
                            selectedUnitPrice !== "" &&
                            selectedUnitPrice !== null && (
                              <>$ {selectedUnitPrice}</>
                            )}
                          {(selectedUnitPrice === 0.0 ||
                            selectedUnitPrice === "0.00" ||
                            selectedUnitPrice === "") && (
                            <>(included) Retail: $ 35.00</>
                          )}
                        </h5>
                      </div>
                    </div>
                  )}
                  {selectedAddons !== null && (
                    <div className="row my-0">
                      <div className="col-12 text-right">
                        <h5 className="text-right mt-0">Addons</h5>
                        {selectedAddonList.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              {roomChargeHotel === "Hyatt" &&
                                equipmentData.hotelAddons &&
                                equipmentData.hotelAddons.map((unit, index) => {
                                  return (
                                    <Fragment key={index}>
                                      {parseInt(unit.startTime) <=
                                        currentTime &&
                                        parseInt(unit.endTime) >= currentTime &&
                                        unit.unitType === item &&
                                        unit.projectHotels ===
                                          roomChargeHotel && (
                                          <>
                                            {unit.name} ${unit.price}{" "}
                                            {unit.unitType}
                                            <br />
                                          </>
                                        )}
                                    </Fragment>
                                  );
                                })}

                              {roomChargeHotel !== "Wyndham" &&
                                roomChargeHotel !== "Hyatt" &&
                                equipmentData.addons &&
                                equipmentData.addons.map((unit, index) => {
                                  return (
                                    <Fragment key={index}>
                                      {parseInt(unit.startTime) <=
                                        currentTime &&
                                        parseInt(unit.endTime) >= currentTime &&
                                        unit.unitType === item && (
                                          <>
                                            {unit.name} ${unit.price}
                                            <br />
                                          </>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {nameSelected === true && roomNumber === null && (
                    <div className="row my-0">
                      <div className="col-12">
                        <h5 className="text-right mt-0">Name : {lastName}</h5>
                      </div>
                    </div>
                  )}
                </>
              )}
              <hr />

              {retailRate !== orderTotal && (
                <div className="row my-0">
                  <div className="col-12">
                    <h5 className="text-right mt-0">
                      Retail Value : ${Number.parseFloat(retailRate).toFixed(2)}
                    </h5>
                  </div>
                </div>
              )}
              <div className="row my-0">
                <div className="col-12">
                  <h5 className="text-right mt-0">
                    Charge Sub Total : $
                    {Number.parseFloat(orderTotal).toFixed(2)}
                  </h5>
                </div>
              </div>
              <div className="row my-0">
                <div className="col-12">
                  <h5 className="text-right mt-0">
                    Tip : ${Number.parseFloat(tipAmount).toFixed(2)}
                  </h5>
                </div>
              </div>
              <div className="row my-0">
                <div className="col-12">
                  <h5 className="text-right mt-0">
                    Charge Total : $
                    {Number.parseFloat(orderTotal + tipAmount).toFixed(2)}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center">
            <div className="container py-2">
              {paymentMethod !== "Voucher" && paymentMethod !== "Cash" && (
                <>
                  <div className="row mb-2">
                    <div className="col">
                      <h3 className="text-center">
                        <i className="fas fa-umbrella-beach" />
                      </h3>
                    </div>
                    <div className="col-auto">
                      <h3 className="text-center">
                        Thank the crew for
                        <br />
                        keeping you cool?
                      </h3>
                    </div>
                    <div className="col">
                      <h3 className="text-center">
                        <i className="fas fa-umbrella-beach" />
                      </h3>
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                  )}
                  <div className="row mb-1">
                    <div className="col">
                      <button
                        className={`btn btn-lg ${
                          tipAmount === 3
                            ? "btn-warning"
                            : "btn-outline-secondary"
                        } mr-1`}
                        onClick={() => this.selectTip(3, "tip3")}
                      >
                        $3
                        <br />
                        (${(3).toFixed(2)})<br />
                        <h1>
                          <i className="fas fa-sun" />
                        </h1>
                      </button>

                      <button
                        className={`btn btn-lg ${
                          tipAmount === 5
                            ? "btn-warning"
                            : "btn-outline-secondary"
                        } m-1`}
                        onClick={() => this.selectTip(5, "tip5")}
                      >
                        $5
                        <br />
                        (${(5).toFixed(2)})<br />
                        <h1>
                          <i className="fas fa-cloud-sun" />
                        </h1>
                      </button>

                      <button
                        className={`btn btn-lg ${
                          tipAmount === 10
                            ? "btn-warning"
                            : "btn-outline-secondary"
                        } m-1`}
                        onClick={() => this.selectTip(10, "tip10")}
                      >
                        $10
                        <br />
                        (${(10).toFixed(2)})<br />
                        <h1>
                          <i className="fas fa-cloud" />
                        </h1>
                      </button>

                      <button
                        className={`btn btn-lg ${
                          tipAmount === 0
                            ? "btn-warning"
                            : "btn-outline-secondary"
                        } ml-1`}
                        onClick={() => this.selectTip(0, "noTip")}
                      >
                        {" "}
                        <br />
                        No Tip
                        <br />
                        <h1>
                          <i className="fas fa-poo-storm" />
                        </h1>
                      </button>
                    </div>
                  </div>
                </>
              )}
              {paymentMethod === "Credit Card" && (
                <>
                  {androidMessage !== "" && (
                    <div className="row my-2">
                      <div className="col-12 bg-light rounded">
                        {androidMessage} &nbsp;
                      </div>
                    </div>
                  )}
                  {ccErrorMessage && (
                    <div className="row my-2">
                      <div className="col-12 alert alert-danger rounded">
                        {ccErrorMessage} &nbsp;
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                      <button
                        className="btn btn-warning btn-sm my-4"
                        onClick={() => {
                          let customerName;
                          if (!hasCartUnits) {
                            // single unit
                            customerName = lastName + " / multi unit purchase";
                          } else {
                            // multi unit
                            customerName =
                              lastName +
                              " / " +
                              unitType +
                              unitNumber +
                              " " +
                              unitLocation;
                          }

                          this.connectToSQCC(
                            Number.parseFloat(orderTotal + tipAmount).toFixed(
                              2
                            ),
                            customerName
                          );
                        }}
                      >
                        Ready to Swipe CC
                      </button>
                    </div>
                  </div>
                </>
              )}
              {paymentMethod !== "Credit Card" && paymentMethod !== "" && (
                <div className="row">
                  <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                    <button
                      className="btn btn-success btn-lg my-1"
                      onClick={() => this.props.completeOrder()}
                    >
                      Complete Payment
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  itemPriceData: state.units.receiptData.prices,
  pageName: state.units.receiptData.pageName,
});

export default connect(mapStateToProps, { checkItemPrice })(SelectReceiptType);
