import React, {Component} from 'react';
import SelectPaymentMethod from './UnitPurchase/SelectPaymentMethod';
import UnitNav from './UnitPurchase/UnitNav';
import SelectEquipment from './UnitPurchase/SelectEquipment';
import ReviewOrder from './UnitPurchase/ReviewOrder';
import CustomerName from './UnitPurchase/CustomerName';
import SelectHotelCustomerName from './UnitPurchase/SelectHotelCustomerName';
import GetSignature from './UnitPurchase/GetSignature';
import SelectHotel from './UnitPurchase/SelectHotel';
import SelectReceiptType from "./UnitPurchase/SelectReceiptType";
import SelectTip from "./UnitPurchase/SelectTip";

class UnitNewCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: null,
            roomChargeHotel: null,
            nameSelected: false,
            equipmentSelected: false,
            manualEntry: false,
            lastName: '',
            roomNumber: null,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            selectMultipleUnits: false,
            unitStep: '',
            orderTotal: 0.00,
            tipAmount: 0,
            addonTotal: 0.00,
            signature: null,
            readerStatus: '',
            transactionID: '',
            saveOrderReview: '',
            ccErrorMessage: '',
            ccResponse: '',
            ccResponseMessage: '',
            wyndhamExtraCharge: 0,
            wyndhamAdditionalCharge:0,
            receiptType: 'phone'
        };
        this.cancelPaymentMethod = this.cancelPaymentMethod.bind(this);
        this.cancelRoomChargeHotel = this.cancelRoomChargeHotel.bind(this);
        this.cancelHotelCustomerName = this.cancelHotelCustomerName.bind(this);
        this.cancelGuestName = this.cancelGuestName.bind(this);
        this.selectHotel = this.selectHotel.bind(this);
        this.selectName = this.selectName.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveSelectedEquipment = this.saveSelectedEquipment.bind(this);
        this.selectedCheckboxes = new Set();
    }

    componentWillMount() {
        this.setState({
            paymentMethod: null,
            roomChargeHotel: null,
            nameSelected: false,
            equipmentSelected: false,
            manualEntry: false,
            lastName: '',
            roomNumber: null,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            selectMultipleUnits: false,
            unitStep: '',
            orderTotal: 0.00,
            addonTotal: 0.00,
            signature: null,
            readerStatus: '',
            transactionID: '',
            saveOrderReview: '',
            ccErrorMessage: '',
            ccResponse: '',
            ccResponseMessage: '',
            wyndhamExtraCharge: 0,
            wyndhamAdditionalCharge:0
        });
    }

    // FUNCTIONS FOR UNIT NAVIGATION
    cancelPaymentMethod = () => {
        this.props.logAction("Canceled Payment Method");
        window.resetReaderData();
        this.setState({
            paymentMethod: null,
            roomChargeHotel: null,
            nameSelected: false,
            equipmentSelected: false,
            lastName: '',
            roomNumber: null,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            orderTotal: 0.00,
            addonTotal: 0.00,
            wyndhamExtraCharge: 0,

            manualEntry: false,
            selectMultipleUnits: false,
            unitStep: '',
            signature: null,
            readerStatus: '',
            transactionID: '',
            saveOrderReview: '',
            ccErrorMessage: '',
            ccResponse: '',
            ccResponseMessage: '',
            wyndhamAdditionalCharge:0
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
        this.props.updateUnitStep('Select Payment Method');
    };
    cancelRoomChargeHotel = () => {
        this.props.logAction("Canceled Room Charge");
        window.resetReaderData();
        this.setState({
            roomChargeHotel: null,
            nameSelected: false,
            equipmentSelected: false,
            lastName: '',
            roomNumber: null,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            orderTotal: 0.00,
            addonTotal: 0.00,
            wyndhamExtraCharge: 0
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
    };
    cancelHotelCustomerName = () => {
        this.props.logAction("Canceled Hotel Customer Name");
        window.resetReaderData();
        this.setState({
            nameSelected: false,
            equipmentSelected: false,
            lastName: '',
            roomNumber: null,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            orderTotal: 0.00,
            addonTotal: 0.00,
            wyndhamExtraCharge: 0
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
    };
    cancelSelectedEquipment = () => {
        this.props.logAction("Canceled Select Equipment");
        window.resetReaderData();
        this.setState({
            equipmentSelected: false,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            orderTotal: 0.00,
            addonTotal: 0.00
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
    };
    cancelGuestName = () => {
        this.props.logAction("Canceled Guest Name");
        window.resetReaderData();
        this.setState({
            lastName: '',
            nameSelected: false,
            orderReviewed: false,
            orderSignature: false
        });
    };

    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onChangeRadio = (name, value, unitPrice) => {
        if (typeof(unitPrice) !== 'undefined') {
            this.setState({
                [name]: value,
                selectedUnitPrice: unitPrice,
                orderTotal: parseFloat(unitPrice)
            });
        } else {
            this.setState({[name]: value});
        }
    };
    onManualChange = (name, value) => { this.setState({[name]:value})};
    handleCheckboxChange = label => {
        window.resetReaderData();
        let currentDateTime = new Date();
        let currentTime = currentDateTime.getHours();
        let { roomChargeHotel, orderTotal, hotelRoomNumber, roomNumber } = this.state;
        let { equipmentData } = this.props;

        if (this.selectedCheckboxes.has(label)) {
            this.selectedCheckboxes.delete(label);
        } else {
            this.selectedCheckboxes.add(label);
        }

        let addonTotal = 0.00;

        let roomEnding = 0;
        if (hotelRoomNumber !== '' && hotelRoomNumber !== null && typeof(hotelRoomNumber) !== 'undefined') {
            let roomNumberLength = roomNumber.toString().length;
            roomEnding = roomNumber.toString().substring(roomNumberLength - 2, roomNumberLength);
        }

        let equipmentOptions = ',';

        for (const checkbox of this.selectedCheckboxes) {
            equipmentOptions += checkbox + ",";

            if (
                (roomChargeHotel !== "Wyndham") ||
                (roomChargeHotel === "Wyndham" && roomEnding >= 70)
            ) {
                for (const unit of equipmentData.addons) {
                    if (parseInt(unit.startTime) <= currentTime && parseInt(unit.endTime) >= currentTime && unit.unitType === checkbox) {
                        addonTotal += parseFloat(unit.price);
                    }
                }
            } else if (
                (roomChargeHotel === "Wyndham" && roomEnding < 70)
            ) {
                for (const unit of equipmentData.hotelAddons) {
                    if (parseInt(unit.startTime) <= currentTime && parseInt(unit.endTime) >= currentTime && unit.unitType === checkbox) {
                        addonTotal += parseFloat(unit.price);
                    }
                }
            }
        }

        this.setState({ selectedAddons : equipmentOptions, orderTotal : orderTotal, addonTotal: addonTotal});
    };

    // mark unit occupied
    markOccupied = () => {
        this.props.markOccupied();
    };

    // UNIT STEPS
    // SELECT PAYMENT METHOD
    selectPaymentMethod = (paymentMethod) => {
        this.props.logAction("Select Payment Method");
        this.setState({ paymentMethod: paymentMethod });
        let unitStep = '';
        if (paymentMethod === 'Room Charge')
        {
            unitStep = 'Select Hotel';
        }
        else
        {
            unitStep = 'Select Equipment';
        }
        this.props.updateUnitStep(unitStep);
    };

    // IF PAYMENT METHOD === HOTEL
    selectHotel = (roomChargeHotel) => {
        this.props.logAction("Select Hotel");
        this.setState({ roomChargeHotel: roomChargeHotel });
        if (roomChargeHotel !== 'Other') {
            let unitStep = 'Enter Hotel Customer Name';
            this.props.updateUnitStep(unitStep);
        }
    };

    // ENTER CUSTOMER NAME
    selectName = () => {
        this.props.logAction("Enter Customer Name");
        this.setState({ nameSelected: true });
        this.props.updateUnitStep('Review Order');
    };

    // SAVE EQUIPMENT SELECTION
    saveSelectedEquipment = () => {
        this.props.logAction("Select Equipment");
        const { hasCartUnits } = this.props;
        const { paymentMethod } = this.state;

        if (hasCartUnits === false)
        {
            if (paymentMethod !== 'Room Charge') {
                this.props.updateUnitStep('Enter Customer Name');
            } else {
                this.props.updateUnitStep('Review Order');
            }
        }
        else
        {
            this.props.updateUnitStep('Review Order');
        }
        this.setState({ equipmentSelected: true });
    };

    // SAVE GUEST NAME
    saveGuestName = () => {
        this.props.logAction("Select Hotel Guest Name");
        const { paymentMethod } = this.state;
        let unitStep = '';

        if (paymentMethod === 'Room Charge') {
            unitStep = 'Select Equipment';
        } else {
            unitStep = 'Review Order';
        }
        this.props.updateUnitStep(unitStep);
        this.setState({ nameSelected: true });
    };
    selectMultipleUnits = () => {
        this.props.logAction("Select Multi Units");
        const {  unitId, unitType, cartUnits, unitNumber, unitLocation } = this.props;
        let unitInfo = {};
        if (this.props.hasCartUnits === true) {
            let roomNumber = cartUnits[0].roomNumber.toString();
            if (roomNumber.length < 4 && roomNumber !== '') roomNumber = '0' + roomNumber;
            unitInfo = {
                unitId : unitId,
                unitType : unitType,
                unitNumber : unitNumber,
                unitLocation : unitLocation,
                paymentMethod: cartUnits[0].paymentMethod,
                roomChargeHotel: cartUnits[0].roomChargeHotel,
                manualEntry: cartUnits[0].manualEntry,
                lastName: cartUnits[0].lastName,
                roomNumber: roomNumber,
                selectedUnitPrice: this.state.selectedUnitPrice,
                selectedUnit: cartUnits[0].selectedUnit,
                selectedAddons: cartUnits[0].selectedAddons,
                orderReviewed: cartUnits[0].orderReviewed,
                orderSignature: cartUnits[0].orderSignature,
                orderTotal: this.state.orderTotal,
                addonTotal: this.state.addonTotal,
                wyndhamExtraCharge: this.state.wyndhamExtraCharge,
                wyndhamAdditionalCharge: this.state.wyndhamAdditionalCharge
            };
        }
        else
        {
            let roomNumber = 0;
            if (typeof(this.state.roomNumber) !== 'undefined' && this.state.roomNumber !== '' && this.state.roomNumber !== null) {
                roomNumber = this.state.roomNumber.toString();
            }
            if (roomNumber.length < 4 && roomNumber !== '') roomNumber = '0' + roomNumber;

            unitInfo = {
                unitId : unitId,
                unitType : unitType,
                unitNumber : unitNumber,
                unitLocation : unitLocation,
                paymentMethod: this.state.paymentMethod,
                roomChargeHotel: this.state.roomChargeHotel,
                manualEntry: this.state.manualEntry,
                lastName: this.state.lastName,
                roomNumber: roomNumber,
                selectedUnitPrice: this.state.selectedUnitPrice,
                selectedUnit: this.state.selectedUnit,
                selectedAddons: this.state.selectedAddons,
                orderReviewed: this.state.orderReviewed,
                orderSignature: this.state.orderSignature,
                selectMultipleUnits: this.state.selectMultipleUnits,
                unitStep: this.state.unitStep,
                orderTotal: this.state.orderTotal,
                addonTotal: this.state.addonTotal,
                wyndhamExtraCharge: this.state.wyndhamExtraCharge,
                wyndhamAdditionalCharge: this.state.wyndhamAdditionalCharge
            };
        }

        let newCartUnits = {};
        let currentCartUnits = cartUnits;

        let dup = false;
        let hasKey = false;
        Object.keys(currentCartUnits).map((key) => {
            hasKey = true;
            return (null);
        });

        if (currentCartUnits !== '' && typeof(currentCartUnits) !== 'undefined' && hasKey === true)
        {
            let cartUnitsFound = currentCartUnits.filter(filterUnit => filterUnit.unitId === unitId);
            if (cartUnitsFound.length === 1) dup = true;

            if (dup === false) {
                newCartUnits = [...currentCartUnits, unitInfo];
            } else {
                newCartUnits = currentCartUnits;
            }
        }
        else {
            newCartUnits = [unitInfo];
        }
        this.props.saveMultipleUnits(newCartUnits);
        this.setState({ selectMultipleUnits: true });
    };
    unSelectSingleUnit = (unitId) => {
        const { cartUnits } = this.props;
        let newCartUnits = cartUnits.filter(filterUnit => filterUnit.unitId !== unitId);
        if (newCartUnits.length === 0)
        {
            this.props.unSelectMultipleUnits();
        }
        else
        {
            this.props.saveMultipleUnits(newCartUnits);
        }
        this.props.updateUnitStep('');
    };

    saveWyndhamGuestName = (roomNumber, lastName, manualEntry, wyndhamExtraCharge, wyndhamAdditionalCharge) => {
        roomNumber = parseInt(roomNumber);
        this.setState({
            lastName : lastName,
            roomNumber : roomNumber,
            nameSelected : true,
            manualEntry : manualEntry,
            wyndhamExtraCharge : wyndhamExtraCharge,
            wyndhamAdditionalCharge : wyndhamAdditionalCharge
        });
        this.props.updateUnitStep('Select Equipment');
    };
    saveOrderReview = () => {
        const { cartUnits, hasCartUnits } = this.props;
        //let roomChargeHotel = this.state.roomChargeHotel;
        //console.log("saveOrderReview", hasCartUnits, cartUnits);
        if (hasCartUnits === true) {
            this.saveMultiOrderReview(cartUnits);
        } else {
            this.props.updateUnitStep('Select Receipt Type');
            this.setState({ orderReviewed: true });
        }
    };
    saveMultiOrderReview = (cartUnits) => {
        let roomChargeHotel = cartUnits[0].roomChargeHotel;
        let paymentMethod = cartUnits[0].paymentMethod;

        //console.log("saveMultiOrderReview", true, cartUnits);
        let sendReceipt = true
        if (
            (
            paymentMethod === 'Cash' ||
            paymentMethod === 'Credit Card' ||
            paymentMethod === 'Voucher' ||
            (paymentMethod === 'Room Charge' && roomChargeHotel === 'Wyndham') || // REMOVE LATER IF ISSUES
            (paymentMethod === 'Room Charge' && roomChargeHotel === 'Opal Sands') ||
            (paymentMethod === 'Room Charge' && roomChargeHotel === 'Hyatt')
            ) && sendReceipt === false
        ) {
            this.saveMultiOrder('', cartUnits, '');
        } else {
            this.props.updateUnitStep('Select Receipt Type');
            this.props.updateMultipleUnits(cartUnits);
            this.setState({ orderReviewed: true });
        }
    };

    saveReceiptType = () => {
        this.props.updateUnitStep('Select Tip');
        //this.setState({ receiptInfoEntered: true });
    };

    updateTipAmount = (tipAmount, tipType) => {
        this.setState({
            tipAmount,
            tipType
        });
    }

    completeOrder = () => {
        const { cartUnits, hasCartUnits } = this.props;
        const { transactionID } = this.state;
        if (hasCartUnits === true) {
            this.saveMultiOrder('', cartUnits, transactionID);
        } else {
            this.saveOrder('', transactionID);
        }
    }

    saveSignature = (signature, cartUnits) => {
        const { hasCartUnits } = this.props;
        if (hasCartUnits === true) {
            this.saveMultiOrder(signature, cartUnits);
        } else {
            this.saveOrder(signature);
        }
    };
    saveMultiOrder = (signature, cartUnits, transactionID) => {
        const {  receiptType, customerPhone, customerEmail, roomChargeHotel, roomNumber, selectedUnitPrice,
            selectedUnit, selectedAddons, manualEntry, wyndhamExtraCharge, wyndhamAdditionalCharge } = this.state;
        const { ourTransactionId } = this.props;
        const { hasCartUnits, equipmentData } = this.props;
        if (transactionID === '' || typeof(transactionID) === 'undefined') transactionID = this.state.transactionID;
        let paymentMethod = cartUnits[0].paymentMethod;
        let lastName = cartUnits[0].lastName;

        let notes = '';
        notes += 'Receipt Type : ' + receiptType + "\n";
        if (receiptType === "phone") notes += 'Receipt Phone : ' + customerPhone + "\n";
        if (receiptType === "email") notes += 'Receipt Email : ' + customerEmail + "\n";
        notes += 'Customer Name : ' + lastName + "\n";
        notes += 'Payment Method : ' + paymentMethod + "\n";
        if (paymentMethod === 'Room Charge')
        {
            notes += 'Hotel : ' + roomChargeHotel + "\n";
            notes += 'Room # : ' + roomNumber + "\n";
            notes += 'Manual Entry : ' + manualEntry + "\n";
            notes += 'Additional Wyndham Charge : ' + wyndhamExtraCharge + "\n";
        }

        let orderTotal = 0;
        let newTotal = 0;
        cartUnits.map( (unit, index) => {
            let selectedAddons = '';
            if (unit.selectedAddons !== null && typeof(unit.selectedAddons) !== 'undefined') selectedAddons = unit.selectedAddons.split(",");
            orderTotal = Number.parseFloat(unit.selectedUnitPrice) + orderTotal;
            notes += 'Unit ' + (index + 1) + ' : ' + unit.unitType + unit.unitNumber + ' ' + unit.selectedUnit + "\n";
            notes += 'Unit ' + (index + 1) + ' Price : $' + Number.parseFloat(unit.selectedUnitPrice) + "\n";
            if (selectedAddons !== '' && selectedAddons.length > 1) {
                selectedAddons.map( addon => {
                    if (addon !== '') {
                        equipmentData.addons.map( (addonName, index2) => {
                            if (addonName.unitType === addon) {
                                orderTotal = Number.parseFloat(addonName.price) + orderTotal;
                                notes += 'Addons ' + (index2 + 1) + ' : ' + addonName.name + "\n";
                                notes += 'Addon ' + (index2 + 1) + ' Price : $' + Number.parseFloat(addonName.price) + "\n";
                            }
                            return null;
                        })
                    }
                    return null;
                })
            }
            return null;
        });

        newTotal += parseFloat(orderTotal);
        let subTotal = newTotal;
        let tipAmount = parseFloat(this.state.tipAmount);
        newTotal += tipAmount;

        notes += 'Sub Total : $' + subTotal + "\n";
        notes += 'Tip : $' + tipAmount + "\n";
        notes += 'Total : $' + newTotal + "\n";

        if (paymentMethod === 'Credit Card') {
            notes += 'CC Transaction ID ' + transactionID + "\n";
        }
        notes += 'Our Transaction ID ' + ourTransactionId + "\n";

        this.props.saveCustomer(
            paymentMethod,
            roomChargeHotel,
            lastName,
            roomNumber,
            selectedUnitPrice,
            selectedUnit,
            selectedAddons,
            orderTotal,
            signature,
            '',
            transactionID,
            manualEntry,
            wyndhamExtraCharge,
            wyndhamAdditionalCharge,
            notes,
            ourTransactionId,
            cartUnits,
            hasCartUnits,
            receiptType,
            customerPhone,
            customerEmail,
            tipAmount
        );

        this.setState({
            paymentMethod: null,
            roomChargeHotel: null,
            nameSelected: false,
            equipmentSelected: false,
            manualEntry: false,
            lastName: '',
            roomNumber: null,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            selectMultipleUnits: false,
            orderTotal: 0.00,
            addonTotal: 0.00,
            readerStatus: '',
            signature: null,
            transactionID: transactionID,
            wyndhamExtraCharge: 0,
            wyndhamAdditionalCharge: 0,
            hasCartUnits: false,
            cartUnits: {},

            unitStep: '',
            saveOrderReview: '',
            ccErrorMessage: '',
            ccResponse: '',
            ccResponseMessage: '',
            receiptType:'phone',
            customerPhone:'',
            customerEmail:''
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }

        window.resetReaderData();
        this.props.updateUnitStep('');
        this.props.unSelectMultipleUnits();
    };
    saveOrder = (signature, transactionID) => {
        const { receiptType, customerPhone, customerEmail, roomChargeHotel, roomNumber, selectedUnitPrice, selectedUnit,
            selectedAddons, orderTotal, addonTotal, manualEntry, wyndhamExtraCharge, wyndhamAdditionalCharge
        } = this.state;

        const { ourTransactionId } = this.props;
        let paymentMethod = this.state.paymentMethod;
        let lastName = this.state.lastName;
        let newTotal = 0;
        newTotal += parseFloat(selectedUnitPrice);
        newTotal += parseFloat(addonTotal);
        let subTotal = newTotal;
        let tipAmount = parseFloat(this.state.tipAmount);
        newTotal += tipAmount;

        let notes = '';
        notes += 'Receipt Type : ' + receiptType + "\n";
        if (receiptType === "phone") notes += 'Receipt Phone : ' + customerPhone + "\n";
        if (receiptType === "email") notes += 'Receipt Email : ' + customerEmail + "\n";
        notes += 'Customer Name : ' + lastName + "\n";
        notes += 'Payment Method : ' + paymentMethod + "\n";
        if (paymentMethod === 'Room Charge')
        {
            notes += 'Hotel : ' + roomChargeHotel + "\n";
            notes += 'Room # : ' + roomNumber + "\n";
            notes += 'Manual Entry : ' + manualEntry + "\n";
            notes += 'Additional Wyndham Charge : ' + wyndhamExtraCharge + "\n";
        }
        notes += 'Unit : ' + selectedUnit + "\n";
        notes += 'Unit Price : $' + selectedUnitPrice + "\n";
        notes += 'Addons : ' + selectedAddons + "\n";
        notes += 'Addon Price : $' + addonTotal + "\n";

        notes += 'Sub Total : $' + subTotal + "\n";
        notes += 'Tip : $' + tipAmount + "\n";
        notes += 'Total : $' + newTotal + "\n";
        if (paymentMethod === 'Credit Card') {
            notes += 'CC Transaction ID ' + transactionID + "\n";
        }
        notes += 'Our Transaction ID ' + ourTransactionId + "\n";

        // console.log("saveOrder", newTotal, this.state, this.props);

        this.props.saveCustomer(
            paymentMethod,
            roomChargeHotel,
            lastName,
            roomNumber,
            selectedUnitPrice,
            selectedUnit,
            selectedAddons,
            orderTotal,
            signature,
            addonTotal,
            transactionID,
            manualEntry,
            wyndhamExtraCharge,
            wyndhamAdditionalCharge,
            notes,
            ourTransactionId,
            {},
            false,
            receiptType,
            customerPhone,
            customerEmail,
            tipAmount
        );
        this.setState({
            paymentMethod: null,
            roomChargeHotel: null,
            nameSelected: false,
            equipmentSelected: false,
            manualEntry: false,
            lastName: '',
            roomNumber: null,
            selectedUnitPrice: null,
            selectedUnit: '',
            selectedAddons: null,
            orderReviewed: false,
            orderSignature: false,
            selectMultipleUnits: false,
            tipAmount: 0,
            orderTotal: 0.00,
            addonTotal: 0.00,
            readerStatus: '',
            signature: null,
            transactionID: transactionID,
            wyndhamExtraCharge: 0,
            wyndhamAdditionalCharge: 0,

            unitStep: '',
            saveOrderReview: '',
            ccErrorMessage: '',
            ccResponse: '',
            ccResponseMessage: '',
            receiptType:'phone',
            customerPhone:'',
            customerEmail:''
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }

        window.resetReaderData();
        this.props.updateUnitStep('');
        this.props.unSelectMultipleUnits();
    };

    render() {
        const { customerPhone, customerEmail, receiptType, ccErrorMessage, paymentMethod, roomChargeHotel, lastName, roomNumber, tipType, nameSelected, equipmentSelected, selectedUnit, selectedAddons, tipAmount, selectedUnitPrice, orderTotal, addonTotal, wyndhamExtraCharge } = this.state;
        const { unitLocation, equipmentData, hotelResults, onChangeHotel, hotelLastName, hotelRoomNumber, unitId, cartUnits, unitStatus, hasCartUnits, unitType, unitNumber, markOccupiedEmpty, hotels } = this.props;
        let unitStep = this.props.unitStep;


        // if (unitStep === '') unitStep = 'Select Tip';

        // NO STEP SELECTED
        if (unitStep === '')
        {
            if (hasCartUnits === true)
            {
                unitStep = 'Select Equipment';
                cartUnits.map( (unit) => {
                    if (unit.unitId === unitId) {
                        unitStep = 'Review Order';
                    }
                    return null;
                });
            }
            else
            {
                unitStep = 'Select Payment Method';
            }
        }

        // SELECT PAYMENT METHOD
        if (unitStep === 'Select Payment Method')
        {
            return (<div className="card panel-primary">
                <div className="card-header">Select Payment Method</div>
                <div className="card-body">
                    <SelectPaymentMethod
                        logAction={this.props.logAction}
                        unitStatus={unitStatus}
                        markOccupied={this.markOccupied}
                        markOccupiedEmpty={markOccupiedEmpty}
                        selectPaymentMethod={this.selectPaymentMethod}
                    />
                </div>
            </div>);
        }

        // SELECT HOTEL
        else if (unitStep === 'Select Hotel')
        {
            return (<div className="card panel-primary">
                <div className="card-header">Select Hotel</div>
                <div className="card-body">
                    <UnitNav
                        logAction={this.props.logAction}
                        paymentMethod={paymentMethod}
                        roomChargeHotel={roomChargeHotel}
                        nameSelected={nameSelected}
                        lastName={lastName}
                        roomNumber={roomNumber}
                        cartUnits={cartUnits}
                        hasCartUnits={hasCartUnits}
                        equipmentSelected={equipmentSelected}
                        cancelPaymentMethod={this.cancelPaymentMethod}
                        cancelRoomChargeHotel={this.cancelRoomChargeHotel}
                        cancelHotelCustomerName={this.cancelHotelCustomerName}
                        cancelSelectedEquipment={this.cancelSelectedEquipment}
                    />
                    <SelectHotel
                        hotels={hotels}
                        logAction={this.props.logAction}
                        unitLocation={unitLocation}
                        roomChargeHotel={roomChargeHotel}
                        selectHotel={this.selectHotel}
                    />
                </div>
            </div>);
        }

        // ENTER CUSTOMER NAME
        else if (unitStep === 'Enter Customer Name')
        {
            return (<div className="card panel-primary">
                <div className="card-header">Enter Customer Name</div>
                <div className="card-body">
                    <UnitNav
                        logAction={this.props.logAction}
                        paymentMethod={paymentMethod}
                        roomChargeHotel={roomChargeHotel}
                        nameSelected={nameSelected}
                        lastName={lastName}
                        roomNumber={roomNumber}
                        selectedUnit={selectedUnit}
                        cartUnits={cartUnits}
                        hasCartUnits={hasCartUnits}
                        selectedUnitPrice={selectedUnitPrice}
                        equipmentSelected={equipmentSelected}
                        cancelPaymentMethod={this.cancelPaymentMethod}
                        cancelRoomChargeHotel={this.cancelRoomChargeHotel}
                        cancelHotelCustomerName={this.cancelHotelCustomerName}
                        cancelSelectedEquipment={this.cancelSelectedEquipment}
                    />
                    <CustomerName
                        logAction={this.props.logAction}
                        paymentMethod={paymentMethod}
                        roomChargeHotel={roomChargeHotel}
                        nameSelected={nameSelected}
                        lastName={lastName}
                        roomNumber={roomNumber}
                        onChange={this.onChange}
                        saveGuestName={this.saveGuestName}
                    />
                </div>
            </div>);
        }

        // ENTER CUSTOMER NAME
        else if (unitStep === 'Enter Hotel Customer Name')
        {
            return (<div className="card panel-primary">
                <div className="card-header">Enter Customer Name</div>
                <div className="card-body">
                    <UnitNav
                        logAction={this.props.logAction}
                        paymentMethod={paymentMethod}
                        roomChargeHotel={roomChargeHotel}
                        nameSelected={nameSelected}
                        lastName={lastName}
                        roomNumber={roomNumber}
                        cartUnits={cartUnits}
                        hasCartUnits={hasCartUnits}
                        equipmentSelected={equipmentSelected}
                        cancelPaymentMethod={this.cancelPaymentMethod}
                        cancelRoomChargeHotel={this.cancelRoomChargeHotel}
                        cancelHotelCustomerName={this.cancelHotelCustomerName}
                        cancelSelectedEquipment={this.cancelSelectedEquipment}
                    />
                    <SelectHotelCustomerName
                        logAction={this.props.logAction}
                        paymentMethod={paymentMethod}
                        roomChargeHotel={roomChargeHotel}
                        nameSelected={nameSelected}
                        lastName={lastName}
                        roomNumber={roomNumber}
                        hotelLastName={hotelLastName}
                        hotelRoomNumber={hotelRoomNumber}
                        hotelResults={hotelResults}
                        equipmentSelected={equipmentSelected}
                        onChangeHotel={onChangeHotel}
                        onChange={this.onChange}
                        saveWyndhamGuestName={this.saveWyndhamGuestName}
                        saveGuestName={this.saveGuestName}
                        cancelPaymentMethod={this.cancelPaymentMethod}
                        cancelRoomChargeHotel={this.cancelRoomChargeHotel}
                        cancelHotelCustomerName={this.cancelHotelCustomerName}
                        cancelSelectedEquipment={this.cancelSelectedEquipment}
                    />
                </div>
            </div>);
        }

        // SELECT EQUIPMENT
        else if (unitStep === 'Select Equipment')
        {
            return (
                <div className="card panel-primary">
                    <div className="card-header">Equipment</div>
                    <div className="card-body">
                        <UnitNav
                            logAction={this.props.logAction}
                            paymentMethod={paymentMethod}
                            roomChargeHotel={roomChargeHotel}
                            nameSelected={nameSelected}
                            lastName={lastName}
                            roomNumber={roomNumber}
                            equipmentSelected={equipmentSelected}
                            cartUnits={cartUnits}
                            hasCartUnits={hasCartUnits}
                            cancelPaymentMethod={this.cancelPaymentMethod}
                            cancelRoomChargeHotel={this.cancelRoomChargeHotel}
                            cancelHotelCustomerName={this.cancelHotelCustomerName}
                            cancelSelectedEquipment={this.cancelSelectedEquipment}
                        />
                        <SelectEquipment
                            logAction={this.props.logAction}
                            unitLocation={unitLocation}
                            equipmentData={equipmentData}
                            hotelRoomNumber={hotelRoomNumber}
                            roomNumber={roomNumber}
                            roomChargeHotel={roomChargeHotel}
                            wyndhamExtraCharge={wyndhamExtraCharge}
                            onChange={this.onChange}
                            onManualChange={this.onManualChange}
                            onChangeRadio={this.onChangeRadio}
                            selectedUnit={selectedUnit}
                            saveSelectedEquipment={this.saveSelectedEquipment}
                            handleCheckboxChange={this.handleCheckboxChange}
                        />
                    </div>
                </div>
            );
        }

        // REVIEW ORDER
        else if (unitStep === 'Review Order')
        {
            return (
                <div className="card panel-primary">
                    <div className="card-header">Review Order</div>
                    <div className="card-body">
                        <ReviewOrder
                            logAction={this.props.logAction}
                            unitId={unitId}
                            unitType={unitType}
                            unitNumber={unitNumber}
                            unitLocation={unitLocation}

                            paymentMethod={paymentMethod}

                            lastName={lastName}

                            roomNumber={roomNumber}
                            roomChargeHotel={roomChargeHotel}
                            nameSelected={nameSelected}
                            hotelRoomNumber={hotelRoomNumber}

                            equipmentData={equipmentData}
                            equipmentSelected={equipmentSelected}
                            selectedUnit={selectedUnit}
                            orderTotal={orderTotal}
                            addonTotal={addonTotal}
                            selectedUnitPrice={selectedUnitPrice}
                            selectedAddons={selectedAddons}

                            ccErrorMessage={ccErrorMessage}
                            saveOrderReview={this.saveOrderReview}

                            saveMultiOrderReview={this.saveMultiOrderReview}
                            selectMultipleUnits={this.selectMultipleUnits}
                            unSelectSingleUnit={this.unSelectSingleUnit}
                            cartUnits={cartUnits}
                            hasCartUnits={hasCartUnits}

                            cancelPaymentMethod={this.cancelPaymentMethod}
                            cancelRoomChargeHotel={this.cancelRoomChargeHotel}
                            cancelHotelCustomerName={this.cancelHotelCustomerName}
                            cancelSelectedEquipment={this.cancelSelectedEquipment}
                            cancelGuestName={this.cancelGuestName}
                        />
                    </div>
                </div>
            )
        }

        else if (unitStep === 'Select Receipt Type')
        {
            return (<div className="card panel-primary">
                <div className="card-header">Select Receipt Type</div>
                <div className="card-body">
                    <SelectReceiptType
                        customerPhone={customerPhone}
                        customerEmail={customerEmail}
                        receiptType={receiptType}

                        onChange={this.onChange}
                        onChangeRadio={this.onChangeRadio}
                        saveReceiptType={this.saveReceiptType}
                    />
                </div>
            </div>
            );
        }

        // CUSTOMER SIGNATURE
        else if (unitStep === 'Customer Signature')
        {
            return (<GetSignature
                logAction={this.props.logAction}
                unitId={unitId}
                unitType={unitType}
                unitNumber={unitNumber}
                unitLocation={unitLocation}
                paymentMethod={paymentMethod}
                roomNumber={roomNumber}
                roomChargeHotel={roomChargeHotel}
                nameSelected={nameSelected}
                hotelRoomNumber={hotelRoomNumber}
                equipmentSelected={equipmentSelected}
                selectedUnit={selectedUnit}
                addonTotal={addonTotal}
                selectedUnitPrice={selectedUnitPrice}
                selectedAddons={selectedAddons}

                lastName={lastName}
                orderTotal={orderTotal}
                cartUnits={cartUnits}
                hasCartUnits={hasCartUnits}
                equipmentData={equipmentData}
                saveSignature={this.saveSignature}
            />);
        }

        // SELECT TOP
        else if (unitStep === 'Select Tip')
        {
            return (
            <div className="card panel-primary">
                <div className="card-header">Review Order and Select Tip</div>
                <div className="card-body">
                    <SelectTip
                        logAction={this.props.logAction}

                        unitId={unitId}
                        unitType={unitType}
                        unitNumber={unitNumber}
                        unitLocation={unitLocation}

                        paymentMethod={paymentMethod}

                        lastName={lastName}
                        tipType={tipType}

                        tipAmount={tipAmount}
                        roomNumber={roomNumber}
                        roomChargeHotel={roomChargeHotel}
                        nameSelected={nameSelected}
                        hotelRoomNumber={hotelRoomNumber}

                        equipmentData={equipmentData}
                        equipmentSelected={equipmentSelected}
                        selectedUnit={selectedUnit}
                        orderTotal={orderTotal}
                        addonTotal={addonTotal}
                        selectedUnitPrice={selectedUnitPrice}
                        selectedAddons={selectedAddons}

                        cartUnits={cartUnits}
                        hasCartUnits={hasCartUnits}

                        ccErrorMessage={ccErrorMessage}
                        completeOrder={this.completeOrder}
                        updateTipAmount={this.updateTipAmount}
                    />
                </div>
            </div>
            );
        }

        // FINAL REVIEW
        else if (unitStep === 'Final Review Order')
        {
            return (
                <div className="card panel-primary">
                    <div className="card-header">Review Order</div>
                    <div className="card-body">
                        empty
                    </div>
                </div>
            )
        }

        else
        {
            return (<div>loading</div>);
        }

    }
}
export default UnitNewCustomer
