import React, {Component, Fragment}  from 'react';
import queryString from 'query-string';
import Dashboard from "./Dashboard";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: '',
            tmpSearchTerm: ''
        };
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if (this.props.updatedSearchTerm !== '' && typeof(this.props.updatedSearchTerm) !== 'undefined') {
            this.setState({searchTerm: this.props.updatedSearchTerm});
        } else {
            this.setState({searchTerm: values.searchTerm});
        }
    }

    render() {
        const { searchTerm } = this.state;
        return (
            <Fragment>
                { searchTerm &&
                <Dashboard
                    {...this.props}
                    searchTerm={searchTerm}
                />}
            </Fragment>
        )
    }
}

Search.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Search);