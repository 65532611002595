import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { saveLogDetails, getLogDetails } from "../../client/actions/apiActions";
import { Redirect } from "react-router-dom";
import FormInput from "../new-form/FormInput";

class LogDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            cancelAdd: false,
            dataResults: {},
            dataErrors: [],
            fields : [
                {
                    fieldName: 'category',
                    fieldType: 'dropdown',
                    placeholder: 'Select Category',
                    fieldLabel: 'Category',
                    dropdown: [
                        {
                            id: 'Setup',
                            name: 'Setup'
                        },
                        {
                            id: 'Operation',
                            name: 'Operation',
                        },
                        {
                            id:'Tear Down',
                            name: 'Tear Down'
                        }
                    ]
                },
                {
                    fieldName: 'name',
                    fieldType: 'text',
                    placeholder: 'enter a name',
                    fieldLabel: 'Name'
                },
                {
                    fieldName: 'label',
                    fieldType: 'text',
                    placeholder: 'enter a label',
                    fieldLabel: 'Label'
                },
                {
                    fieldName: 'placeholder',
                    fieldType: 'text',
                    placeholder: 'enter a placeholder',
                    fieldLabel: 'Placeholder'
                },
                {
                    fieldName: 'fieldSort',
                    fieldType: 'number',
                    placeholder: '',
                    fieldLabel: 'Sort'
                },
                {
                    fieldName: 'fieldType',
                    fieldType: 'dropdown',
                    placeholder: 'Select Field Type',
                    fieldLabel: 'Field Type',
                    dropdown: [
                        {
                            id: "text",
                            name: 'Text'
                        },
                        {
                            id: "number",
                            name: 'Number',
                        },
                        {
                            id: "textarea",
                            name: 'Textarea'
                        },
                        {
                            id: "date",
                            name: 'Date'
                        },
                        {
                            id: "toggle",
                            name: 'Toggle'
                        },
                        {
                            id: "checkbox",
                            name: 'Checkbox'
                        }
                    ]
                }
            ]
        };
        this.selectedCheckboxes = new Set();
    };
    componentDidMount() {
        let dataResults = this.state.dataResults;
        this.setState({cancelEdit: false});
        let id = this.props.match.params.id;
        if (!id) id = 0;
        dataResults["id"] = id;

        this.setState({
            dataResults: dataResults
        });

        if (id !== 0) {
            try {
                this.props.getLogDetails(id);
            } catch (e) {
                console.log("e", e);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            const { data } = this.props;
            if (data)
            {
                let dataResults = {};
                dataResults["id"] = data.id;
                dataResults["category"] = data.category;
                dataResults["name"] = data.name;
                dataResults["label"] = data.label;
                dataResults["placeholder"] = data.placeholder;
                dataResults["fieldSort"] = data.fieldSort;
                dataResults["fieldType"] = data.fieldType;
                dataResults["cancelEdit"] = false;
                this.setState({
                    dataResults: dataResults
                });
            }

            if (this.props.data.pageType === "saveLogDetail")
            {
                let success = this.props.data.success;
                success = success === 1;
                this.setState({
                    cancelAdd: success
                });
            }
        }
    }
    componentWillUnmount() {
        this.setState({cancelAdd: false, dataResults: {} });
    }

    onChange = (type, e, field) => {
        let dataResults = this.state.dataResults;
        let dataErrors = {};
        if (typeof(dataResults[field.fieldName]) === 'undefined') {
            dataResults[field.fieldName] = [];
        }
        let tmpValue = '';
        if (type === 'text')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'phone')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'email')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'textarea')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) dataResults[field.fieldName] = '1';
            if (e.target.checked === false) dataResults[field.fieldName] = '0';
        }
        else if (type === 'image')
        {
            dataResults[field.fieldName] = e;
        }
        else if (type === 'editor')
        {
            dataResults[field.fieldName] = e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            dataResults[e.target.name] = e.target.value;
        }
        else if (type === 'password')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'checkbox')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'radio')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else
        {
            dataResults[field.fieldName] = e.target.value;
        }

        if (typeof(field) !== 'undefined')
        {
            if (typeof(field.callBackFunction) !== "undefined")
            {
                console.log("callBackFunction", field.callBackFunction);
                this.props[field.callBackFunction](tmpValue);
            }
        }

        this.setState({dataResults: dataResults, dataErrors});
    };
    changeValue = (name, value) => {
        let dataResults = this.state.dataResults;
        dataResults[name] = value;
        this.setState({dataResults: dataResults});
    };
    handleCheckboxChange = (name, label) => {
        let dataResults = this.state.dataResults;

        if (typeof(this.selectedCheckboxes[name]) === 'undefined') this.selectedCheckboxes[name] = new Set();
        if (this.selectedCheckboxes[name].has(label)) {
            this.selectedCheckboxes[name].delete(label);
        } else {
            this.selectedCheckboxes[name].add(label);
        }

        let checkedBoxes = ',';
        for (const checkbox of this.selectedCheckboxes[name]) {
            checkedBoxes += checkbox + ",";
        }
        this.setState({
            dataResults: {...dataResults, [name]: checkedBoxes}
        })
    };
    saveLogDetail = (e) => {
        let dataResults = this.state.dataResults;
        if (typeof(dataResults["id"]) === 'undefined') dataResults["id"] = 0;

        if (typeof(e) !== 'undefined') e.preventDefault();
        let noerror = true;
        let dataErrors = {};
        if (dataResults["category"] === '' || typeof(dataResults["category"]) === 'undefined') {
            noerror = false;
            dataErrors = {...dataErrors, category: 'You left the "Category" blank'};
        }
        if (dataResults["name"] === '' || typeof(dataResults["name"]) === 'undefined') {
            noerror = false;
            dataErrors = {...dataErrors, name: 'You left the "Name" blank'};
        }
        if (dataResults["fieldSort"] === '' || typeof(dataResults["fieldSort"]) === 'undefined') {
            noerror = false;
            dataErrors = {...dataErrors, fieldSort: 'You left the "sort" blank'};
        }
        if (dataResults["fieldType"] === '' || typeof(dataResults["fieldType"]) === 'undefined') {
            noerror = false;
            dataErrors = {...dataErrors, fieldType: 'You left the "Field Type" blank'};
        }



        if (noerror === true) {
            this.props.saveLogDetails( dataResults );
        } else {
            this.setState({dataErrors});
        }
    };
    cancelAdd = () => {
        this.setState({
            errorMessage: '',
            cancelAdd: true,
            errors : {},
            category:'',
            id:'',
            name:'',
            label:'',
            placeholder:'',
            sort:'',
            fieldType:''
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
    };

    render() {
        const {errorMessage, cancelAdd, fields, dataResults, dataErrors} = this.state;
        // console.log("errors", dataErrors, dataResults);
        if (cancelAdd === true) {
            return (<Redirect to="/config/log-details"/> )
        } else {
            return (
                <div className="container">
                    <form name="addLog DetailForm" id="addLog DetailForm" autoComplete="off" onSubmit={this.saveLogDetail}>
                        <div className="row">
                            <div className="col-12"><h1 className="text-center">Add New Log Detail</h1></div>
                            {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}
                            <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelAdd()}>BACK</button></div>
                            <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveLogDetail()}>SAVE</button></div>
                            <div className="col-12"><hr noshade="true" /></div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                {fields && fields.map((field, index) => {
                                    return (<FormInput
                                        id={field.fieldName + "-" + index}
                                        field={field}
                                        type={field.fieldType}
                                        name={field.fieldName}
                                        label={field.fieldLabel}
                                        placeholder={field.placeholder}
                                        colSize={field.colSize}
                                        dropdown={field.dropdown}
                                        error={dataErrors[field.fieldName]}
                                        value={dataResults[field.fieldName]}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />);
                                })}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12"><hr noshade="true" /></div>
                            <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelAdd()}>BACK</button></div>
                            <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveLogDetail()}>SAVE</button></div>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

LogDetail.propTypes = {
    saveLogDetails: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    data: state.pages.data
});

export default connect(mapStateToProps, { saveLogDetails, getLogDetails })(LogDetail);