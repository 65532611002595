import React, {Component, Fragment} from 'react';
import Loading from '../../layout/Loading';

class ReportUserLog extends Component {
    state = {
        userClicked: 0
    }

    render() {
        if (typeof(this.props.reportData) !== 'undefined') {
            const { userList, reportTitle, reportHeaders, reportRows } = this.props.reportData;
            return (<Fragment>
                <h2>{reportTitle}</h2>
                {userList && <Fragment>
                    {userList.map((user,index) => {
                        if (user.id === this.state.userClicked) {
                            return (<Fragment key={index}>
                                <h3>{user.name}</h3>
                                <button className="btn btn-secondary btn-sm mb-1" onClick={()=>this.setState({userClicked:''})}>Close</button>
                                <table className="table table-striped table-sm table-bordered">
                                    {reportHeaders && <thead className="thead-dark">
                                        <tr>
                                            {reportHeaders.map((field,index2) =>
                                                <th key={index2}>{field}</th>
                                            )}
                                        </tr>
                                    </thead>}
                                    {reportRows && <tbody>
                                        {typeof (reportRows[user.name]) !== 'undefined' && user.name !== '' && <Fragment>
                                            {reportRows[user.name].map((row, index2) => <tr key={index2}>
                                                {row.map((data, index3) =>
                                                    <td key={index3}>{data}</td>
                                                )}
                                            </tr>)}
                                        </Fragment>}
                                    </tbody>}
                                </table>
                            </Fragment>);
                        } else {
                            return (<h3 key={index} onClick={()=>this.setState({userClicked:user.id})}>{user.name}</h3>);
                        }
                    })}
                </Fragment>}
            </Fragment>);
        } else {
            return (<Loading />);
        }
    }
}
export default ReportUserLog