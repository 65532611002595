export const GET_UNITS = 'GET_UNITS';
export const GET_UNIT = 'GET_UNIT';
export const GET_DATA = 'GET_DATA';
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const SAVE_UNIT_NUMBER = 'SAVE_UNIT_NUMBER';
export const SEARCH_WYNDHAM = 'SEARCH_WYNDHAM';
export const MARK_EMPTY = 'MARK_EMPTY';
export const RESEAT_CUSTOMER = 'RESEAT_CUSTOMER';
export const GET_PAGE_DROPOFF = 'GET_PAGE_DROPOFF';
export const SAVE_DROPOFF = 'SAVE_DROPOFF';
export const SAVE_CONFIG = 'SAVE_CONFIG';
export const GET_CONFIG = 'GET_CONFIG';

export const SAVE_NOTE = 'SAVE_NOTE';
export const RESEND_RECEIPT = 'RESEND_RECEIPT';
export const UPDATE_RECEIPT = 'UPDATE_RECEIPT';

// PEOPLE
export const GET_PEOPLE_LIST = 'GET_PEOPLE_LIST';
export const GET_PEOPLE = 'GET_PEOPLE';
export const SAVE_PEOPLE = 'SAVE_PEOPLE';
export const SAVE_PEOPLE_SUCCESS = 'SAVE_PEOPLE_SUCCESS';
export const SAVE_PEOPLE_ERROR = 'SAVE_PEOPLE_ERROR';
export const DELETE_PEOPLE_ERROR = 'DELETE_PEOPLE_ERROR';
export const DELETE_PEOPLE = 'DELETE_PEOPLE';

// LOCATION
export const GET_LOCATION_LIST = 'GET_LOCATION_LIST';
export const GET_LOCATION = 'GET_LOCATION';
export const SAVE_LOCATION = 'SAVE_LOCATION';
export const SAVE_LOCATION_SUCCESS = 'SAVE_LOCATION_SUCCESS';
export const SAVE_LOCATION_ERROR = 'SAVE_LOCATION_ERROR';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';
export const DELETE_LOCATION = 'DELETE_LOCATION';

// PRICE
export const GET_PRICE_LIST = 'GET_PRICE_LIST';
export const GET_PRICE = 'GET_PRICE';
export const SAVE_PRICE = 'SAVE_PRICE';
export const SAVE_PRICE_SUCCESS = 'SAVE_PRICE_SUCCESS';
export const SAVE_PRICE_ERROR = 'SAVE_PRICE_ERROR';
export const DELETE_PRICE_ERROR = 'DELETE_PRICE_ERROR';
export const DELETE_PRICE = 'DELETE_PRICE';

// HOTEL
export const GET_HOTEL_LIST = 'GET_HOTEL_LIST';
export const GET_HOTEL = 'GET_HOTEL';
export const SAVE_HOTEL = 'SAVE_HOTEL';
export const SAVE_HOTEL_SUCCESS = 'SAVE_HOTEL_SUCCESS';
export const SAVE_HOTEL_ERROR = 'SAVE_HOTEL_ERROR';
export const DELETE_HOTEL_ERROR = 'DELETE_HOTEL_ERROR';
export const DELETE_HOTEL = 'DELETE_HOTEL';

export const GET_LOGIN_STATUS = 'GET_LOGIN_STATUS';

// MESSAGE
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';

// USER AUTH
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_2FA = 'LOGIN_2FA';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';

export const PRINT_RECEIPT = 'PRINT_RECEIPT';
export const PRINT_NOTES = 'PRINT_NOTES';
export const PRINT_NOTE = 'PRINT_NOTE';
export const PRINT_UNIT_NOTES = 'PRINT_UNIT_NOTES';

export const PROCESS_CC = 'PROCESS_CC';

export const GET_REPORT = 'GET_REPORT';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const LOOKUP_TRANSACTION = 'LOOKUP_TRANSACTION';

export const SAVE_EMAIL_ADDRESS = 'SAVE_EMAIL_ADDRESS';
export const SAVE_EMAIL_ADDRESS_ERROR = 'SAVE_EMAIL_ADDRESS_ERROR';
