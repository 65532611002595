import React, { Component, Fragment } from 'react';
import connect from "react-redux/es/connect/connect";
import { logAction, getUnits, getPrintUnitNote, getUnit, moveCustomer, getResendReceipt, saveUnitNote, saveUnitNumber, saveCustomer, searchWyndham, markEmpty, markOccupiedEmpty, markOccupied, reseatCustomer, getPrintReceipt } from "../../client/actions/apiActions";
import PropTypes from "prop-types";
import Loading from "../layout/Loading";
import Locations from "./DashboardLocations";
import { Tabs, Tab } from 'react-bootstrap';
import UnitNumber from './dashboard/UnitNumber';
import UnitNewCustomer from './dashboard/UnitNewCustomer';
import UnitCustomerList from './dashboard/UnitCustomerList';
import UnitNotes from './dashboard/UnitNotes';
import UnitLog from './dashboard/UnitLog';
import 'core-js/es6/map';
import 'core-js/es6/set';
import { createMessage } from "../../client/actions/messages";
import { withAlert } from 'react-alert';
import DatePicker from 'react-date-picker';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeStamp: null,
            showModal: false,
            tabKey: 'umbrellaNumbers',
            unitNumber: null,
            unitType: null,
            unitRow: null,
            unitId: null,
            unitLocation: null,
            errorStatus: null,
            errorMessage: null,
            unitData: null,
            data: null,
            results: null,
            isLoading: false,
            lastName: null,
            roomNumber: null,
            hotelLastName: null,
            hotelRoomNumber: null,
            printCode: null,
            printTime: 0,
            initialDataLoaded: false,
            scrollY: 0,
            scrollX: 0,
            scrollDiv: '',
            locations: {},
            ourTransactionId:0,
            searchTerm: '',
            currentRunTime: 1000,
            currentDate: new Date(),
            unitStep: '',
            cartUnits: {},
            hasCartUnits: false,
            idToPrintTime: 0,
            hotels: []
        };
        this.onChangeDateClick = this.onChangeDateClick.bind(this);
        this.editUmbrella = this.editUmbrella.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loadUnits = this.loadUnits.bind(this);

        this.intervalHandle = null;
        this.startRoomSearchTimer = this.startRoomSearchTimer.bind(this);
        this.stopRoomSearchTimer = this.stopRoomSearchTimer.bind(this);
    };
    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        const { data, initialDataLoaded } = this.state;
        if (initialDataLoaded === false && typeof(nextProps.data) !== 'undefined')
        {
            this.setState({
                initialDataLoaded: true,
                data: nextProps.data,
                locations: nextProps.data.locations,
                hotels: nextProps.data.hotels
            });
        }
        else if ( data.locations !== nextProps.data.locations )
        {
            this.setState({
                initialDataLoaded: true,
                data: nextProps.data,
                locations: nextProps.data.locations,
                hotels: nextProps.data.hotels
            });
        }
        else if (typeof(nextProps.unitData.locations) != 'undefined' && data.locations !== nextProps.unitData.locations )
        {
            this.setState({
                unitData: nextProps.unitData,
                locations: nextProps.unitData.locations,
                hotels: nextProps.data.hotels,
                ourTransactionId: nextProps.unitData.ourTransactionId
            });
            if (nextProps.unitData.idToPrint !== '' && typeof(nextProps.unitData.idToPrint) !== 'undefined' && nextProps.unitData.loadTime !== this.state.idToPrintTime) {
                this.setState({
                    idToPrintTime: nextProps.unitData.loadTime
                });
                this.printReciept(nextProps.unitData.idToPrint, 0);
            }
        }
        if (typeof(nextProps.unitData.ourTransactionId) !== 'undefined') {
            this.setState({
                ourTransactionId: nextProps.unitData.ourTransactionId
            });
        }
    }
    componentDidMount() {
        if (this.props.searchTerm !== '' && typeof(this.props.searchTerm) !== 'undefined') {
            this.setState({ searchTerm : this.props.searchTerm });
            this.props.getUnits(this.state.timeStamp, this.props.searchTerm);
        } else {
            this.loadUnits();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchTerm !== this.state.searchTerm && typeof(prevProps.searchTerm) !== 'undefined') {
            this.setState({ searchTerm : prevProps.searchTerm });
            this.props.getUnits(this.state.timeStamp, prevProps.searchTerm);
        }
        if (prevProps.searchTerm !== this.props.searchTerm) {
            this.setState({ searchTerm : this.props.searchTerm });
            this.props.getUnits(this.state.timeStamp, this.props.searchTerm);
        }
        const { scrollX, scrollY, scrollDiv } = this.state;
        if ( scrollX !== 0 && document.getElementById(scrollDiv) != null) {
            document.getElementById(scrollDiv).scrollLeft = scrollX;
        }

        if (prevProps !== this.props)
        {
            if (this.props.unitData.CustomerSaveStatus === 1)
            {
                this.closeModal();
                this.unSelectMultipleUnits();
            }
            if (this.props.unitData.CustomerLoaded === 1)
            {
                if (this.props.unitData.SaveUnitNotes === 1)
                {
                    this.setState({
                        showModal: true,
                        tabKey: 'notes'
                    });
                }
                else
                {
                    if (this.props.unitData.status === 5 || this.props.unitData.status === '5' || this.props.unitData.status === 3 || this.props.unitData.status === '3' || this.props.unitData.status === 1 || this.props.unitData.status === '1') {
                        this.setState({
                            showModal: true,
                            tabKey: 'newCustomer'
                        });
                    }
                    else if (this.props.unitData.umbrellaType !== null && this.props.unitData.umbrellaType !== '') {
                        this.setState({
                            showModal: true,
                            tabKey: 'customers'
                        });
                    }
                    else {
                        this.setState({
                            showModal: true,
                            tabKey: 'umbrellaNumbers'
                        });
                    }
                }
            }
            if (this.props.unitData.moveCustomer === true) {
                this.closeModal();
                this.setState({
                    hasMoveUnit: false,
                    moveUnit: {},
                    showModal: false
                });
            }
        }

        window.scrollTo(scrollX, scrollY);
    }
    componentWillUnmount() {
        this.setState({
            showModal: false,
            tabKey: 'umbrellaNumbers',
            unitNumber: null,
            unitType: null,
            unitRow: null,
            unitId: null,
            unitLocation: null,
            errorStatus: null,
            errorMessage: null,
            lastName: null,
            roomNumber: null,
            hotelLastName: null,
            hotelRoomNumber: null,
            ccResponse: null,
            ourTransactionId: '',
            unitStep: '',
            cartUnits: {},
            hasCartUnits: false
        });
    }

    startRoomSearchTimer = () => {
        clearTimeout(this.intervalHandle);
        let time=this.state.currentRunTime;
        this.intervalHandle = setTimeout(this.onChangeHotelDoSearch, time);
    };
    stopRoomSearchTimer = () => {
        clearTimeout(this.intervalHandle);
    };
    onChangeHotel = (e) => {
        this.setState({[e.target.name]: e.target.value});
        this.startRoomSearchTimer();
    };
    onChangeHotelDoSearch = () => {
        const { hotelRoomNumber, hotelLastName } = this.state;
        const searchHotelRooms = {hotelLastName, hotelRoomNumber};
        this.stopRoomSearchTimer();
        this.props.searchWyndham(searchHotelRooms);
    };

    loadUnits = () => {
        this.props.getUnits(this.state.timeStamp, this.state.searchTerm);
    };
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onManualChange = (name, value) => { this.setState({[name]:value})};
    onChangeDateClick = (dateStamp) => {
        this.props.getUnits(dateStamp, this.state.searchTerm);
    };

    onChangeRadio = (name, value, unitPrice) => {
        this.setState({
            [name]: value
        });
    };
    getScrollPos = ( clickedDiv ) => {
        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (typeof(clickedDiv) !== 'undefined')
        {
            scrollLeft = document.getElementById(clickedDiv).scrollLeft;
        } else {
            clickedDiv = '';
        }
        this.setState({ scrollY: scrollTop, scrollX: scrollLeft, scrollDiv: clickedDiv });
    };

    logAction = (logText) => {
        const { unitId } = this.state;
        this.props.logAction(unitId, logText);
    };
    editUmbrella = (unitId, unitType, unitNumber, unitStatus, unitLocation) => {
        this.props.getUnit(unitId, this.state.data.currentTime);
        if (unitType ==='T' || unitType === 'P')
        {
            this.setState({
                showModal: true,
                unitNumber: unitNumber,
                unitType: unitType,
                unitId: unitId,
                unitStatus: unitStatus,
                unitLocation: unitLocation,
                errorStatus: null,
                errorMessage: null,
                tabKey: 'umbrellaNumbers'
            });
        }
        else
        {
            if (unitStatus === 3 || unitStatus === 1 || unitStatus === 5) {
                this.setState({
                    unitNumber: unitNumber,
                    unitType: unitType,
                    unitId: unitId,
                    unitStatus: unitStatus,
                    unitLocation: unitLocation,
                    errorStatus: null,
                    errorMessage: null
                });
            }
            else if (unitType !== null && unitType !== '') {
                this.setState({
                    unitNumber: unitNumber,
                    unitType: unitType,
                    unitId: unitId,
                    unitStatus: unitStatus,
                    unitLocation: unitLocation,
                    errorStatus: null,
                    errorMessage: null,
                });
            }
            else {
                this.setState({
                    unitNumber: unitNumber,
                    unitType: unitType,
                    unitId: unitId,
                    unitStatus: unitStatus,
                    unitLocation: unitLocation,
                    errorStatus: null,
                    errorMessage: null
                });
            }
        }
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            tabKey: 'umbrellaNumbers',
            unitNumber: null,
            unitType: null,
            unitRow: null,
            unitId: null,
            unitLocation: null,
            errorStatus: null,
            errorMessage: null,
            lastName: null,
            roomNumber: null,
            hotelLastName: null,
            hotelRoomNumber: null,
            ccResponse: null,
            ourTransactionId: '',
            unitStep: '',
            cartUnits: {},
            hasCartUnits: false
        });
    };
    saveUnitNotes = (customNotes) => {
        const { unitId } = this.state;
        this.props.saveUnitNote(unitId, customNotes);
        //this.closeModal();
        //this.unSelectMultipleUnits();
    };
    saveUmbrellaNumber = (e) => {
        e.preventDefault();
        let errorStatus=null;
        let errorMessage='';
        const { unitNumber, unitType, unitId, timeStamp } = this.state;
        if (unitNumber === '0' || unitNumber === '' || unitNumber === null) {
            errorStatus=true;
            errorMessage += 'You did not enter a unit number';
        }
        if (unitType === '' || unitType === null) {
            errorStatus=true;
            errorMessage += 'You did not select a unit type';
        }
        if (errorStatus === true) {
            this.setState({ errorStatus, errorMessage });
        } else {
            const updUnitInformation = {unitNumber, unitType, unitId, timeStamp};
            this.props.saveUnitNumber(updUnitInformation);
            this.closeModal();
        }
        this.unSelectMultipleUnits();
    };
    saveCustomer = (
        paymentMethod,
        roomChargeHotel,
        lastName,
        roomNumber,
        selectedUnitPrice,
        selectedUnit,
        selectedAddons,
        orderTotal,
        signature,
        addonTotal,
        transactionID,
        manualEntry,
        wyndhamExtraCharge,
        wyndhamAdditionalCharge,
        notes,
        ourTransactionId,
        cartUnits,
        hasCartUnits,
        receiptType,
        customerPhone,
        customerEmail,
        tipAmount
    ) => {
        const { unitType, unitId } = this.state;
        const { unitData } = this.props;
        const customerData = {
            unitType,
            unitId,
            unitData,
            paymentMethod,
            roomChargeHotel,
            lastName,
            roomNumber,
            selectedUnitPrice,
            selectedUnit,
            selectedAddons,
            orderTotal,
            signature,
            addonTotal,
            transactionID,
            manualEntry,
            wyndhamExtraCharge,
            notes,
            ourTransactionId,
            cartUnits,
            hasCartUnits,
            receiptType,
            customerPhone,
            customerEmail,
            tipAmount
        };
        this.props.saveCustomer( customerData );
    };
    markOccupied = () => {
        const { unitId } = this.state;
        const unitData = { unitId };

        this.props.markOccupied(unitData);
        this.closeModal();
        this.unSelectMultipleUnits();
    };
    markOccupiedEmpty = () => {
        const { unitId } = this.state;
        const unitData = { unitId };

        this.props.markOccupiedEmpty(unitData);
        this.closeModal();
        this.unSelectMultipleUnits();
    };

    reseatCustomer = (customerId, unitId) => {
        const unitData = { customerId, unitId };
        this.props.reseatCustomer(unitData);
        this.closeModal();
        this.unSelectMultipleUnits();
    };
    markUnitEmpty = (unitId) => {
        const unitData = { unitId };
        this.props.markEmpty(unitData);
        this.closeModal();
        this.unSelectMultipleUnits();
    };
    printReciept = (unitId, reprint) => {
        this.props.getPrintReceipt(unitId, reprint);
    };
    editReciept = (unitId, reprint) => {
        //this.props.getResendReceipt(unitId, reprint);
    };
    saveEditReciept = (unitId, reprint) => {
        //this.props.getResendReceipt(unitId, reprint);
    };

    printNote = (noteId) => {
        this.props.getPrintUnitNote(noteId);
    };

    saveMultipleUnits = (cartUnits) => {
        this.setState({cartUnits: cartUnits, showModal: false, hasCartUnits: true, unitStep: ''});
    };
    unSelectMultipleUnits = () => {
        this.setState({cartUnits: {}, showModal: false, hasCartUnits: false, unitStep: ''});
    };
    updateUnitStep = (step) => {
        this.setState({unitStep: step});
    };
    updateMultipleUnits = (cartUnits) => {
        this.setState({cartUnits: cartUnits});
    };
    onChangeDate = (date) => {
        let currentDate = new Date(date);
        let dateStamp = Math.round(currentDate.getTime() / 1000);
        this.props.getUnits(dateStamp, this.state.searchTerm);
    };




    moveCustomer = (customerId, unitDBId) => {
        const { unitId } = this.state;
        this.setState({
            hasMoveUnit: true,
            moveUnit: {
                unitId: unitId,
                unitDBId: unitDBId,
                customerId: customerId
            },
            showModal: false
        })
    }
    confirmMove = (unitData) => {
        this.props.moveCustomer(unitData, this.state.moveUnit);
    }
    cancelMove = () => {
        this.setState({
            hasMoveUnit: false,
            moveUnit: {},
            showModal: false
        });
    }

    render() {
        const { cartUnits, hotels, unitStep, hasCartUnits, searchTerm, unitStatus, hotelLastName, hotelRoomNumber, data, ourTransactionId, showModal, unitNumber, unitType, unitId, unitLocation, errorStatus, errorMessage, locations, moveUnit, hasMoveUnit} = this.state;
        let unitData = this.props.unitData;
        const { hotelResults, androidMessage, auth } = this.props;

        if (data !== null)
        {
            if (data.locations !== '' && typeof (data.locations) !== 'undefined' && data.locations !== null)
            {
                if (showModal === true)
                {
                    return (
                        <div className="container mb-4">
                            <div className="row">
                                {unitData &&
                                <div className="col-10">
                                    <h5 className="mt-3">
                                        Loc: {unitData.umbrellaLocation} -
                                        Row: {unitData.umbrellaRow} -
                                        Pos: {unitData.umbrellaPosition}
                                        {unitData.umbrellaNumber !== 0 && unitData.umbrellaNumber !== '0' && <> - #: {unitData.umbrellaNumber}</>}
                                        &nbsp; {' '}
                                        Available
                                    </h5>
                                </div>}
                                <div className="col-2 my-2 text-right">
                                    <button
                                        className="btn btn-danger btn-lg oswald mr-3"
                                        onClick={() => this.closeModal()}
                                    ><i className="fas fa-times"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {unitNumber !== '' && unitNumber !== null && typeof(unitNumber) !=='undefined' && <>

                                        {typeof(moveUnit) !== 'undefined' && typeof(moveUnit.unitId) !== 'undefined' && moveUnit.unitId !== unitId && <Tabs id="moveCustomerTabs" activeKey="moveCustomer" onSelect={tabKey => this.setState({tabKey})}>
                                            <Tab eventKey="moveCustomer" title="Move Customer">

                                                <div className="card card-primary">
                                                    <div className="card-header">Move Customer</div>
                                                    <div className="card-body">

                                                        <div className="container p-4 border-1">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    Move Customer to this unit?<br /><br />
                                                                    <button className="btn btn-success" onClick={() => this.confirmMove(unitData)}>Confirm Move</button><br /><br />

                                                                    <button className="btn btn-warning" onClick={() => this.cancelMove()}>Cancel Move</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </Tab>
                                        </Tabs>}

                                        {typeof(moveUnit) !== 'undefined' && typeof(moveUnit.unitId) !== 'undefined' && moveUnit.unitId === unitId && <Tabs id="moveCustomerTabs" activeKey="moveCustomer" onSelect={tabKey => this.setState({tabKey})}>
                                            <Tab eventKey="moveCustomer" title="Move Customer">
                                                <div className="card card-primary">
                                                    <div className="card-header">Move Customer</div>
                                                    <div className="card-body">

                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    Cancel moving this customer?<br /><br />
                                                                    <button className="btn btn-warning" onClick={() => this.cancelMove()}>Cancel Move</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>}

                                        { (typeof(moveUnit) === 'undefined' || (typeof(moveUnit) !== 'undefined' && typeof(moveUnit.unitId) === 'undefined')) && <Tabs id="editUmbrellaTabs" activeKey={this.state.tabKey} onSelect={tabKey => this.setState({tabKey})}>
                                            <Tab eventKey="umbrellaNumbers" title="Number">
                                                <UnitNumber
                                                    logAction={this.logAction}
                                                    hotels={hotels}
                                                    unitId={unitId}
                                                    unitType={unitType}
                                                    unitNumber={unitNumber}
                                                    errorStatus={errorStatus}
                                                    errorMessage={errorMessage}
                                                    onChange={this.onChange}
                                                    onChangeRadio={this.onChangeRadio}
                                                    customers={unitData.customers}
                                                    saveUmbrellaNumber={this.saveUmbrellaNumber}
                                                />
                                            </Tab>
                                            {unitType !== 'T' && unitType !== 'P' && <Tab eventKey="newCustomer" title="New Customer">
                                                <UnitNewCustomer
                                                    ourTransactionId={ourTransactionId}
                                                    logAction={this.logAction}
                                                    hotels={hotels}

                                                    unitId={unitId}
                                                    unitType={unitType}
                                                    unitNumber={unitNumber}
                                                    unitLocation={unitLocation}

                                                    unitStatus={unitStatus}
                                                    cartUnits={cartUnits}
                                                    hasCartUnits={hasCartUnits}
                                                    saveMultipleUnits={this.saveMultipleUnits}
                                                    unitStep={unitStep}
                                                    updateUnitStep={this.updateUnitStep}

                                                    hotelLastName={hotelLastName}
                                                    hotelRoomNumber={hotelRoomNumber}
                                                    hotelResults={hotelResults}
                                                    errorStatus={errorStatus}
                                                    errorMessage={errorMessage}
                                                    androidMessage={androidMessage}
                                                    equipmentData={unitData.equipmentData}
                                                    markOccupied={this.markOccupied}
                                                    markOccupiedEmpty={this.markOccupiedEmpty}
                                                    onChange={this.onChange}
                                                    onManualChange={this.onManualChange}
                                                    onChangeHotel={this.onChangeHotel}
                                                    saveCustomer={this.saveCustomer}
                                                    unSelectMultipleUnits={this.unSelectMultipleUnits}
                                                    updateMultipleUnits={this.updateMultipleUnits}
                                                    ref={(EditUmbrellaNewCustomer) => {window.EditUmbrellaNewCustomer = EditUmbrellaNewCustomer}}
                                                />
                                            </Tab>}
                                            {unitType !== 'T' && unitType !== 'P' && <Tab eventKey="customers" title="Customers">
                                                <UnitCustomerList
                                                    hotels={hotels}
                                                    logAction={this.logAction}
                                                    onChange={this.onChange}
                                                    onChangeRadio={this.onChangeRadio}
                                                    unitId={unitData.id}
                                                    customers={unitData.customers}
                                                    reseatCustomer={this.reseatCustomer}
                                                    printReciept={this.printReciept}
                                                    resendReciept={this.resendReciept}
                                                    editReciept={this.editReciept}
                                                    markUnitEmpty={this.markUnitEmpty}
                                                    moveCustomer={this.moveCustomer}
                                                />
                                            </Tab>}
                                            {unitType !== 'T' && unitType !== 'P' && <Tab eventKey="unitLog" title="Log">
                                                <UnitLog
                                                    logAction={this.logAction}

                                                    unitData={unitData}
                                                    saveUnitNotes={this.saveUnitNotes}
                                                    printNote={this.printNote}
                                                />
                                            </Tab>}
                                            {unitType !== 'T' && unitType !== 'P' && <Tab eventKey="notes" title="Notes">
                                                <UnitNotes
                                                    logAction={this.logAction}

                                                    unitData={unitData}
                                                    saveUnitNotes={this.saveUnitNotes}
                                                    printNote={this.printNote}
                                                />
                                            </Tab>}
                                        </Tabs>}

                                    </>}

                                    {(unitNumber === '' || unitNumber === null || unitNumber === 'null' || typeof(unitNumber) === 'undefined') && <UnitNumber
                                        hotels={hotels}
                                        unitId={unitId}
                                        unitType={unitType}
                                        unitNumber={unitNumber}
                                        errorStatus={errorStatus}
                                        errorMessage={errorMessage}
                                        onChange={this.onChange}
                                        onChangeRadio={this.onChangeRadio}
                                        customers={unitData.customers}
                                        saveUmbrellaNumber={this.saveUmbrellaNumber}
                                    />}

                                </div>
                            </div>
                        </div>
                    )
                }
                else
                {
                    let currentDate = new Date(data.currentDay);
                    return (<div id="Dashboard" className="container" style={{backgroundColor: '#d8c09e', maxWidth: '100%'}}>
                        {auth.user.user.userType === 'A' && <div className="row">
                            <div className="col-12 my-2">
                                <button
                                    className="btn btn-warning btn-sm mr-3"
                                    onClick={() => { this.getScrollPos(); this.onChangeDateClick(data.startTime) }}
                                >{data.startDate}</button>

                                <DatePicker
                                    className="mr-3"
                                    onChange={this.onChangeDate}
                                    value={currentDate}
                                />

                                {(data.endDOY - auth.user.user.DOY) <= 0 && <button
                                    className="btn btn-warning btn-sm mr-3"
                                    onClick={() => { this.getScrollPos(); this.onChangeDateClick(data.endTime) }}
                                >{data.endDate}</button>}

                                { hasCartUnits === true && <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => { this.unSelectMultipleUnits() }}
                                >Cancel Order</button>}
                            </div>
                        </div>}

                        {auth.user.user.userType === 'S' &&  <div className="row">
                            <div className="col-12 my-2">
                                {(((auth.user.user.DOY - data.startDOY) <= 1) || data.dom === "1") && <button
                                    className="btn btn-warning btn-sm mr-3"
                                    onClick={() => { this.getScrollPos(); this.onChangeDateClick(data.startTime) }}
                                >{data.startDate}</button>}

                                {((data.endDOY - auth.user.user.DOY) <= 0 || data.dom === "1") && <button
                                    className="btn btn-warning btn-sm mr-3"
                                    onClick={() => { this.getScrollPos(); this.onChangeDateClick(data.endTime) }}
                                >{data.endDate}</button>}

                                { hasCartUnits === true && <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => { this.unSelectMultipleUnits() }}
                                >Cancel Order</button>}
                            </div>
                        </div>}
                        {auth.user.user.userType !== 'A' && auth.user.user.userType !== 'S' && hasCartUnits === true && <div className="row">
                            <div className="col-12 my-2">
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => { this.unSelectMultipleUnits() }}
                                >Cancel Order</button>
                            </div>
                        </div>}
                        {searchTerm && <div className="col-12 my-2 text-center">
                            <h2>Searching for : {searchTerm}</h2>
                            <h4>{data.totalUnits} results found</h4>
                        </div>}

                        {locations.map((location, index) => {
                            return (<Locations
                                hotels={hotels}
                                location={location}
                                totalUnits={data.totalLocationUnits[location.locationId]}
                                key={index}
                                searchTerm={searchTerm}
                                cartUnits={cartUnits}
                                hasCartUnits={hasCartUnits}

                                moveUnit={moveUnit}
                                hasMoveUnit={hasMoveUnit}

                                editUmbrella={this.editUmbrella}
                                getScrollPos={this.getScrollPos}
                                dom={data.dom}
                                auth={auth.user.user}
                                currentDOY={auth.user.user.DOY}
                                endDOY={data.endDOY}
                                startDOY={data.startDOY}

                            />);
                        })}
                    </div>);
                }
            }
            else if (data.noLocations === 1) {
                return (<div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="text-center my-5">No Locations have been assigned to you!</h2>
                        </div>
                    </div>
                </div>)
            }
            else {
                return (<Loading/>)
            }
        }
        else
        {
            return (<Loading/>)
        }
    }
}

Dashboard.propTypes = {
    data: PropTypes.object.isRequired,
    unitData: PropTypes.object.isRequired,
    hotelResults: PropTypes.array.isRequired,
    saveUnitNumber: PropTypes.func.isRequired,
    saveCustomer: PropTypes.func.isRequired,
    reseatCustomer: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    markEmpty: PropTypes.func.isRequired,
    getPrintReceipt: PropTypes.func.isRequired,
    searchWyndham: PropTypes.func.isRequired,
    getUnits: PropTypes.func.isRequired,
    getUnit: PropTypes.func.isRequired,
    saveUnitNote: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.units.data,
    unitData: state.units.unitData,
    // printCode: state.pages.printCode,
    ccResponse: state.pages.ccResponse,
    results: state.updates.results,
    hotelResults: state.updates.hotelResults
});

export default connect(
    mapStateToProps,
    { logAction, getUnits, getPrintUnitNote, getUnit, getResendReceipt, moveCustomer, saveUnitNumber, saveCustomer, searchWyndham, markEmpty, markOccupiedEmpty, markOccupied, reseatCustomer, getPrintReceipt, createMessage, saveUnitNote }
)(withAlert(Dashboard));
