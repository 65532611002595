import React, {Component} from 'react';

class itemRadio extends Component {
    render() {
        const { label, name, radioId, value, unitPrice, selectedValue, placeholder, onChangeRadio } = this.props;
        return (
            <div className="form-group my-1">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <input
                                type="radio"
                                name={name}
                                onChange={()=>onChangeRadio(name, value, unitPrice)}
                                id={radioId}
                                defaultChecked={value === selectedValue ? 'checked' : null }
                                placeholder={placeholder}
                                value={value}
                            />
                        </div>
                    </div>
                    <label htmlFor={radioId} className="form-control">{label} {unitPrice !== '0.00' && typeof(unitPrice) !== 'undefined' && ("$" + unitPrice)}</label>
                </div>
            </div>
        )
    }
}

export default itemRadio