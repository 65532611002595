import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { saveHotel } from "../../client/actions/apiActions";
import {Redirect} from "react-router-dom";
import ItemTextArea from "../form/itemTextArea"

class Hotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            cancelEdit: false,
            name:'', contactName:'', contactEmail:'', roomRange:'',
            errors : {}
        };
    };
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onChangeRadio = (name, value) => {
        this.setState({
            [name]: value
        });
    };
    saveHotel = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        let noerror = true;
        let id = 0;
        let errors = {};
        const { name, contactName, contactEmail, roomRange } = this.state;

        if (name === '') {
            noerror = false;
            errors = {...errors, name: 'You left the hotel "Name" blank'};
        }
        if (contactName === '') {
            noerror = false;
            errors = {...errors, contactName: 'You left the hotel "Contact Name" blank'};
        }
        if (contactEmail === '') {
            noerror = false;
            errors = {...errors, contactEmail: 'You left the hotel "Contact Email" blank'};
        }

        if (noerror === true) {
            let hotelData = { id, name, contactName, contactEmail, roomRange };
            this.props.saveHotel( hotelData );
            this.props.history.push('/config/hotels');
        } else {
            this.setState({errors});
        }
    };

    cancelEdit = () => {
        this.setState({
            errorMessage: '',
            cancelEdit: true,
            errors : {},
            id:'', name:'', contactName:'', contactEmail:'', roomRange:''
        });
    };

    render() {
        const {
            errorMessage,
            errors,
            cancelEdit,
            name, contactName, contactEmail, roomRange
        } = this.state;

        if (cancelEdit === true) {
            return (<Redirect to="/config/hotels"/> )
        } else {
            return (
                <div className="container">
                    <form name="addHotelForm" id="addHotelForm" autoComplete="off" onSubmit={this.saveHotel}>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="text-center">Add New Hotel</h1>
                            </div>
                            {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}

                            <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                            <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveHotel()}>SAVE HOTEL</button></div>
                            <div className="col-12"><hr noshade="true" /></div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Hotel Name</label>
                            <div className="col-sm-8"><input type="text" className="form-control" id="name" name="name" onChange={this.onChange} placeholder="enter Name ..." value={name} /></div>
                            {errors.name && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors.name}</div></div>}
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Contact Name</label>
                            <div className="col-sm-8"><input type="text" className="form-control" id="contactName" name="contactName" onChange={this.onChange} placeholder="enter contact Name ..." value={contactName} /></div>
                            {errors.contactName && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors.contactName}</div></div>}
                        </div>

                        <ItemTextArea
                            errors={errors.contactEmail}
                            label="Contact Emails"
                            id="contactEmail"
                            value={contactEmail}
                            placeholder="enter contact emails .."
                            onChange={this.onChange}
                        />

                        <ItemTextArea
                            errors={errors.roomRange}
                            label="Room Range"
                            id="roomRange"
                            value={roomRange}
                            placeholder="enter room Range .."
                            onChange={this.onChange}
                        />

                        <div className="row">
                            <div className="col-12"><hr noshade="true" /></div>
                            <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                            <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.saveHotel()}>SAVE HOTEL</button></div>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

Hotel.propTypes = {
    saveHotel: PropTypes.func.isRequired,
    saveData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    saveData: state.pages.saveData
});

export default connect(mapStateToProps, { saveHotel })(Hotel);