import React, {Component} from 'react';
import SignatureCanvas from 'react-signature-canvas'

class GetSignature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderTotal: 0,
            lastName: '',
            cartUnits: {}
        };
    }

    componentDidMount() {
        const { equipmentData, hasCartUnits, cartUnits } = this.props;
        let orderTotal = this.props.orderTotal;
        let lastName = this.props.lastName;
        let newCartUnits = {};
        if (hasCartUnits === true)
        {
            let thisUnit = false;
            cartUnits.map( (unit) => {
                if (unit.unitId === this.props.unitId) thisUnit = true;
                return (null);
            });

            if (thisUnit === true)
            {
                newCartUnits = cartUnits;
            }
            else
            {
                lastName = cartUnits[0].lastName;
                let unitInfo = {
                    unitId : this.props.unitId,
                    unitType : this.props.unitType,
                    unitNumber : this.props.unitNumber,
                    unitLocation : this.props.unitLocation,
                    thisUnit: thisUnit,

                    roomChargeHotel: cartUnits[0].roomChargeHotel,
                    roomNumber: cartUnits[0].roomNumber,
                    lastName: cartUnits[0].lastName,
                    manualEntry: cartUnits[0].manualEntry,

                    selectedUnitPrice: this.props.selectedUnitPrice,
                    selectedUnit: this.props.selectedUnit,
                    selectedAddons: this.props.selectedAddons,

                    orderTotal: this.props.orderTotal,
                    addonTotal: this.props.addonTotal,

                    wyndhamExtraCharge: this.props.wyndhamExtraCharge,
                    wyndhamAdditionalCharge: this.props.wyndhamAdditionalCharge
                };
                let cartUnitsFound = cartUnits.filter(filterUnit => filterUnit.unitId !== this.props.unitId);
                newCartUnits = [...cartUnitsFound, unitInfo];
            }

            //console.log( "orderTotal 1", cartUnits, newCartUnits);
            orderTotal = 0;
            newCartUnits.map( (unit) => {
                let selectedAddons = '';
                if (unit.selectedAddons !== null && typeof(unit.selectedAddons) !== 'undefined') selectedAddons = unit.selectedAddons.split(",");
                orderTotal = Number.parseFloat(unit.selectedUnitPrice) + orderTotal;
                if (selectedAddons !== '' && selectedAddons.length > 1) {
                    selectedAddons.map( addon => {
                        if (addon !== '') {
                            equipmentData.addons.map( (addonName) => {
                                if (addonName.unitType === addon) {
                                    orderTotal = Number.parseFloat(addonName.price) + orderTotal;
                                }
                                return (null);
                            })
                        }
                        return (null);
                    })
                }
                return (null);
            });
            this.setState({
                orderTotal: orderTotal,
                lastName: lastName,
                cartUnits: newCartUnits
            });
        } else {
            this.setState({
                orderTotal: this.props.orderTotal,
                lastName: this.props.lastName,
                cartUnits: newCartUnits
            });
        }

    }

    componentWillUnmount() {
        this.setState({
            orderTotal: 0,
            lastName: '',
            cartUnits: {}
        });
    }

    sigPad = {};
    render() {
        const { saveSignature } = this.props;
        const { orderTotal, lastName } = this.state;

        //console.log( "orderTotal 2", orderTotal, lastName, this.props);

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h1 className="text-center">Signature</h1>
                        <h4 className="text-center">Charge Total: ${orderTotal}</h4>
                        <SignatureCanvas
                            className="ml-auto mr-auto"
                            penColor='black'
                            canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                            ref={(ref) => { this.sigPad = ref }}
                        />
                        <div className="text-center">
                            <h4>{lastName}</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                        <button
                            className="btn btn-block btn-secondary btn-lg mt-4"
                            onClick={
                                () => {
                                    saveSignature(this.sigPad.getCanvas().toDataURL('image/png'), this.state.cartUnits);
                                }
                            }
                        >
                            CONTINUE
                        </button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default GetSignature