import React, {Component} from 'react';
import Loading from '../../layout/Loading';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class ReportStandard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            textStartDate: '',
            textEndDate: '',
            reportType: ''
        };
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    handleChangeStart(date) {
        let newDate = new Date(date);
        let textStartDate = (newDate.getMonth()+1) + "-" + newDate.getDate() + "-" + newDate.getFullYear();
        this.setState({
            startDate: date,
            textStartDate: textStartDate
        });
    }
    handleChangeEnd(date) {
        let newDate = new Date(date);
        let textEndDate = (newDate.getMonth()+1) + "-" + newDate.getDate() + "-" + newDate.getFullYear();
        this.setState({
            endDate: date,
            textEndDate: textEndDate
        });
    }
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    render() {
        if (typeof(this.props.reportData) !== 'undefined') {
            const { reportTitle, reportHeaders, reportRows, reportFooter } = this.props.reportData;
            const { UpdateSearchReport, showLoading } = this.props;
            const { viewTransaction, user } = this.props;
            const { textStartDate, textEndDate, reportType, startDate, endDate } = this.state;
            return (
                <div>
                    <h3>{reportTitle}</h3>
                    <div className="row onlyprint">
                        <div className="col-12">
                            <h4>{textStartDate} - {textEndDate}</h4>
                        </div>
                    </div>
                    <div className="row bg-light rounded noprint">
                        <div className="col-3 p-4">
                            <label className="col-12">Report Type</label>
                            <select name="reportType" defaultValue={reportType} onChange={(e) => { this.onChange(e); UpdateSearchReport(e.target.value, startDate, endDate)}} className="form-control">
                                <option>-- Report Type --</option>

                                <option value='move'>Move</option>
                                <option value='agentTip'>Agent</option>
                                <option value='hamptonInnSuitesRoomCharge'>Hampton Inn</option>
                                <option value='wintersBeachClubRoomCharge'>Winters Beach Club</option>

                                <option value='tip'>Transactions</option>
                                <option value='creditCardTips'>Credit Card</option>
                                <option value='creditCardDaily'>Credit Card Daily</option>
                                <option value='dailyByType'>Daily Totals</option>
                            </select>
                        </div>
                        <div className="col-6 p-4">
                            <label className="col-12">Start Date</label>
                            <DatePicker
                                className="form-control"
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                onChange={this.handleChangeStart}
                            />

                            <DatePicker
                                className="form-control"
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                onChange={this.handleChangeEnd}
                            />
                        </div>
                        <div className="col-3 p-4">
                            <label className="col-12">End Date</label>
                            <button className="btn btn-warning" onClick={() => UpdateSearchReport(reportType, startDate, endDate)}>Search</button>
                        </div>
                    </div>

                    { showLoading === 1 && <Loading />}
                    { showLoading !== 1 && <table className="table table-striped table-sm table-bordered">
                        {reportHeaders && <thead className="thead-dark">
                            <tr>
                                {reportHeaders.map((field,index) =>
                                    <th key={index}>{field}</th>
                                )}
                                {reportType === 'tip' && <th className="noprint">&nbsp;</th>}
                            </tr>
                        </thead>}
                        {reportRows && <tbody>
                            {reportRows.map((row,index)=> <tr key={index}>
                                {row.map((data,index2) => {
                                    if ((reportType === 'tip' || reportType === 'creditCardTips' || reportType === 'adminOpalRoomCharge' || reportType === 'adminHyattRoomCharge') && (index2 === 8)) return null;
                                    if (reportType === 'wyndhamComp' && (index2 === 6 || index2 === 7)) return null;
                                    if (reportType === 'adminWyndhamWvo' && (index2 === 8 || index2 === 9)) return null;
                                    if (reportType === 'adminWyndhamUpgrade' && (index2 === 8 || index2 === 9)) return null;
                                    return (<td key={index2}>{data}</td>);
                                })}

                                {reportType === 'wyndhamComp' && <td className="noprint">
                                    <button className="btn btn-sm btn-primary m-1" onClick={ () => viewTransaction(row[7]) }><i className="fas fa-eye" /></button>
                                </td>}

                                {(reportType === 'creditCardTips' || reportType === 'tip' || reportType === 'adminOpalRoomCharge' || reportType === 'adminHyattRoomCharge') && <td className="noprint">
                                    <button className="btn btn-sm btn-primary m-1" onClick={ () => viewTransaction(row[8]) }><i className="fas fa-eye" /></button>
                                </td>}

                                {reportType === 'adminWyndhamWvo' && <td className="noprint">
                                    <button className="btn btn-sm btn-primary m-1" onClick={ () => viewTransaction(row[9]) }><i className="fas fa-eye" /></button>
                                </td>}

                                {reportType === 'adminWyndhamUpgrade' && <td className="noprint">
                                    <button className="btn btn-sm btn-primary m-1" onClick={ () => viewTransaction(row[9]) }><i className="fas fa-eye" /></button>
                                </td>}
                            </tr>)}
                        </tbody>}
                        {reportFooter && <tfoot>
                            <tr className="bg-warning">
                                {reportFooter.map((data,index) =>
                                    <td key={index}>{data}</td>
                                )}
                                {reportType === 'tip' && <th className="noprint">&nbsp;</th>}
                            </tr>
                        </tfoot>}
                    </table>}
                </div>
            );
        } else {
            return (<Loading />);
        }
    }
}
export default ReportStandard
