import React, {Component, Fragment} from 'react';

class unit extends Component {
    render() {
        const { id, type, number, status, totalPrice, paymentMethod } = this.props.unit;
        const { index, editUmbrella, scrollDiv, getScrollPos, locationName, searchTerm, extraStyle } = this.props;

        switch(status)
        {

            case (1) :
                if (this.props.auth.userType === 'S' && (this.props.currentDOY - this.props.startDOY) > 1 && this.props.dom !== "1") {
                    return (
                        <div
                            id={id}
                            style={{ display: 'block', float: 'left', width: 75}}
                            className={"bg-success py-2 text-light text-center rounded m-1 " + extraStyle}
                        >
                            {type}{number}<br />&nbsp;<br />&nbsp;<br />
                        </div>
                    );
                } else {
                    return (
                        <div
                            id={id}
                            style={{ display: 'block', float: 'left', width: 75}}
                            className={"bg-success py-2 text-light text-center rounded m-1 " + extraStyle}
                            onClick={()=>{getScrollPos(scrollDiv); editUmbrella(id, type, number, status, locationName)}}
                        >
                            {type}{number}<br />&nbsp;<br />&nbsp;<br />
                        </div>
                    );
                }

            case (2) :
                if (this.props.auth.userType === 'S' && (this.props.currentDOY - this.props.startDOY) > 1 && this.props.dom !== "1") {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-danger py-2 text-light text-center rounded m-1 " + extraStyle}
                        >
                            {type}{number}<br />&nbsp;<br />&nbsp;
                        </div>
                    );
                } else {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-danger py-2 text-light text-center rounded m-1 " + extraStyle}
                            onClick={() => {
                                getScrollPos(scrollDiv);
                                editUmbrella(id, type, number, status, locationName)
                            }}
                        >
                            {type}{number}<br/>{totalPrice !== 0 && `$${totalPrice}`}<br/>
                            {paymentMethod === 'Cash' && (<i className="far fa-money-bill-alt"/>)}
                            {paymentMethod === 'Credit Card' && (<i className="fab fa-cc-visa"/>)}
                            {paymentMethod === 'Room Charge' && (<i className="fas fa-hotel"/>)}
                            {paymentMethod === 'Voucher' && (<i className="fas fa-audio-description"/>)}
                        </div>
                    );
                }

            case (3) :
                if (this.props.auth.userType === 'S' && (this.props.currentDOY - this.props.startDOY) > 1 && this.props.dom !== "1") {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-warning py-2 text-white text-center rounded m-1 " + extraStyle}
                        >
                            {type}{number}<br />&nbsp;<br />&nbsp;
                        </div>
                    );
                } else {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-warning py-2 text-white text-center rounded m-1 " + extraStyle}
                            onClick={() => {
                                getScrollPos(scrollDiv);
                                editUmbrella(id, type, number, status, locationName)
                            }}
                        >
                            {type}{number}<br/>
                            {totalPrice !== 0 && <Fragment>${totalPrice}<br/></Fragment>}
                            {totalPrice === 0 && <Fragment>&nbsp;<br/></Fragment>}
                            {paymentMethod === '' && (<i className="fas fa-user-friends"/>)}
                            {paymentMethod === 'Cash' && (<i className="far fa-money-bill-alt"/>)}
                            {paymentMethod === 'Credit Card' && (<i className="fab fa-cc-visa"/>)}
                            {paymentMethod === 'Room Charge' && (<i className="fas fa-hotel"/>)}
                            {paymentMethod === 'Voucher' && (<i className="fas fa-audio-description"/>)}
                        </div>
                    );
                }

            case (4) :
                if (this.props.auth.userType === 'S' && (this.props.currentDOY - this.props.startDOY) > 1 && this.props.dom !== "1") {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-primary py-2 text-light text-center rounded m-1 " + extraStyle}
                        >
                            {type}{number}<br />&nbsp;<br />&nbsp;
                        </div>
                    );
                } else {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-primary py-2 text-light text-center rounded m-1 " + extraStyle}
                            onClick={() => {
                                getScrollPos(scrollDiv);
                                editUmbrella(id, type, number, status, locationName)
                            }}
                        >
                            {type}{number}<br/>{totalPrice !== 0 && `$${totalPrice}`}<br/>
                            {paymentMethod === 'Cash' && (<i className="far fa-money-bill-alt"/>)}
                            {paymentMethod === 'Credit Card' && (<i className="fab fa-cc-visa"/>)}
                            {paymentMethod === 'Room Charge' && (<i className="fas fa-hotel"/>)}
                            {paymentMethod === 'Voucher' && (<i className="fas fa-audio-description"/>)}
                        </div>
                    );
                }

            case (5) :
                if (this.props.auth.userType === 'S' && (this.props.currentDOY - this.props.startDOY) > 1 && this.props.dom !== "1") {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-occupied py-2 text-light text-center rounded m-1 " + extraStyle}
                        >
                            {type}{number}<br />&nbsp;<br />&nbsp;
                        </div>
                    );
                } else {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-occupied py-2 text-light text-center rounded m-1 " + extraStyle}
                            onClick={() => {
                                getScrollPos(scrollDiv);
                                editUmbrella(id, type, number, status, locationName)
                            }}
                        >
                            {type}{number}<br/>{totalPrice !== 0 && `$${totalPrice}`}<br/>
                            <i className="fas fa-user-friends"/>
                        </div>
                    );
                }

            case (6) :
                if (this.props.auth.userType === 'S' && (this.props.currentDOY - this.props.startDOY) < 1 && this.props.dom !== "1") {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-dark py-2 text-light text-center rounded m-1 " + extraStyle}
                        >
                            {type === "P" && "Pier"}{type === "T" && `Tower`}<br/>
                            {type === "P" && (
                                <>
                                    &nbsp;<br/>
                                </>
                            )}
                            {type === "T" && (
                                <>
                                    {number}<br/>
                                </>
                            )}
                            &nbsp;
                        </div>
                    );
                }
                else {
                    return (
                        <div
                            id={id}
                            style={{display: 'block', float: 'left', width: 75}}
                            className={"bg-dark py-2 text-light text-center rounded m-1 " + extraStyle}
                            onClick={() => {
                                getScrollPos(scrollDiv);
                                editUmbrella(id, type, number, status, locationName)
                            }}
                        >
                            {type === "P" && "Pier"}{type === "T" && `Tower`}<br/>
                            {type === "P" && (
                                <>
                                    &nbsp;<br/>
                                </>
                            )}
                            {type === "T" && (
                                <>
                                    {number}<br/>
                                </>
                            )}
                            &nbsp;
                        </div>
                    );
                }

            default :

                if (searchTerm === '' || typeof(searchTerm) === 'undefined') {
                    return (
                        <div
                            id={id}
                            style={{ display: 'block', float: 'left', width: 75}}
                            className="bg-light py-2 text-dark text-center rounded border m-1"
                            onClick={ ()=>{
                                getScrollPos(scrollDiv);
                                editUmbrella(id, type, number, status, locationName)
                            }}
                        >
                            ({index+1})<br />&nbsp;<br />&nbsp;
                        </div>
                    );
                } else {
                    return (
                        <div
                            id={id}
                            style={{ display: 'block', float: 'left', width: 75}}
                            className="bg-light py-2 text-dark text-center rounded border m-1"
                        >
                            ({index+1})<br />&nbsp;<br />&nbsp;
                        </div>
                    );
                }
        }
    }
}

export default unit
