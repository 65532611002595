import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { getReceipt } from "../../client/actions/apiActions";
import 'react-confirm-alert/src/react-confirm-alert.css'
import logo from "../../assets/logo.png";
import '../../assets/Receipt.css';

class Receipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            receiptId: [],
            successMessage: '',
            errorMessage: '',
            name: '',
            receiptNumber: '',
            purchaseDate: '',
            notes: '',
            price: ''
        };
    };

    componentDidMount() {
        // console.log("componentDidMount", this.props.match.params.receiptId);
        let receiptId = this.props.match.params.receiptId;
        this.props.getReceipt(receiptId);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            // console.log("props", this.props.peopleList.orderInfo);
            this.setState({
                purchaseDate: this.props.peopleList.orderInfo.purchaseDate,
                receiptNumber: this.props.peopleList.orderInfo.ourTransactionId,
                price: this.props.peopleList.orderInfo.Price,
                unitNumber: this.props.peopleList.unitNumber,
                unitLocation: this.props.peopleList.unitLocation,
                unitRow: this.props.peopleList.unitRow,
                unitPosition: this.props.peopleList.unitPosition,
                tipAmount: this.props.peopleList.orderInfo.tipAmount,
                tipPercent: this.props.peopleList.orderInfo.tipPercent,
                Total: this.props.peopleList.orderInfo.Total,
                retailPrice: this.props.peopleList.orderInfo.retailPrice,
            })
        }
    }

    render() {
        const { name, receiptNumber, purchaseDate, price, unitNumber, tipAmount, retailPrice, Total, tipPercent } = this.state;
        return (<table className="body-wrap" id="Receipt">
            <tbody>
            <tr>
                <td className="container" width="600">
                    <div className="content">
                        <table className="main" width="100%" cellPadding="0" cellSpacing="0">
                            <tbody>
                            <tr>
                                <td className="content-wrap text-center">
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                        <tr>
                                            <td className="content-block"><img src={logo} alt={name} /></td>
                                        </tr>
                                        <tr>
                                            <td className="content-block">
                                                <h2>Receipt</h2>
                                                <table className="invoice">
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            {name}<br/>
                                                            Invoice #{receiptNumber}<br/>
                                                            {purchaseDate}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <table className="invoice-items" cellPadding="0" cellSpacing="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td colSpan="2">Unit: {unitNumber}</td>
                                                                </tr>
                                                                {tipAmount !== 0 ? <>
                                                                    <tr className="total">
                                                                        <td colSpan="2" className="text-right">Retail Value $ {retailPrice}</td>
                                                                    </tr>
                                                                    <tr className="total">
                                                                        <td colSpan="2" className="text-right">Sub Total $ {price}</td>
                                                                    </tr>
                                                                    <tr className="total">
                                                                        <td colSpan="2">Gratuity Selected {tipPercent}%</td>
                                                                    </tr>
                                                                    <tr className="total">
                                                                        <td colSpan="2" className="text-right">Gratuity $ {tipAmount}</td>
                                                                    </tr>
                                                                    <tr className="total">
                                                                        <td colSpan="2" className="text-right">Total $ {Total}</td>
                                                                    </tr>
                                                                </>:<>
                                                                    <tr className="total">
                                                                        <td colSpan="2" className="text-right">Total $ {Total}</td>
                                                                    </tr>
                                                                </>}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="content-block">
                                                            Winters Beach<br/>
                                                            655 S Gulfview Blvd, <br/>
                                                            Clearwater Beach, FL 33767
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="footer">
                                        <table width="100%">
                                            <tbody>
                                            <tr>
                                            <td className="text-center content-block">Questions? Email <a href={`mailto:gm@wintersbeachclub.com?subject=question about my rental : ${receiptNumber}`}>gm@wintersbeachclub.com</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>);
    }
}

Receipt.propTypes = {
    peopleList: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    peopleList: state.pages.peopleList
});

export default connect( mapStateToProps, { getReceipt })(Receipt);
