import React, { Component} from 'react';
import classnames from 'classnames';
import Uploading from "./Uploading";
import ShowInputError from "./ShowInputError";
import store from "../../client/store";
import {ApiURL} from "../../config";

class FormInputFile extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            uploading: false,
            uploadErrorMessage: ''
        };
        this.onDeleteFile = this.onDeleteFile.bind(this);
    };
    onUpload = (e) => {
        const files = Array.from(e.target.files);
        this.setState({ uploading: true });
        const formData = new FormData();
        const token = store.getState().auth.token;
        let imageFieldName = e.target.name;
        formData.append('name', imageFieldName);
        formData.append('id', this.props.id);
        formData.append('tableName', this.props.tableName);

        files.forEach((file, i) => {
            formData.append(i, file);
        });

        fetch(`${ApiURL}/file-upload`, {
            method: 'POST',
            headers: {'Authorization': token},
            body: formData
        })
            .then(res => res.json())
            .then(images => {
                this.setState({image: images.newFileName, uploading: false});
                this.props.onChange("image", images.newFileName, this.props.field)
            })
    };
    onDeleteFile = () => {
        this.props.onChange("image", '', this.props.field)
    };
    render() {
        const { colSize, name, value, label, placeholder, helpText, error } = this.props;
        const { uploading, uploadErrorMessage } = this.state;
        let divColSize;
        if (colSize === 0) divColSize = 0;
        if (colSize === 1) divColSize = 12;
        if (colSize === 2) divColSize = 6;
        if (colSize === 3) divColSize = 4;
        if (colSize === 4) divColSize = 8;

        return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
            <div className="container p-0">
                <div className="form-group row border-bottom pb-2">
                    <label className="col-12" htmlFor={name}>{label}</label>
                    {uploadErrorMessage !== '' && <div className="alert alert-danger col-12">{uploadErrorMessage}</div>}
                    {value && <div className="col-12 py-2">
                        <a className="btn btn-primary" href={value} target="_blank" rel="noopener noreferrer">View File</a>{' '}
                        <button className="btn btn-danger" onClick={() => this.onDeleteFile(name)}>Delete File</button>
                    </div>}

                    <div className="col-12">
                        {uploading === true && <Uploading />}
                        {uploading === false && value === '' && <input
                            id={name}
                            name={name}
                            type="file"
                            className={classnames({ 'is-invalid': error})}
                            onChange={this.onUpload}
                            aria-describedby={name+"Help"}
                            placeholder={placeholder}
                        />}
                    </div>
                    {helpText && <small id={name + "Help"} className="col-12 form-text text-muted">{helpText}</small>}
                    {error && <ShowInputError error={error} />}
                </div>
            </div>
        </div>)
    }
}

export default FormInputFile