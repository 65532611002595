import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {
    saveConfigSettings,
    getConfigSettings
} from "../../client/actions/apiActions";
import { Editor } from '@tinymce/tinymce-react';
import {Link} from "react-router-dom";

class Error404 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialDataLoaded: false,
            fromName: '',
            fromEmail: '',
            paymentSubject: '',
            successMessage: '',
            reportEmailMessage: '',
            reportEmailSubject: '',
            reportEmailTime: '',
            receiptTextMessage: '',
            receiptEmailSubject: '',
            receiptEmailBody: '',
        };
    };
    componentDidMount() {
        this.props.getConfigSettings();
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        const {initialDataLoaded} = this.state;
        if (initialDataLoaded === false && typeof (nextProps.data) !== 'undefined') {
            this.setState({
                initialDataLoaded: true,
                data: nextProps.data,
                fromName: nextProps.data.fromName,
                fromEmail: nextProps.data.fromEmail,
                paymentSubject: nextProps.data.paymentSubject,
                reportEmailMessage: nextProps.data.reportEmailMessage,
                reportEmailSubject: nextProps.data.reportEmailSubject,
                reportEmailTime: nextProps.data.reportEmailTime,
                receiptTextMessage: nextProps.data.receiptTextMessage,
                receiptEmailSubject: nextProps.data.receiptEmailSubject,
                receiptEmailBody: nextProps.data.receiptEmailBody,
            });
        } else if (nextProps.saveData.success === '1' || nextProps.saveData.success === 1) {
            this.setState({successMessage: nextProps.saveData.message});
        }
    }
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    handleEditorChange = (e) => {
        let content = e.target.getContent();
        //console.log("Content", content);
        this.setState({reportEmailMessage: content});
    };
    saveConfigSettings = () => {
        const { fromName, fromEmail, paymentSubject, reportEmailTime, reportEmailSubject, reportEmailMessage, receiptTextMessage, receiptEmailSubject, receiptEmailBody } = this.state;
        this.props.saveConfigSettings( fromName, fromEmail, paymentSubject, reportEmailTime, reportEmailSubject, reportEmailMessage, receiptTextMessage, receiptEmailSubject, receiptEmailBody );
        window.scrollTo(0, 0);
    };
    render() {
        const { fromName, fromEmail, paymentSubject, successMessage, reportEmailTime, reportEmailSubject, reportEmailMessage, receiptTextMessage, receiptEmailSubject, receiptEmailBody } = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 pt-4">
                        <h1 className="text-center">Config Settings</h1>
                    </div>
                    <div className="col-12 py-4"><Link to={"/config/reports"}>Manage Reports Config</Link></div>
                    {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">From Name</label>
                            <input type="text" className="form-control" id="fromName" name="fromName" onChange={this.onChange} placeholder="enter from name ..." value={fromName} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">From Email</label>
                            <input type="text" className="form-control" id="fromEmail" name="fromEmail" onChange={this.onChange} placeholder="enter from email ..." value={fromEmail} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Payment Subject</label>
                            <input type="text" className="form-control" id="paymentSubject" name="paymentSubject" onChange={this.onChange} placeholder="enter payment subject ..." value={paymentSubject} />
                        </div>
                    </div>

                    <div className="col-12">
                        <h2>Auto Report Config</h2>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="reportEmailTime">Report Email Time<br/>
                                Enter in 2 digit hour in military time.
                            </label>
                            <input type="text" className="form-control" id="reportEmailTime" name="reportEmailTime" onChange={this.onChange} placeholder="enter report run time ..." value={reportEmailTime} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="reportEmailTime">Report Email Subject</label>
                            <input type="text" className="form-control" id="reportEmailSubject" name="reportEmailSubject" onChange={this.onChange} placeholder="enter report run time ..." value={reportEmailSubject} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="reportEmailMessage">Report Email Message</label>
                            <Editor
                                apiKey='r7zawome3jz6v10sen489g702inbdx7hexim6spf3cy9b550'
                                initialValue={reportEmailMessage}
                                name="reportEmailMessage"
                                id="reportEmailMessage"
                                init={{
                                    height: 350,
                                    plugins: 'link image code',
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                }}
                                onChange={this.handleEditorChange}
                            />
                        </div>
                    </div>

                    <div className="col-12">
                        <h2>Receipt Settings</h2>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="receiptTextMessage">Text Receipt Message</label>
                            <textarea
                                rows="3"
                                className="form-control"
                                id="receiptTextMessage"
                                name="receiptTextMessage"
                                onChange={this.onChange}
                                placeholder="enter text message ..."
                                value={receiptTextMessage}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="receiptEmailSubject">Email Receipt Subject</label>
                            <input type="text" className="form-control" id="receiptEmailSubject" name="receiptEmailSubject" onChange={this.onChange} placeholder="enter receipt email subject ..." value={receiptEmailSubject} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="receiptEmailBody">Email Receipt Message</label>
                            <textarea
                                rows="6"
                                className="form-control"
                                id="receiptEmailBody"
                                name="receiptEmailBody"
                                onChange={this.onChange}
                                placeholder="enter receipt email message ..."
                                value={receiptEmailBody}
                            />
                        </div>
                    </div>

                    <div className="col-sm-6 offset-sm-3 col-md-6 offset-md-3 text-center mb-3">
                        <button type="submit" className="btn btn-secondary btn-block" onClick={() => this.saveConfigSettings()}>SAVE CONFIG SETTINGS</button>
                    </div>
                </div>
            </div>
        )
    }
}

Error404.propTypes = {
    data: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    saveConfigSettings: PropTypes.func.isRequired,
    getConfigSettings: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    data: state.pages.data,
    saveData: state.pages.saveData
});

export default connect( mapStateToProps, { saveConfigSettings, getConfigSettings })(Error404);
