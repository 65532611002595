import React, {Component, Fragment} from 'react';

class SelectHotelCustomerName extends Component {
    state = {
        manualEntry: false,
    };
    onChangeManualEntry = () => {
        let { manualEntry } = this.state;
        if (manualEntry === false)
        {
            manualEntry = true;
        }else {
            manualEntry = false;
        }
        this.setState({manualEntry : manualEntry});
    };

    render() {
        const { hotelResults, hotelLastName, hotelRoomNumber, roomChargeHotel, onChange, onChangeHotel, lastName, roomNumber, saveWyndhamGuestName, saveGuestName } = this.props;
        const { manualEntry } = this.state;
        return (
            <React.Fragment>
                {roomChargeHotel === 'Wyndham' && <Fragment>
                    <div className="row bg-light border-bottom border-top mt-3">
                        <div className="col-12 pt-2">
                            <input
                                type="checkbox"
                                name="manualEntry"
                                id="manualEntry"
                                value="1"
                                onChange={this.onChangeManualEntry}
                            />
                            <label htmlFor="manualEntry">
                                {" "}
                                &nbsp;
                                Manual Entry
                                {manualEntry}
                            </label>
                        </div>
                    </div>
                    <div className="row bg-light border-bottom border-top mt-3">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="hotelLastName"
                                    name="hotelLastName"
                                    placeholder="enter last name ..."
                                    onChange={onChangeHotel}
                                    defaultValue={hotelLastName}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            {manualEntry === true && <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Room Number</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="hotelRoomNumber"
                                    name="hotelRoomNumber"
                                    placeholder="enter room number ..."
                                    onChange={onChangeHotel}
                                    defaultValue={hotelRoomNumber}
                                />
                            </div>}
                        </div>
                    </div>
                </Fragment>}

                {roomChargeHotel !== 'Wyndham' && <div className="row bg-light border-bottom border-top mt-3">
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Last Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                placeholder="enter last name ..."
                                onChange={onChange}
                                defaultValue={lastName}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Room Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="roomNumber"
                                name="roomNumber"
                                placeholder="enter room number ..."
                                onChange={onChange}
                                defaultValue={roomNumber}
                            />
                        </div>
                    </div>
                </div>}

                {roomChargeHotel === 'Wyndham' && manualEntry !== true && <Fragment>
                    <table className="mt-3 table table-striped">
                        <thead className="thead-dark text-light">
                            <tr>
                                <th width="20%">Room</th>
                                <th width="60%">Name</th>
                                <th width="60%">Alt Name</th>
                                <th width="20%">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            { typeof(hotelResults) !== 'undefined' && hotelResults !== null && (hotelRoomNumber !== null || hotelLastName !== null) && <Fragment>
                                {hotelResults && typeof(hotelResults) !== 'undefined' && <Fragment>
                                    {hotelResults.map((room, index) => {
                                        let roomNumber = '';
                                        if (hotelLastName !== '') {
                                            // change room number to
                                            let i;
                                            if (typeof(room.Room) !== 'undefined') {
                                                for (i = 0; i < room.Room.length; i++) {
                                                    if (i === 0 || i === 1) {
                                                        roomNumber += 'X';
                                                    } else {
                                                        roomNumber += room.Room[i];
                                                    }
                                                }
                                            }
                                        } else {
                                            roomNumber = room.Room;
                                        }

                                        return (<tr key={index}>
                                            <td>{roomNumber}</td>
                                            <td>{room.Guest_Name}</td>
                                            <td>{room.Accompanying_Guest_Name}</td>
                                            <td nowrap="true">
                                                {typeof(room.Room) !== 'undefined' && <Fragment>
                                                {room.Additional === 1 && <button onClick={()=>saveWyndhamGuestName(room.Room, room.Guest_Name, manualEntry, 1)} className="btn btn-success btn-sm">SELECT</button>}
                                                {room.Additional === 0 && room.Room !== '' && typeof(room.Room) !== 'undefined' && <button onClick={()=>saveWyndhamGuestName(room.Room, room.Guest_Name, manualEntry, 0)} className="btn btn-primary btn-sm">SELECT</button>}
                                                </Fragment>}
                                            </td>
                                        </tr>);

                                    })}
                                </Fragment>}
                            </Fragment>}
                        </tbody>
                    </table>
                </Fragment>}

                {roomChargeHotel !== 'Wyndham' && lastName !== '' && lastName !== null && roomNumber !== '' && roomNumber !== null &&
                    <div className="row justify-content-md-center mt-3">
                        <div className="col-sm-12 col-md-6 text-center">
                            <button
                                className="btn btn-block btn-secondary btn-lg mt-4"
                                onClick={() => saveGuestName()}
                            >CONTINUE</button>
                        </div>
                    </div>
                }

                {roomChargeHotel === 'Wyndham' && manualEntry === true && hotelLastName !== '' && hotelLastName !== null && hotelRoomNumber !== '' && hotelRoomNumber !== null &&
                    <div className="row justify-content-md-center mt-3">
                        <div className="col-sm-12 col-md-6 text-center">
                            <button
                                className="btn btn-block btn-secondary btn-lg mt-4"
                                onClick={() => saveWyndhamGuestName(hotelRoomNumber, hotelLastName, manualEntry) }
                            >CONTINUE</button>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default SelectHotelCustomerName
