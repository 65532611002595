import React, {Component} from 'react';
import {connect} from "react-redux";

class Footer extends Component {
    render() {
        const { user } = this.props.auth;
        if (user !== null)
        {
            return (
                <footer className="page-footer noprint mt-4">
                    <div className="container-fluid font-small bg-light text-dark">
                        <div className="container">
                            <div className="row pt-3">
                                <div className="col-md-12">
                                    <p>© Winters Beach | All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            )
        }
        else
        {
            return (<div>&nbsp;</div>);
        }
    }
}

const mapStateToProps = state => ({
    auth:state.auth
});

export default connect(mapStateToProps, {  })(Footer);
