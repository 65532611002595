import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { savePeople } from "../../client/actions/apiActions";

import ItemRadio from "../form/itemRadio";
import {Redirect} from "react-router-dom";
import ItemText from '../form/itemText'

class People extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peopleData: {},
            successMessage: '',
            errorMessage: '',
            cancelEdit: false,
            user: '',
            name: '',
            email: '',
            pass: '',
            confirmPass: '',
            initials: '',
            phone: '',
            locations: '',
            saveData: '',
            admin: '0',
            manager: '0',
            supervisor: '0',
            selectedLocations: null,
            errors : {}
        };
        this.selectedCheckboxes = new Set();
    };

    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onChangeRadio = (name, value) => {
        this.setState({
            [name]: value
        });
    };
    handleCheckboxChange = label => {
        if (this.selectedCheckboxes.has(label)) {
            this.selectedCheckboxes.delete(label);
        } else {
            this.selectedCheckboxes.add(label);
        }
    };

    savePeople = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();

        let noerror = true;
        let id = 0;
        let errors = {};
        let selectedLocations = ',';
        for (const checkbox of this.selectedCheckboxes) {
            selectedLocations += checkbox + ",";
        }
        const { user, name, email, pass, confirmPass, initials, phone, locations, admin, manager, supervisor } = this.state;

        if (name === '') {
            noerror = false;
            errors = {...errors, name: 'You left the users "Name" blank'};
        }
        if (initials === '') {
            noerror = false;
            errors = {...errors, initials: 'You left the users "Initials" blank'};
        }
        if (user === '') {
            noerror = false;
            errors = {...errors, user: 'You left the users "Username" blank'};
        }
        if (pass === '') {
            noerror = false;
            errors = {...errors, pass: 'You left the users "Password" blank'};
        }
        if (pass !== '' && confirmPass !== '' && confirmPass !== pass) {
            noerror = false;
            errors = {...errors, confirmPass: 'Your passwords do not match'};
        }
        if ( confirmPass === '' ) {
            noerror = false;
            errors = {...errors, confirmPass: 'Your did not confirm your "Password"'};
        }

        if (noerror === true) {
            let peopleData = { id, user, selectedLocations, name, email, pass, confirmPass, initials, phone, locations, admin, manager, supervisor };
            this.props.savePeople( peopleData );
            this.props.history.push('/config/people');
        } else {
            this.setState({errors});
        }
    };

    cancelEdit = () => {
        this.setState({
            peopleData: {},
            successMessage: '',
            errorMessage: '',
            cancelEdit: true,
            user: '',
            name: '',
            email: '',
            pass: '',
            confirmPass: '',
            initials: '',
            phone: '',
            locations: '',
            admin: 0,
            manager: 0,
            supervisor: 0,
            selectedLocations: null
        });
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
    };

    render() {
        const {
            errorMessage,
            name,
            email,
            user,
            pass,
            confirmPass,
            initials,
            phone,
            admin,
            manager,
            supervisor,
            cancelEdit,
            errors
        } = this.state;

        if (cancelEdit === true) {
            return (<Redirect to="/config/people"/> )
        } else {
            return (
                <div className="container">
                    <form name="addPeopleForm" id="addPeopleForm" autoComplete="off" onSubmit={this.savePeople}>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Add New User</h1>
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}

                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePeople()}>SAVE USER</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>

                    <h3>Personal Information</h3>
                    <ItemText
                        label="Full Name"
                        type="text"
                        id="name"
                        value={name}
                        placeholder="Enter full name ..."
                        onChange={this.onChange}
                        errors={errors.name}
                    />

                    <ItemText
                        label="Initials"
                        id="initials"
                        placeholder="Enter initials ..."
                        value={initials}
                        type="text"
                        onChange={this.onChange}
                        errors={errors.initials}
                    />

                    <ItemText
                        label="Phone"
                        id="phone"
                        placeholder="Enter Phone Number ..."
                        value={phone}
                        type="text"
                        onChange={this.onChange}
                        errors={errors.phone}
                    />

                    <ItemText
                        label="Email"
                        id="email"
                        placeholder="Enter Email ..."
                        value={email}
                        type="text"
                        onChange={this.onChange}
                        errors={errors.email}
                    />

                    <h3>Permissions</h3>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Admin</label>
                        <div className="col-sm-8">
                            <div className="row">
                                <div className="col">
                                    <ItemRadio
                                        label="Yes"
                                        name="admin"
                                        radioId={"adminY"}
                                        value="1"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={admin}
                                    />
                                </div>
                                <div className="col">
                                    <ItemRadio
                                        label="No"
                                        name="admin"
                                        radioId={"adminN"}
                                        value="0"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={admin}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Supervisor</label>
                        <div className="col-sm-8">
                            <div className="row">
                                <div className="col">
                                    <ItemRadio
                                        label="Yes"
                                        name="supervisor"
                                        radioId={"supervisorY"}
                                        value="1"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={supervisor}
                                    />
                                </div>
                                <div className="col">
                                    <ItemRadio
                                        label="No"
                                        name="supervisor"
                                        radioId={"supervisorN"}
                                        value="0"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={supervisor}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Manager</label>
                        <div className="col-sm-8">
                            <div className="row">
                                <div className="col">
                                    <ItemRadio
                                        label="Yes"
                                        name="manager"
                                        radioId={"managerY"}
                                        value="1"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={manager}
                                    />
                                </div>
                                <div className="col">
                                    <ItemRadio
                                        label="No"
                                        name="manager"
                                        radioId={"managerN"}
                                        value="0"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={manager}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3>User Credentials</h3>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Username</label>
                        <div className="col-sm-8"><input type="text" className="form-control" id="user" name="user" onChange={this.onChange} autoComplete="new-username" placeholder="enter user ..." value={user} /></div>
                        {errors.user && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors.user}</div></div>}
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Password</label>
                        <div className="col-sm-8"><input type="password" className="form-control" id="pass" name="pass" onChange={this.onChange} autoComplete="new-password" placeholder="enter pass ..." value={pass} /></div>
                        {errors.pass && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors.pass}</div></div>}
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Confirm Password</label>
                        <div className="col-sm-8"><input type="password" className="form-control" id="confirmPass" name="confirmPass" onChange={this.onChange} autoComplete="new-password" placeholder="confirm pass ..." value={confirmPass} /></div>
                        {errors.confirmPass && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors.confirmPass}</div></div>}
                    </div>

                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePeople()}>SAVE USER</button></div>
                    </div>
                    </form>
                </div>
            )
        }
    }
}

People.propTypes = {
    savePeople: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    saveData: state.pages.saveData
});

export default connect(mapStateToProps, { savePeople })(People);