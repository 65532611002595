import React, {Component}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getPeopleList, deletePeople } from "../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Loading from "../layout/Loading"

class People extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peopleList: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getPeopleList();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            peopleList: nextProps.peopleList.people
        });
    }

    deletePeople = (userId, userName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this user "${userName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deletePeople(userId); this.setState({successMessage: "User has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("User clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            peopleList,
            successMessage
        } = this.state;
        if (peopleList !== '' && peopleList.length > 0) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Winters Beach Users</h1>
                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                            <Link className="btn btn-secondary my-2" to="/config/people/add">Add User</Link>
                            <table className="table table-striped">
                                <thead className="thead-dark">
                                <tr>
                                    <th>Name</th>
                                    <th>Initials</th>
                                    <th>Type</th>
                                    <th>User</th>
                                    <th>Locations</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {peopleList.map((person, index) => (
                                        <tr key={index}>
                                            <td>{person.name}</td>
                                            <td>{person.initials}</td>
                                            <td>{person.userType}</td>
                                            <td>{person.user}</td>
                                            <td>{person.locations}</td>
                                            <td className="text-right">
                                                <Link className="btn btn-warning btn-lg m-1" to={"/config/people/edit/" + person.id}><i className="fas fa-pencil-alt"></i></Link>
                                                <button className="btn btn-danger btn-lg m-1" onClick={()=>this.deletePeople(person.id, person.name)}><i className="fas fa-times"></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <Loading/>
            )
        }
    }
}

People.propTypes = {
    peopleList: PropTypes.object.isRequired,
    getPeopleList: PropTypes.func.isRequired,
    deletePeople: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    peopleList: state.pages.peopleList
});

export default connect( mapStateToProps, { getPeopleList, deletePeople })(People);
