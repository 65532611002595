import React, {Component}  from 'react';
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { savePrice, getPrice } from "../../../client/actions/apiActions";

import Loading from "../../layout/Loading"
import {Redirect} from "react-router-dom";
import ItemRadio from "../../form/itemRadio";
import ItemText from "../../form/itemText";

class Price extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            cancelEdit: false,
            errors : {},
            id:'',
            name:'',
            price:'',
            addOn:'',
            sort: 0,
            projectHotels:'',
            startTime:'',
            endTime:'',
            unitType:'',
            allHotels:[],
            retailRate: '0.00',
            hotelRate: '0.00'
        }
    };
    componentDidMount() {
        try {
            const {id} = this.props.match.params;
            this.props.getPrice(id);
        }
        catch (e) {
            console.log("e", e);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (typeof(nextProps.outputData) !== 'undefined')
        {
            const { outputData } = nextProps;
            this.setState({
                id: outputData.id,
                name: outputData.name,
                price: outputData.price,
                retailRate: outputData.retailRate,
                hotelRate: outputData.hotelRate,
                addOn: outputData.addOn,
                sort: outputData.sort,
                projectHotels: outputData.projectHotels,
                startTime: outputData.startTime,
                endTime: outputData.endTime,
                unitType: outputData.unitType,
                allHotels: outputData.allHotels
            });
        }
    }
    onChange = (e) => this.setState({[e.target.name]: e.target.value});
    onChangeRadio = (name, value) => {
        this.setState({
            [name]: value
        });
    };
    savePrice = (e) => {
        if (typeof(e) !== 'undefined') e.preventDefault();
        const { id } = this.state;

        let noerror = true;
        let errors = {};
        const { name, price, retailRate, hotelRate, addOn, sort, projectHotels, startTime, endTime, unitType } = this.state;

        if (name === '') {
            noerror = false;
            errors = {...errors, name: 'You left the price "Name" blank'};
        }

        if (noerror === true) {
            let priceData = { id, name, price, retailRate, hotelRate, addOn, sort, projectHotels, startTime, endTime, unitType };
            this.props.savePrice( priceData );
            this.props.history.push('/config/prices');
        } else {
            this.setState({errors});
        }
    };
    cancelEdit = () => {
        this.setState({
            errorMessage: '',
            cancelEdit: true,
            id:'', name:'', price:'', sort:'', addOn:'', projectHotels:'', startTime:'', endTime:'', unitType:'', allHotels: [],
            retailRate: '0.00',
            hotelRate: '0.00'
        });
    };

    render() {
        const {
            errorMessage,
            errors,
            cancelEdit,
            name, price, addOn, projectHotels, startTime, endTime, unitType, allHotels, sort,
            retailRate,
            hotelRate
        } = this.state;
        if (cancelEdit === true) {
            return (<Redirect to="/config/prices"/> )
        } else if (typeof(name) !== 'undefined' && name !== null && name !== '') {
            return (
                <div className="container">
                    <form name="editPriceForm" id="editPriceForm" autoComplete="off" onSubmit={this.savePrice}>
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Edit Price : {name}</h1>
                        </div>
                        {errorMessage && <div className="col-12"><div className="alert alert-danger">{errorMessage}</div></div>}

                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePrice()}>SAVE PRICE</button></div>
                        <div className="col-12"><hr noshade="true" /></div>
                    </div>

                    <ItemText
                        label="Name"
                        id="name"
                        placeholder="enter name ... "
                        value={name}
                        type="text"
                        errors={errors.name}
                        onChange={this.onChange}
                    />
                    <ItemText
                        label="Price"
                        id="price"
                        placeholder="enter price ... "
                        value={price}
                        type="text"
                        errors={errors.price}
                        onChange={this.onChange}
                    />
                    <ItemText
                        label="Retail Rate"
                        id="retailRate"
                        placeholder="enter retail rate ... "
                        type="text"
                        value={retailRate}
                        errors={errors.retailRate}
                        onChange={this.onChange}
                    />
                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Is this an Add on</label>
                        <div className="col-sm-8">
                            <div className="row">
                                <div className="col">
                                    <ItemRadio
                                        label="Yes"
                                        name="addOn"
                                        radioId={"addOnY"}
                                        value="1"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={addOn}
                                    />
                                </div>
                                <div className="col">
                                    <ItemRadio
                                        label="No"
                                        name="addOn"
                                        radioId={"addOnN"}
                                        value="0"
                                        onChangeRadio={this.onChangeRadio}
                                        selectedValue={addOn}
                                    />
                                </div>
                            </div>
                        </div>
                        {errors.addOn && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up"></i> {errors.addOn}</div></div>}
                    </div>

                    <div className="form-group row">
                        <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">Hotel Only</label>
                        <div className="col-sm-8">
                            <select
                                id="projectHotels"
                                name="projectHotels"
                                defaultValue={projectHotels}
                                onChange={this.onChange}
                                className="form-control"
                            >
                                <option value="">-- SELECT --</option>
                                {allHotels ? (
                                        allHotels.map((options, index) =>
                                            <option
                                                key={index}
                                                value={options.id}
                                            >
                                                {options.name}
                                            </option>
                                        ))
                                    : null
                                }
                            </select>
                        </div>
                        {errors.projectHotels && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {errors.projectHotels}</div></div>}
                    </div>

                    <ItemText
                        label="Start Time"
                        id="startTime"
                        placeholder="enter start time ... "
                        value={startTime}
                        type="text"
                        errors={errors.startTime}
                        onChange={this.onChange}
                    />
                    <ItemText
                        label="End Time"
                        id="endTime"
                        placeholder="enter end time ... "
                        value={endTime}
                        type="text"
                        errors={errors.endTime}
                        onChange={this.onChange}
                    />
                    <ItemText
                        label="Unit Code"
                        id="unitType"
                        placeholder="enter unit code ... "
                        value={unitType}
                        type="text"
                        errors={errors.unitType}
                        onChange={this.onChange}
                    />
                    <ItemText
                        label="sort"
                        id="sort"
                        placeholder="enter sort ... "
                        value={sort}
                        type="text"
                        errors={errors.sort}
                        onChange={this.onChange}
                    />

                    <div className="row">
                        <div className="col-12"><hr noshade="true" /></div>
                        <div className="col-sm-6 text-left mb-3"><button type="button" className="btn btn-secondary" onClick={() => this.cancelEdit()}>Back</button></div>
                        <div className="col-sm-6 text-right mb-3"><button type="button" className="btn btn-success" onClick={() => this.savePrice()}>SAVE PRICE</button></div>
                    </div>
                    </form>
                </div>
            )
        } else  {
            return (<Loading />)
        }
    }
}

Price.propTypes = {
    outputData: PropTypes.object.isRequired,
    saveData: PropTypes.object.isRequired,
    savePrice: PropTypes.func.isRequired,
    getPrice: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    outputData: state.pages.outputData,
    saveData: state.pages.saveData
});

export default connect( mapStateToProps, { savePrice, getPrice })(Price);
