import React, {Component} from 'react';

class itemRadio extends Component {
    state = {
        isChecked: false,
    };

    toggleCheckboxChange = () => {
        const {handleCheckboxChange, value} = this.props;
        this.setState(({isChecked}) => (
            {
                isChecked: !isChecked,
            }
        ));
        handleCheckboxChange(value);
    };

    render() {
        const { label, name, radioId, value, checkedValues } = this.props;
        return (
            <div className="form-group my-1">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <input
                                type="checkbox"
                                name={name}
                                id={radioId}
                                defaultChecked={checkedValues && checkedValues.indexOf("," + value.toString() + ",") !== -1 ? 'checked' : null }
                                value={value}
                                onChange={this.toggleCheckboxChange}
                            />
                        </div>
                    </div>
                    <label htmlFor={radioId} className="form-control">{label}</label>
                </div>
            </div>
        )
    }
}

export default itemRadio