import React, { Component } from 'react';
import Loading from '../../layout/Loading';

class ReportStandard extends Component {
    render() {
        if (typeof(this.props.reportData) !== 'undefined') {
            const { reportTitle, reportHeaders, reportRows, reportFooter } = this.props.reportData;
            return (
                <div>
                    <h3>{reportTitle}</h3>
                    <table className="table table-striped table-sm table-bordered">
                        
                        {reportHeaders && <thead className="thead-dark">
                            <tr>
                                {reportHeaders.map((field,index) =>
                                    <th key={index}>{field}</th>
                                )}
                            </tr>
                        </thead>}

                        {reportRows && <tbody>
                            {reportRows.map((row,index)=> <tr key={index}>
                                {row.map((data,index2) =>
                                    <td key={index2}>{data}</td>
                                )}
                            </tr>)}
                        </tbody>}

                        {reportFooter && <tfoot>
                            <tr className="bg-warning">
                                {reportFooter.map((data,index) =>
                                    <td key={index}>{data}</td>
                                )}
                            </tr>
                        </tfoot>}

                    </table>
                </div>
            );
        } else {
            return (<Loading />);
        }
    }
}
export default ReportStandard