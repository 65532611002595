import React, {Component} from 'react';

class ItemText extends Component {
    render() {
        const { errors, label, type, id, value, autoFocus, placeholder, onChange } = this.props;
        return (<div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="exampleFormControlInput1">{label}</label>
            <div className="col-sm-8">
                <input
                    type={type}
                    className="form-control"
                    id={id}
                    name={id}
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value}
                    autoFocus={autoFocus}
                /></div>
            {errors && <div className="col-sm-12"><div className="alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {errors}</div></div>}
        </div>);
    }
}

export default ItemText