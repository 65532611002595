import React, {Component} from 'react';

class CustomerName extends Component {
    render() {
        const { lastName, onChange, saveGuestName} = this.props;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 mt-4">
                                <input
                                    type="text"
                                    name="lastName"
                                    className="form-control"
                                    id="lastName"
                                    placeholder="enter customer name ..."
                                    onChange={onChange}
                                    value={lastName}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {lastName &&
                <div className="row">
                    <div className="col-sm-12 col-md-6 offset-md-3 text-center">
                        <button
                            className="btn btn-block btn-secondary btn-lg mt-4"
                            onClick={() => saveGuestName()}
                        >
                            CONTINUE
                        </button>
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}

export default CustomerName