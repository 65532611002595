import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './client/store';
import PrivateRoute from "./common/PrivateRoute";

// IMPORT ALERTS
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

// TEMPLATES
import HeaderTemplate from './components/layout/Header';
import FooterTemplate from './components/layout/Footer';
import Alerts from './components/layout/Alerts';

// PAGES
import Dashboard from './components/pages/Dashboard';
import DailyReports from './components/pages/DailyReports';
import NotesDropOff from './components/pages/NotesDropOff';
import Config from './components/pages/Config';

import People from './components/pages/People';
import People_Add from './components/pages/People_Add';
import People_Edit from './components/pages/People_Edit';

import Price from './components/pages/Prices/Prices';
import Price_Add from './components/pages/Prices/Prices_Add';
import Price_Edit from './components/pages/Prices/Prices_Edit';

import Hotel from './components/pages/Hotels';
import Hotel_Add from './components/pages/Hotels_Add';
import Hotel_Edit from './components/pages/Hotels_Edit';

import LogDetails from './components/pages/LogDetails';
import LogDetails_Add from './components/pages/LogDetails_Add';
import LogDetails_Edit from './components/pages/LogDetails_Add';

import DailyLog from './components/pages/DailyLog';

import ReportsConfig from './components/pages/ReportsConfig';
import ReportsConfigEdit from './components/pages/ReportsConfigEdit';

import Location from './components/pages/Locations';
import Location_Add from './components/pages/Locations_Add';
import Location_Edit from './components/pages/Locations_Edit';

import Search from './components/pages/Search';
import Error404 from './components/pages/Error404';
import Login from './components/pages/Login';
import Receipt from './components/pages/Receipt';

import { loadUser } from "./client/actions/auth";

// CSS
import './assets/App.css';
import queryString from "query-string";

// ALERT OPTIONS
const alertOptions = {
  timeout: 3000,
  position: 'top center'
};

class App extends Component {
  state = {
    appVersion: '',
    searchTerm:''
  };
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    let appVersion = values.AV;

    if (typeof(appVersion) !== 'undefined') {
      this.setState({ appVersion: appVersion });
    }
    store.dispatch(loadUser());
  }

  updateSearchTerm = (searchTerm) => {
    this.setState({searchTerm: searchTerm});
  };

  render() {
    return (
        <Provider store={store}>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <Router>
              <React.Fragment>
                <HeaderTemplate {...this.props} updateSearchTerm={this.updateSearchTerm}/>
                <Alerts />
                <Switch>
                  <PrivateRoute exact path="/" component={Dashboard} />
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/dashboard/:unitView/:timeStamp" component={Dashboard} />
                  <PrivateRoute exact path="/dashboard/:unitView/:tabView/:timeStamp" component={Dashboard} />

                  <PrivateRoute exact path="/notes" component={NotesDropOff} />
                  <PrivateRoute exact path="/config" component={Config} />

                  <PrivateRoute exact path="/config/people" component={People} />
                  <PrivateRoute exact path="/config/people/add" component={People_Add} />
                  <PrivateRoute exact path="/config/people/edit/:id" component={People_Edit} />

                  <PrivateRoute exact path="/config/locations" component={Location} />
                  <PrivateRoute exact path="/config/location/add" component={Location_Add} />
                  <PrivateRoute exact path="/config/location/edit/:id" component={Location_Edit} />

                  <PrivateRoute exact path="/config/prices" component={Price} />
                  <PrivateRoute exact path="/config/price/add" component={Price_Add} />
                  <PrivateRoute exact path="/config/price/edit/:id" component={Price_Edit} />

                  <PrivateRoute exact path="/config/reports" component={ReportsConfig} />
                  <PrivateRoute exact path="/config/reports/edit/:id" component={ReportsConfigEdit} />

                  <PrivateRoute exact path="/config/hotels" component={Hotel} />
                  <PrivateRoute exact path="/config/hotel/add" component={Hotel_Add} />
                  <PrivateRoute exact path="/config/hotel/edit/:id"  component={Hotel_Edit} />

                  <PrivateRoute exact path="/config/log-details" component={LogDetails} />
                  <PrivateRoute exact path="/config/log-details/add" component={LogDetails_Add} />
                  <PrivateRoute exact path="/config/log-details/edit/:id"  component={LogDetails_Edit} />

                  <PrivateRoute exact path="/search" component={() => <Search {...this.props} updatedSearchTerm={this.state.searchTerm} /> } />

                  <PrivateRoute exact path="/config/daily-log" component={DailyLog} />

                  <PrivateRoute exact path="/dailyreports" component={DailyReports} />
                  <PrivateRoute exact path="/logout" component={Login} />

                  <Route exact path="/receipt/:receiptId" component={Receipt} />
                  <Route exact path="/login" component={Login} location={this.props.location} />
                  <PrivateRoute component={Error404} />
                </Switch>
                <FooterTemplate {...this.props} />
              </React.Fragment>
            </Router>
          </AlertProvider>
        </Provider>
    );
  }
}

export default App;
