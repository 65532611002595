import React, {Component}  from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { getPriceList, deletePrice } from "../../../client/actions/apiActions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import Loading from "../../layout/Loading"

class Price extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            successMessage: '',
            errorMessage: ''
        };
        this.selectedCheckboxes = new Set();
    };

    componentDidMount() {
        this.props.getPriceList();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        //console.log(nextProps);
        this.setState({
            dataList: nextProps.dataList.price
        });
    }

    deletePrice = (priceId, priceName) => {
        const deleteOptions = {
            title: 'Confirm Delete',
            message: `Are you sure you wish to delete this price "${priceName}"`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { this.props.deletePrice(priceId); this.setState({successMessage: "Price has been deleted!"}); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log("Price clicked no"); }
                }
            ]
        };

        confirmAlert(deleteOptions);
    };

    render() {
        const {
            dataList,
            successMessage
        } = this.state;

        if (dataList !== '') {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="text-center">Shade Patrol Prices</h1>

                            {successMessage && <div className="col-12"><div className="alert alert-success">{successMessage}</div></div>}
                            <Link className="btn btn-secondary my-2" to="/config/price/add">Add Price</Link>
                            <table className="table table-striped">
                                <thead className="thead-dark">
                                <tr>
                                    <th>Name</th>
                                    <th>Price</th>
                                    <th>Retail Rate</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Add On</th>
                                    <th>Unit Code</th>
                                    <th>Sort</th>
                                    <th>Hotel</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {dataList.map((price, index) => (
                                        <tr key={index}>
                                            <td>{price.name}</td>
                                            <td>{price.price}</td>
                                            <td>{price.retailRate}</td>
                                            <td>{price.startTime}</td>
                                            <td>{price.endTime}</td>
                                            <td>{price.addOn}</td>
                                            <td>{price.unitType}</td>
                                            <td>{price.sort}</td>
                                            <td>{price.projectHotels}</td>
                                            <td className="text-right">
                                                <Link className="btn btn-warning btn-lg m-1" to={"/config/price/edit/" + price.id}><i className="fas fa-pencil-alt"></i></Link>
                                                <button className="btn btn-danger btn-lg m-1" onClick={()=>this.deletePrice(price.id, price.name)}><i className="fas fa-times"></i></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <Loading/>
            )
        }
    }
}

Price.propTypes = {
    dataList: PropTypes.object.isRequired,
    getPriceList: PropTypes.func.isRequired,
    deletePrice: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    dataList: state.pages.dataList
});

export default connect( mapStateToProps, { getPriceList, deletePrice })(Price);
