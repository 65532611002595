import React, {Component} from 'react';

class UnitNav extends Component {
    render() {
        const { paymentMethod, hasCartUnits, cartUnits, roomChargeHotel, nameSelected, lastName, roomNumber, equipmentSelected, selectedUnit, selectedUnitPrice, cancelPaymentMethod } = this.props;

        let multiPaymentMethod = '';
        let multiRoomChargeHotel = '';
        let multiLastName = '';
        let multiRoomNumber = '';
        if (hasCartUnits === true)
        {
            multiPaymentMethod = cartUnits[0].paymentMethod;
            multiRoomChargeHotel = cartUnits[0].roomChargeHotel;
            multiLastName = cartUnits[0].lastName;
            multiRoomNumber = cartUnits[0].roomNumber;
        }

        return (
            <div className="row">
                <div className="col-12">
                    {hasCartUnits === true && multiPaymentMethod !== '' && <div className="btn btn-outline-secondary btn-sm mr-2">{multiPaymentMethod} </div>}
                    {hasCartUnits === true && multiRoomChargeHotel !== '' && typeof(multiRoomChargeHotel) !== 'undefined' && <div className="btn btn-outline-secondary btn-sm mr-2">{multiRoomChargeHotel} </div>}
                    {hasCartUnits === true && multiRoomNumber !== '' && typeof(multiRoomNumber) !== 'undefined' && <div className="btn btn-outline-secondary btn-sm mr-2">{multiLastName} / #{multiRoomNumber} </div>}
                    {hasCartUnits === true && multiRoomNumber === '' && multiLastName !== '' && typeof(multiLastName) !== 'undefined' && <div className="btn btn-outline-secondary btn-sm mr-2">{multiLastName}</div>}

                    {!hasCartUnits && paymentMethod !== null && <button onClick={()=>cancelPaymentMethod()} className="btn btn-outline-danger btn-sm mr-2">{paymentMethod} <i className="fas fa-times" /></button>}
                    {!hasCartUnits && roomChargeHotel !== null && <button onClick={()=>cancelPaymentMethod()} className="btn btn-outline-danger btn-sm mr-2">{roomChargeHotel} <i className="fas fa-times" /></button>}
                    {!hasCartUnits && nameSelected === true && roomNumber !== 0 && lastName && <button onClick={()=>cancelPaymentMethod()} className="btn btn-outline-danger btn-sm mr-2">{lastName} / # {roomNumber} <i className="fas fa-times" /></button>}
                    {!hasCartUnits && equipmentSelected === true && <button onClick={()=>cancelPaymentMethod()} className="btn btn-outline-danger btn-sm mr-2">{selectedUnit} $ {selectedUnitPrice} <i className="fas fa-times" /></button>}
                </div>
            </div>
        )
    }
}

export default UnitNav
