import React, {Component, Fragment} from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Alerts extends Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
        message: PropTypes.object.isRequired
    };
    componentDidUpdate(prevProps) {
        const { error, alert, message } = this.props;
        if (message !== prevProps.message){
            alert.success(message.message);
        }
        if (error !== prevProps.error)
        {
            if (typeof(error.msg) !== 'undefined')
            {
                if (typeof(error.msg.data) !== 'undefined')
                {
                    if (typeof(error.msg.data.details) !== 'undefined')
                    {
                        if (error.msg.data.details !== 'Authentication credentials were not provided.')
                        {
                            alert.error(error.msg.data.details);
                        }
                    }
                }
                if (typeof(error.msg.details) !== 'undefined')
                {
                    alert.error(error.msg.details);
                }
            }
        }
    }
    render() {
        return (
            <Fragment />
        )
    }
}

const mapStateToProps = state => ({
    error: state.errors,
    message: state.messages
});

export default connect(mapStateToProps)(withAlert(Alerts));