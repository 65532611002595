import React, {Component, Fragment} from 'react';
import Loading from '../../layout/Loading';

class ReportStandard extends Component {
    render() {
        if (typeof(this.props.reportData) !== 'undefined') {
            const { reportTitle, reportHeaders, reportRows, reportFooter } = this.props.reportData;
            const { viewTransaction } = this.props;
            return (
                <div>
                    <h3>{reportTitle}</h3>
                    <table className="table table-striped table-sm table-bordered">
                        {reportHeaders && <thead className="thead-dark">
                            <tr>
                                {reportHeaders.map((field,index) =>
                                    <Fragment key={index}>
                                        {index !== 6 && <th>{field}</th>}
                                        {index === 6 && <th className="noprint">{field}</th>}
                                    </Fragment>
                                )}
                            </tr>
                        </thead>}
                        {reportRows && <tbody>
                            {reportRows.map((row,index)=> <tr key={index}>
                                {row.map((data,index2) =>
                                    <Fragment key={index2}>
                                        {index2 !== 6 && <td>{data}</td> }
                                        {index2 === 6 && <td className="noprint">
                                            <button className="btn btn-sm btn-primary m-1" onClick={ () => viewTransaction(row[6]) }><i className="fas fa-eye"></i></button>
                                        </td> }
                                    </Fragment>
                                )}
                            </tr>)}
                        </tbody>}
                        {reportFooter && <tfoot>
                            <tr className="bg-warning">
                                {reportFooter.map((data,index) =>
                                    <Fragment key={index}>
                                        {index !== 6 && <td>{data}</td>}
                                        {index === 6 && <td className="noprint">{data}</td>}
                                    </Fragment>
                                )}
                            </tr>
                        </tfoot>}
                    </table>
                </div>
            );
        } else {
            return (<Loading />);
        }
    }
}
export default ReportStandard